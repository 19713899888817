define("plutof/components/generic-views/copy-content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/utils/notifications", "plutof/utils/clipboard"], function (_exports, _component, _templateFactory, _component2, _object, _service, _notifications, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Don't pollute the DOM with meaningless divs if possible }}
  {{yield (hash
      register-content=(modifier "get-reference" this.registerContent)
      copy=this.copy
  )}}
  
  {{#if (has-block "content")}}
      <div {{get-reference this.registerContent}}>
          {{yield to="content"}}
      </div>
  {{/if}}
  
  */
  {
    "id": "m/QVTNeT",
    "block": "[[[18,1,[[28,[37,1],null,[[\"register-content\",\"copy\"],[[50,[28,[37,3],[\"modifier:get-reference\"],null],2,[[30,0,[\"registerContent\"]]],null],[30,0,[\"copy\"]]]]]]],[1,\"\\n\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[11,0],[4,[38,6],[[30,0,[\"registerContent\"]]],null],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\",\"&content\"],false,[\"yield\",\"hash\",\"modifier\",\"-resolve\",\"if\",\"has-block\",\"get-reference\"]]",
    "moduleName": "plutof/components/generic-views/copy-content.hbs",
    "isStrictMode": false
  });
  let CopyContent = _exports.default = (_class = class CopyContent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
    }
    copy() {
      if (this.contentElement) {
        const text = this.contentElement.textContent.trim();
        (0, _clipboard.sendToClipboard)(text);
        const notification = this.i18n.translate('General.textCopiedToClipboard', {
          hash: {
            text
          }
        });
        (0, _notifications.showNotification)('success', notification, {
          successTime: 2000,
          scrollToTop: false,
          includeTimestamp: false
        });
      }
    }
    registerContent(contentElement) {
      this.contentElement = contentElement;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "copy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerContent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerContent"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CopyContent);
});