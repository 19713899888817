define("plutof/components/information/content/general/area-event-edit", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h4>{{i18n-t "Sample.area.areaSelectionHelper"}}</h4>
  <Information::Content::General::AreaProjectFilter />
  <br />
  
  <Information::Content::General::MapControls />
  
  */
  {
    "id": "XNypXFS+",
    "block": "[[[10,\"h4\"],[12],[1,[28,[35,0],[\"Sample.area.areaSelectionHelper\"],null]],[13],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[10,\"br\"],[12],[13],[1,\"\\n\\n\"],[8,[39,2],null,null,null],[1,\"\\n\"]],[],false,[\"i18n-t\",\"information/content/general/area-project-filter\",\"information/content/general/map-controls\"]]",
    "moduleName": "plutof/components/information/content/general/area-event-edit.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});