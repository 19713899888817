define("plutof/components/linked-items/view/-files-content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @searchQuery}}
      <div class="linked-items-view__tab-controls">
          <RelatedObjects::SearchButton
              @searchQuery={{@searchQuery}}
              @searchModule="filerepository" />
      </div>
  {{/if}}
  
  {{#if @files.record}}
      <div>
          {{common/local-label "linkedItems.record"}}
  
          <Layout::RegularGrid @columns={{6}}>
              {{#each @files.record as |item|}}
                  <div>
                      <LinkedItems::View::FileItem
                          @item={{item}}
                          @open={{fn this.show item}} />
                  </div>
              {{/each}}
          </Layout::RegularGrid>
      </div>
  {{/if}}
  
  {{#if @files.event}}
      <div>
          {{common/local-label "linkedItems.event"}}
  
          <Layout::RegularGrid @columns={{6}}>
              {{#each @files.event as |item|}}
                  <div>
                      <LinkedItems::View::FileItem
                          @item={{item}}
                          @open={{fn this.show item}} />
                  </div>
              {{/each}}
          </Layout::RegularGrid>
      </div>
  {{/if}}
  
  <Filerepository::Gallery
      @items={{@files.all}}
      @active={{this.selected}} />
  
  */
  {
    "id": "tcmCl2Yf",
    "block": "[[[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"linked-items-view__tab-controls\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@searchQuery\",\"@searchModule\"],[[30,1],\"filerepository\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"record\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"linkedItems.record\"],null]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,2,[\"record\"]]],null]],null],null,[[[1,\"                \"],[10,0],[12],[1,\"\\n                    \"],[8,[39,6],null,[[\"@item\",\"@open\"],[[30,3],[28,[37,7],[[30,0,[\"show\"]],[30,3]],null]]],null],[1,\"\\n                \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"event\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"linkedItems.event\"],null]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,2,[\"event\"]]],null]],null],null,[[[1,\"                \"],[10,0],[12],[1,\"\\n                    \"],[8,[39,6],null,[[\"@item\",\"@open\"],[[30,4],[28,[37,7],[[30,0,[\"show\"]],[30,4]],null]]],null],[1,\"\\n                \"],[13],[1,\"\\n\"]],[4]],null],[1,\"        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,8],null,[[\"@items\",\"@active\"],[[30,2,[\"all\"]],[30,0,[\"selected\"]]]],null],[1,\"\\n\"]],[\"@searchQuery\",\"@files\",\"item\",\"item\"],false,[\"if\",\"related-objects/search-button\",\"common/local-label\",\"layout/regular-grid\",\"each\",\"-track-array\",\"linked-items/view/file-item\",\"fn\",\"filerepository/gallery\"]]",
    "moduleName": "plutof/components/linked-items/view/-files-content.hbs",
    "isStrictMode": false
  });
  let LinkedItemsViewFileTabContent = _exports.default = (_class = class LinkedItemsViewFileTabContent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "selected", _descriptor, this);
    }
    show(item) {
      this.selected = item;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "show", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "show"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LinkedItemsViewFileTabContent);
});