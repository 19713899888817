define("plutof/components/specimen/view/exsiccata", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (annotation/fields-modified @specimen 'exsiccata') as |annotated|}}
      <GenericViews::Record @record={{@exsiccata}} as |view|>
          <Layout::RegularGrid @columns={{4}} @cellGap={{true}}>
              {{view.attr "title" annotated=annotated}}
              {{view.attr "abbreviation" annotated=annotated}}
              {{view.attr "groups" annotated=annotated}}
  
              {{#view.attr
                  label=(component "plutof-labelc" path="specimen.exsiccata_no")
                  annotated=(annotation/fields-modified @specimen 'exsiccata_no')
              }}
                  {{@specimen.exsiccata_no}}
              {{/view.attr}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  {{/let}}
  
  */
  {
    "id": "Bg8AcXn0",
    "block": "[[[44,[[28,[37,1],[[30,1],\"exsiccata\"],null]],[[[1,\"    \"],[8,[39,2],null,[[\"@record\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\",\"@cellGap\"],[4,true]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,4,[\"attr\"]],[\"title\"],[[\"annotated\"],[[30,2]]]]],[1,\"\\n            \"],[1,[28,[30,4,[\"attr\"]],[\"abbreviation\"],[[\"annotated\"],[[30,2]]]]],[1,\"\\n            \"],[1,[28,[30,4,[\"attr\"]],[\"groups\"],[[\"annotated\"],[[30,2]]]]],[1,\"\\n\\n\"],[6,[30,4,[\"attr\"]],null,[[\"label\",\"annotated\"],[[50,\"plutof-labelc\",0,null,[[\"path\"],[\"specimen.exsiccata_no\"]]],[28,[37,1],[[30,1],\"exsiccata_no\"],null]]],[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"exsiccata_no\"]]],[1,\"\\n\"]],[]]]]],[1,\"        \"]],[]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"]],[2]]]],[\"@specimen\",\"annotated\",\"@exsiccata\",\"view\"],false,[\"let\",\"annotation/fields-modified\",\"generic-views/record\",\"layout/regular-grid\",\"component\"]]",
    "moduleName": "plutof/components/specimen/view/exsiccata.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});