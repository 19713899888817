define("plutof/controllers/profile/stats", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "localforage", "plutof/utils/caching", "plutof/utils/file-serialization"], function (_exports, _controller, _object, _computed, _utils, _localforage, _caching, _fileSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProfileStatsController = (_dec = (0, _computed.alias)('model.profile.clipboard_size'), _dec2 = (0, _computed.alias)('model.clipboardUsage'), _dec3 = (0, _object.computed)('model.profile.storage_used'), _class = class ProfileStatsController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "clipboardSize", _descriptor, this);
      _initializerDefineProperty(this, "usage", _descriptor2, this);
    }
    get fileStorageUsed() {
      const usage = this.get('model.profile.storage_used');
      if ((0, _utils.isNone)(usage)) {
        return null;
      }
      return (0, _fileSerialization.getHumanReadableSize)(usage);
    }
    async clearCaches() {
      await _caching.CacheManager.dropCaches();

      // Unmanaged caches
      //
      // Ignoring the errors because they do happen, but the result is still there. Something interal to
      // LocalForage, probably due to the instance already being open
      await _localforage.default.dropInstance({
        name: 'cache.simple.options.eng'
      }).catch(err => console.error(err));
      await _localforage.default.dropInstance({
        name: 'cache.simple.options.est'
      }).catch(err => console.error(err));
      window.location.reload();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "clipboardSize", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "usage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fileStorageUsed", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "fileStorageUsed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearCaches", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearCaches"), _class.prototype), _class);
  var _default = _exports.default = ProfileStatsController;
});