define("plutof/templates/components/datacite/taxon-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0fQr4ZfZ",
    "block": "[[[10,\"td\"],[15,0,[52,[30,0,[\"validations\",\"attrs\",\"taxon\",\"full_taxon_name\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n\"],[41,[30,0,[\"hasTaxonName\"]],[[[1,\"        \"],[1,[30,1,[\"full_taxon_name\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,1],[[24,0,\"datacite-edit-taxa__taxon\"]],[[\"@update\",\"@params\"],[[30,0,[\"fillTaxon\"]],[28,[37,2],null,[[\"mini\"],[true]]]]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@taxon\",\"@remove\"],false,[\"if\",\"taxonomy/taxon/auto-complete\",\"hash\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/taxon-row-form.hbs",
    "isStrictMode": false
  });
});