define("plutof/components/mycobank-search", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/utils", "ember-concurrency", "plutof/config/environment", "plutof/misc/config", "plutof/utils/notifications", "plutof/utils/push-to-store"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _utils, _emberConcurrency, _environment, _config, _notifications, _pushToStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Common::LocalLabel
      @label="taxonImport.searchMycobank"
      @tooltip="taxonImport.searchMycobankTooltip" />
  
  <div class="button-group button-group--wide">
      <Input
          @type="text"
          @value={{this.query}}
          @enter={{this.search}}
          class="button-group__input" />
  
      <button
          {{on "click" (fn this.search this.query)}}
          class="button-group__button button-group__button--addon"
      >
          <span class="icon-search {{if this.searchMycobank.isRunning 'spinicon'}}"></span>
      </button>
  </div>
  
  {{#if this.results.length}}
      <div class="plutof-label">&nbsp;</div>
  
      <ExpandedSelect
          @content={{this.results}}
          @selection={{this.selectedTaxon}}
          @idProperty="RecordId"
          as |result|
      >
          {{result.Name.Value}}
      </ExpandedSelect>
  {{/if}}
  
  <div class="determination-actions">
      <button
          {{on "click" this.import}}
          disabled={{this.cannotImport}}
          class="btn btn-default"
      >
          <span class="icon-success {{if this.importMycobank.isRunning 'spinicon'}}"></span>
          {{i18n-t "taxonImport.actions.import"}}
      </button>
  
      {{#if @close}}
          <button
              {{on "click" @close}}
              class="btn btn-default"
          >
              {{i18n-t "taxonImport.actions.close"}}
          </button>
      {{/if}}
  </div>
  
  */
  {
    "id": "qQB2O6Av",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@tooltip\"],[\"taxonImport.searchMycobank\",\"taxonImport.searchMycobankTooltip\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"button-group button-group--wide\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"button-group__input\"]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[30,0,[\"query\"]],[30,0,[\"search\"]]]],null],[1,\"\\n\\n    \"],[11,\"button\"],[24,0,\"button-group__button button-group__button--addon\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"search\"]],[30,0,[\"query\"]]],null]],null],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[\"icon-search \",[52,[30,0,[\"searchMycobank\",\"isRunning\"]],\"spinicon\"]]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"results\",\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\" \"],[13],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@content\",\"@selection\",\"@idProperty\"],[[30,0,[\"results\"]],[30,0,[\"selectedTaxon\"]],\"RecordId\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"Name\",\"Value\"]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"determination-actions\"],[12],[1,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,0,[\"cannotImport\"]]],[24,0,\"btn btn-default\"],[4,[38,2],[\"click\",[30,0,[\"import\"]]],null],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[\"icon-success \",[52,[30,0,[\"importMycobank\",\"isRunning\"]],\"spinicon\"]]]],[12],[13],[1,\"\\n        \"],[1,[28,[35,6],[\"taxonImport.actions.import\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,2],[\"click\",[30,2]],null],[12],[1,\"\\n            \"],[1,[28,[35,6],[\"taxonImport.actions.close\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"result\",\"@close\"],false,[\"common/local-label\",\"input\",\"on\",\"fn\",\"if\",\"expanded-select\",\"i18n-t\"]]",
    "moduleName": "plutof/components/mycobank-search.hbs",
    "isStrictMode": false
  });
  // TODO: Merge this and gbif-search into general purpose modal for taxa import jobs.

  function onError(error) {
    (0, _notifications.reportError)(error);
    throw error;
  }
  let MycobankSearch = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class MycobankSearch extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "i18n", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "results", _descriptor4, this);
      _initializerDefineProperty(this, "selectedTaxon", _descriptor5, this);
      _defineProperty(this, "onSearchSuccess", response => {
        if ((0, _utils.isNone)(response.RecordDetails)) {
          (0, _notifications.displayNotification)('success', this.i18n.translate('taxonImport.notFound'));
          this.results = [];
          return;
        }
        try {
          const selectedTaxon = response.RecordDetails.Synonymy.SynInfo.SelectedRecord;
          const currentTaxon = response.RecordDetails.Synonymy.SynInfo.CurrentNameRecord;
          let taxonRepresentation = selectedTaxon.RecordName;
          if (selectedTaxon.RecordId !== currentTaxon.RecordId) {
            const synonymOf = this.i18n.translate('taxonImport.synonymOf', {
              hash: {
                acceptedName: currentTaxon.RecordName
              }
            });
            taxonRepresentation += ` (${synonymOf})`;
          }
          const exactMatch = {
            Name: {
              Value: taxonRepresentation
            },
            RecordId: response.RecordDetails.Synonymy.SynInfo.SelectedRecord.RecordId
          };
          const children = response.RecordDetails.Classification.ChildValue;
          this.results = [exactMatch].concat(children);
        } catch (err) {
          this.results = [];
          onError(err);
        }
      });
      _defineProperty(this, "onImportSuccess", taxon => {
        const treeId = _config.default.Taxonomy.DEFAULT_TAXONOMY_ID;
        const message = `${this.i18n.translate('taxonImport.newCreated')}` + ` <a href="${window.location.origin}/taxonomy/trees?taxon_id=${taxon.id}&tree_id=${treeId}"> ` + `${this.i18n.translate('taxonImport.actions.view')}</a>`;
        (0, _notifications.displayHtmlNotification)('success', message, null, 12000);
        const parent = this.args.parentObject;
        if ((0, _utils.isPresent)(parent)) {
          this.store.findRecord('taxonomy/taxonnode', taxon.id).then(taxonNode => {
            parent.setProperties({
              expanded: false,
              taxon_node: taxonNode
            });
          });
        }
        if ((0, _utils.isPresent)(this.args.afterImport)) {
          this.args.afterImport((0, _pushToStore.default)(this.store, 'taxonomy/taxonnode', taxon));
        }
        this.selectedTaxon = null;
        if (this.args.close) {
          this.args.close();
        }
      });
      _initializerDefineProperty(this, "searchMycobank", _descriptor6, this);
      _initializerDefineProperty(this, "importMycobank", _descriptor7, this);
    }
    get query() {
      return this.args.query;
    }
    set query(value) {
      this.args.updateQuery(value);
    }
    get cannotImport() {
      return this.importMycobank.isRunning || this.searchMycobank.isRunning || (0, _utils.isEmpty)(this.selectedTaxon);
    }
    import() {
      this.importMycobank.perform();
    }
    search(query) {
      this.selectedTaxon = null;
      if (this.searchMycobank.isRunning) {
        return;
      }
      if ((0, _utils.isEmpty)(query)) {
        this.results = [];
        return;
      }
      this.searchMycobank.perform(query);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "results", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedTaxon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "searchMycobank", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (query) {
        const url = `${_environment.default.API_HOST}/taxonomy/mycobank/search/?name=${query}`;
        yield this.ajax.request(url).then(yield this.onSearchSuccess, yield onError);
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "importMycobank", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const url = `${_environment.default.API_HOST}/taxonomy/import-mycobank-taxon/`;
        const data = {
          taxon_id: this.selectedTaxon.RecordId
        };
        yield this.ajax.post(url, {
          data
        }).then(yield this.onImportSuccess, yield onError);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "import", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "import"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MycobankSearch);
});