define("plutof/controllers/observation/edit", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "rsvp", "ember-cp-validations", "plutof/misc/config", "plutof/mixins/component-validations", "plutof/mixins/edit-controller", "plutof/mixins/moderation", "plutof/utils/i18n", "plutof/utils/notifications", "plutof/utils/model"], function (_exports, _controller, _object, _computed, _utils, _rsvp, _emberCpValidations, _config, _componentValidations, _editController, _moderation, _i18n, _notifications, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getAreaEventTranslations = getAreaEventTranslations;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const COUNT_REGEXP = new RegExp('^(-1)$|^([0-9]{1,8})$');
  const i18n = (0, _i18n.getI18n)();
  function getAreaEventTranslations() {
    const obj = {
      'SamplingEvent': {
        gatheringAgents: 'Observations.observers',
        gatheringAgentsTooltip: 'Observations.observers',
        beginDate: 'Observations.beginDate',
        beginTime: 'Observations.beginTime',
        endDate: 'Observations.endDate',
        endTime: 'Observations.endTime'
      },
      'Sample': {
        samplingArea: 'Observations.samplingArea',
        existingSamplingArea: 'Observations.existingSamplingArea',
        existingSamplingAreaTooltip: 'Observations.existingSamplingAreaTooltip',
        parentSamplingArea: 'Observations.parentSamplingArea',
        parentSamplingAreaTooltip: 'Observations.parentSamplingAreaTooltip'
      },
      'General': {
        samplingEvent: 'Observations.samplingEvent',
        samplingAreaEvent: 'Observations.samplingAreaEvent'
      }
    };
    return obj;
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'countIsValid': (0, _emberCpValidations.validator)('is-truthy'),
    'observation.remarks': (0, _emberCpValidations.validator)('length', {
      max: 2048
    })
  });
  const Base = _controller.default.extend(_editController.default, Validations, _componentValidations.default, _moderation.default);
  let ObservationEditController = (_dec = (0, _computed.alias)('model.observation'), _dec2 = (0, _object.computed)('observation.individual_count'), _class = class ObservationEditController extends Base {
    constructor() {
      super(...arguments);
      _defineProperty(this, "validationChildren", [{
        name: 'areaEvent',
        label: 'Observations.samplingAreaEvent'
      }, {
        name: 'substrate',
        label: 'General.substrate'
      }, {
        name: 'measurements',
        label: 'General.measurements'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }, {
        name: 'access',
        label: 'General.access'
      }, {
        name: 'interactions',
        label: 'Interaction.interaction'
      }, {
        name: 'determinations',
        label: 'General.identifications'
      }, {
        name: 'project',
        label: 'General.project'
      }]);
      _defineProperty(this, "recordsToUnload", [{
        record: 'observation',
        subRecords: ['substrate']
      }]);
      _initializerDefineProperty(this, "observation", _descriptor, this);
      _defineProperty(this, "areaEventTranslations", getAreaEventTranslations());
    }
    get countIsValid() {
      const count = this.get('observation.individual_count');
      const countRequired = this.observation && (0, _model.relationID)(this.observation, 'mainform') === _config.default.Observation.BIRD_MAINFORM_ID;
      return (0, _utils.isPresent)(count) ? COUNT_REGEXP.test(count) : !countRequired;
    }
    addAdditionalIdentifier(code, source) {
      const identifier = this.store.createRecord('taxonoccurrence/observation/additional-identifier', {
        observation: this.model.observation,
        code,
        source
      });
      this.model.additionalIdentifiers.pushObject(identifier);
    }
    removeAdditionalIdentifier(identifier) {
      this.model.additionalIdentifiers.removeObject(identifier);
      this.objectsToDelete.pushObject(identifier);
    }
    async _save() {
      this.model.project.apply(this.model.observation);
      await this.model.substrateData.save();
      await this.model.samplingAreaEventFormData.applyAndSaveOccurrence(this.model.observation);
      await this.model.accessRights.saveWithTarget(this.observation);
      await _rsvp.default.all([this.model.determinations.save(), this.model.itemData.save(this.model.observation, this.model.accessRights), this.model.observationData.save(), this.model.interactions.save(), this.model.layers.save([this.model.observation]), _rsvp.default.all(this.model.additionalIdentifiers.invoke('save')), _rsvp.default.all(this.relatedObjectsToDelete.invoke('destroyRecord')).then(() => _rsvp.default.all(this.objectsToDelete.invoke('destroyRecord')))]);
    }
    async save() {
      await (0, _notifications.notifyProcess)(this._save(), i18n);
      this.set('routeHasBeenLoaded', null);
      this.routeActions.goBack();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "observation", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "countIsValid", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "countIsValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAdditionalIdentifier", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addAdditionalIdentifier"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAdditionalIdentifier", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeAdditionalIdentifier"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  var _default = _exports.default = ObservationEditController;
});