define("plutof/components/linked-items/view/-references-tab", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@group.simpleTab
      @name="references"
      @header={{i18n-t "General.References"}}
      @count={{@items.objectCount}}
  >
      <Common::RecordListView @records={{@items.objects}} as |item|>
          <LinkTo @route="reference.view" @model={{item.reference.id}} class={{annotation/added-removed-class item}}>
              <WrappedText @text={{item.representation}} @breakWord={{true}} />
          </LinkTo>
      </Common::RecordListView>
  
      <Pagination::LoadMore @pagination={{@items}} />
  </@group.simpleTab>
  
  */
  {
    "id": "izjZ7zSP",
    "block": "[[[8,[30,1,[\"simpleTab\"]],null,[[\"@name\",\"@header\",\"@count\"],[\"references\",[28,[37,0],[\"General.References\"],null],[30,2,[\"objectCount\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@records\"],[[30,2,[\"objects\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[16,0,[28,[37,3],[[30,3]],null]]],[[\"@route\",\"@model\"],[\"reference.view\",[30,3,[\"reference\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@text\",\"@breakWord\"],[[30,3,[\"representation\"]],true]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@pagination\"],[[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@group\",\"@items\",\"item\"],false,[\"i18n-t\",\"common/record-list-view\",\"link-to\",\"annotation/added-removed-class\",\"wrapped-text\",\"pagination/load-more\"]]",
    "moduleName": "plutof/components/linked-items/view/-references-tab.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});