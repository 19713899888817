define("plutof/components/ui/-clickable-label", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @clicked}}
      <button {{on "click" @clicked}} class="btn-reset plutof-label" ...attributes>
          {{yield}}
      </button>
  {{else}}
      <div class="plutof-label" ...attributes>
          {{yield}}
      </div>
  {{/if}}
  
  */
  {
    "id": "pO1PZeio",
    "block": "[[[41,[30,1],[[[1,\"    \"],[11,\"button\"],[24,0,\"btn-reset plutof-label\"],[17,2],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,0],[24,0,\"plutof-label\"],[17,2],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[\"@clicked\",\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "plutof/components/ui/-clickable-label.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});