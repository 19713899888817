define("plutof/components/quick-browse/record-popup", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="quick-browse-popup">
      <div>
          <span class="plutof-label">
              <span class={{@record.icon}}></span>
          </span>
  
          <LinkTo @route={{@record.viewRoute}} @model={{@record.id}}>
              {{@record.data.name}}
          </LinkTo>
      </div>
  
      {{component (concat "quick-browse/record-popup/" this.templateName)
          record=@record
          module=@module
          files=@record.data.files}}
  
      <button
          {{on "click" @closePopup}}
          class="quick-browse-popup__close-btn close-btn"
      >
          <span class={{icon "close"}}></span>
      </button>
  </div>
  
  */
  {
    "id": "fYJ83jzw",
    "block": "[[[10,0],[14,0,\"quick-browse-popup\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[10,1],[14,0,\"plutof-label\"],[12],[1,\"\\n            \"],[10,1],[15,0,[30,1,[\"icon\"]]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,0],null,[[\"@route\",\"@model\"],[[30,1,[\"viewRoute\"]],[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"data\",\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[46,[28,[37,2],[\"quick-browse/record-popup/\",[30,0,[\"templateName\"]]],null],null,[[\"record\",\"module\",\"files\"],[[30,1],[30,2],[30,1,[\"data\",\"files\"]]]],null],[1,\"\\n\\n    \"],[11,\"button\"],[24,0,\"quick-browse-popup__close-btn close-btn\"],[4,[38,3],[\"click\",[30,3]],null],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[37,4],[\"close\"],null]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@record\",\"@module\",\"@closePopup\"],false,[\"link-to\",\"component\",\"concat\",\"on\",\"icon\"]]",
    "moduleName": "plutof/components/quick-browse/record-popup.hbs",
    "isStrictMode": false
  });
  const POPUP_TEMPLATES = {
    'taxonomy/taxonnode': 'taxon-node'
  };
  const DEFAULT_POPUP_TEMPLATE = 'taxon-occurrence';
  let QuickBrowseRecordPopup = (_dec = (0, _object.computed)('args.record.modelName'), _class = class QuickBrowseRecordPopup extends _component2.default {
    get templateName() {
      return POPUP_TEMPLATES[this.args.record.modelName] || DEFAULT_POPUP_TEMPLATE;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "templateName", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "templateName"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuickBrowseRecordPopup);
});