define("plutof/components/preparation/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "rsvp", "plutof/models/common/preparation"], function (_exports, _component, _templateFactory, _component2, _object, _service, _rsvp, _preparation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.preparations wait=true as |preparations|}}
      {{yield (hash
          count=preparations.length
          empty=(is-equal preparations.length 0)
          view=(component "preparation/-view-list"
              preparations=preparations
              modelSet=this.modelSet)
      )}}
  {{/resolve-promise}}
  
  */
  {
    "id": "25YKsFDc",
    "block": "[[[6,[39,0],[[30,0,[\"preparations\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[18,2,[[28,[37,2],null,[[\"count\",\"empty\",\"view\"],[[30,1,[\"length\"]],[28,[37,3],[[30,1,[\"length\"]],0],null],[50,\"preparation/-view-list\",0,null,[[\"preparations\",\"modelSet\"],[[30,1],[30,0,[\"modelSet\"]]]]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"preparations\",\"&default\"],false,[\"resolve-promise\",\"yield\",\"hash\",\"is-equal\",\"component\"]]",
    "moduleName": "plutof/components/preparation/view.hbs",
    "isStrictMode": false
  });
  let PreparationView = _exports.default = (_dec = (0, _object.computed)('args.record'), _dec2 = (0, _object.computed)('args.record'), _class = class PreparationView extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get modelSet() {
      return (0, _preparation.getPreparationModelset)(this.args.record.constructor.modelName);
    }
    get preparations() {
      return this.store.query(this.modelSet.preparationModel, {
        [this.modelSet.objectField]: this.args.record.id
      }).then(preparations => _rsvp.default.Promise.all(preparations.map(async preparation => ({
        preparation,
        agents: await this.store.query(this.modelSet.agentModel, {
          preparation: preparation.id,
          ordering: 'id'
        })
      }))));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "modelSet", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "modelSet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preparations", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "preparations"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PreparationView);
});