define("plutof/templates/components/search/pagination-controls", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "L9P9aYz4",
    "block": "[[[10,0],[15,0,[29,[[52,[30,0,[\"pageSizeIsInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"table-control__pagination__page-size\"],[12],[1,\"\\n        \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,2],[[24,0,\"input-xs table-control__pagination__page-size__input\"]],[[\"@enter\",\"@value\",\"@placeholder\",\"@disabled\"],[[30,0,[\"_updatePageSize\"]],[30,0,[\"pageSize\"]],[28,[37,3],[\"Search.resultsPerPage\"],null],[30,2]]],null],[1,\"\\n\\n\"],[1,\"            \"],[10,1],[15,1,[29,[[30,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n                \"],[1,[28,[35,3],[\"Search.pageSizeToolip\"],null]],[1,\"\\n\\n                (<=\"],[1,[30,3]],[1,\")\\n            \"],[13],[1,\"\\n        \"]],[1]]]]],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[11,\"button\"],[16,\"disabled\",[28,[37,4],[[30,0,[\"pageSizeIsInvalid\"]],[30,2]],null]],[24,0,\"btn btn-xs btn-default plutof-btn-green\"],[4,[38,5],[\"click\",[30,0,[\"_updatePageSize\"]]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[52,[30,4,[\"isLoading\"]],\"loading-icon\",\"icon-search\"]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@pagination\",\"@removeSpacing\",\"@alwaysShowInterface\",\"@maxPages\"],[[30,4],[30,5],true,[30,6]]],null],[1,\"\\n\"]],[\"t\",\"@disabled\",\"@maxPageSize\",\"@pagination\",\"@removeSpacing\",\"@maxPages\"],false,[\"if\",\"tooltip-wrapper\",\"input\",\"i18n-t\",\"or\",\"on\",\"pagination/page-controls\"]]",
    "moduleName": "plutof/templates/components/search/pagination-controls.hbs",
    "isStrictMode": false
  });
});