define("plutof/components/clipboard/bulk-edit-measurement-form", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/utils", "@glimmer/tracking", "plutof/config/environment"], function (_exports, _component, _object, _service, _utils, _tracking, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _dec4, _class2, _descriptor5, _descriptor6, _descriptor7, _dec5, _class3, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const FLOAT_REGEXP = new RegExp('^$|^((-[1-9])|\\d)\\d*([.]\\d+)?$');
  const INT_REGEXP = new RegExp('^$|^((-[1-9])|\\d)\\d*');
  const Mode = {
    Closed: 'closed',
    Edit: 'edit',
    Delete: 'delete'
  };
  let Field = (_dec = (0, _object.computed)('mode'), _dec2 = (0, _object.computed)('mode'), _dec3 = (0, _object.computed)('value'), _class = class Field {
    constructor(trait) {
      _initializerDefineProperty(this, "methodFieldOpen", _descriptor, this);
      _initializerDefineProperty(this, "methodDescription", _descriptor2, this);
      _initializerDefineProperty(this, "mode", _descriptor3, this);
      _initializerDefineProperty(this, "value", _descriptor4, this);
      this.trait = trait;
    }
    get disabled() {
      return this.mode !== Mode.Edit;
    }
    get active() {
      return this.mode !== Mode.Closed;
    }
    get inEditMode() {
      return this.mode === Mode.Edit;
    }
    get inDeleteMode() {
      return this.mode === Mode.Delete;
    }
    get isValid() {
      const value = (0, _utils.isNone)(this.value) ? '' : this.value;
      const intIsGood = this.trait.type !== 'int' || INT_REGEXP.test(value);
      const floatIsGood = this.trait.type !== 'float' || FLOAT_REGEXP.test(value);
      const presenceIsGood = !this.trait.is_required || !(0, _utils.isEmpty)(value);
      return intIsGood && floatIsGood && presenceIsGood;
    }
    reset() {
      this.methodFieldOpen = false;
      this.methodDescription = '';
      this.mode = Mode.Closed;
      this.value = null;
    }
    switchMode(mode) {
      this.reset();
      this.mode = mode;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "methodFieldOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "methodDescription", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Mode.Closed;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "active", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "active"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _class);
  let MeasurementsData = (_dec4 = (0, _object.computed)('fields.@each.isValid'), _class2 = class MeasurementsData {
    constructor(_ref) {
      let {
        ajax,
        formPath
      } = _ref;
      _initializerDefineProperty(this, "form", _descriptor5, this);
      _initializerDefineProperty(this, "externalFields", _descriptor6, this);
      _initializerDefineProperty(this, "mainformFields", _descriptor7, this);
      this.ajax = ajax;
      this.formPath = formPath;
    }
    get fields() {
      return this.mainformFields.concat(this.externalFields);
    }
    get isValid() {
      return this.fields.every(field => field.isValid);
    }
    async setForm(form) {
      this.form = form;
      this.mainformFields = [];
      if (form) {
        const traits = await this.ajax.request(`${_environment.default.API_HOST}/measurement/mainforms/${form.id}/measurements/`);
        const usedTrats = traits[this.formPath] ?? [];
        this.mainformFields = usedTrats.map(trait => this.wrapTrait(trait));
      }
    }
    wrapTrait(trait) {
      return new Field(trait);
    }
    addExternalTrait(trait) {
      this.externalFields.pushObject(this.wrapTrait(trait));
    }
    apply(bulkUpdateRequest) {
      // Edited fields with values.
      bulkUpdateRequest.object_measurement = this.fields.filter(field => field.mode === Mode.Edit).filter(field => !(0, _utils.isEmpty)(field.value)).map(field => {
        const value = field.trait.type === 'set' ? field.value.id : field.value;
        return {
          measurement: field.trait.url,
          method_desc: field.methodDescription,
          value
        };
      });

      // Fields marked for deletion.
      bulkUpdateRequest.remove_object_measurement = this.fields.filter(field => field.mode === Mode.Delete).map(field => ({
        measurement: field.trait.url
      }));
    }
  }, _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "form", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "externalFields", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "mainformFields", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "isValid", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isValid"), _class2.prototype), _class2);
  let BulkEditMeasurementForm = (_dec5 = (0, _object.computed)('data.mainformType'), _class3 = class BulkEditMeasurementForm extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor8, this);
    }
    get mainformFilters() {
      return {
        type: this.get('data.mainformType')
      };
    }
    toggleMethodEdit(field) {
      for (const f of this.data.measurementData.fields) {
        f.methodFieldOpen = f === field && !f.methodFieldOpen;
      }
    }
    toggleEdit(field) {
      field.switchMode(field.mode === Mode.Edit ? Mode.Closed : Mode.Edit);
    }
    toggleDelete(field) {
      field.switchMode(field.mode === Mode.Delete ? Mode.Closed : Mode.Delete);
    }
    updateMainform(inputWrapper, mainform) {
      inputWrapper.set('value', mainform);
      this.assureMeasurementData();
      this.data.measurementData.setForm(mainform);
    }
    assureMeasurementData() {
      if (!this.data.measurementData) {
        // Should just always be there
        (0, _object.set)(this.data, 'measurementData', new MeasurementsData({
          ajax: this.ajax,
          formPath: this.formPath
        }));
      }
    }
    addExternalTraits(traits) {
      this.assureMeasurementData();
      for (const trait of traits) {
        this.data.measurementData.addExternalTrait(trait);
      }
    }
  }, _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "mainformFilters", [_dec5], Object.getOwnPropertyDescriptor(_class3.prototype, "mainformFilters"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "toggleMethodEdit", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleMethodEdit"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "toggleEdit", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleEdit"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "toggleDelete", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleDelete"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "updateMainform", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "updateMainform"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "addExternalTraits", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "addExternalTraits"), _class3.prototype), _class3);
  var _default = _exports.default = BulkEditMeasurementForm;
});