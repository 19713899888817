define("plutof/templates/components/organization-label-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "uyOLlwuN",
    "block": "[[[8,[39,0],null,[[\"@label\"],[[30,0,[\"data\",\"labelText\"]]]],[[\"tooltip\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[[30,0,[\"data\",\"tooltipText\"]]],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n\\n        \"],[1,[28,[35,2],[\"Agents.organizationTooltip\"],null]],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"data\",\"infoLinks\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,5],null,[[\"infoLink\"],[[30,1,[\"url\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[\"info\"],false,[\"ui/label\",\"break-line\",\"i18n-t\",\"each\",\"-track-array\",\"info-link\"]]",
    "moduleName": "plutof/templates/components/organization-label-tooltip.hbs",
    "isStrictMode": false
  });
});