define("plutof/components/tables/-list-view/tristate-checkbox-label", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TooltipWrapper as |t|>
      <ThreeStateCheckbox @checked={{this.dummy}} @changed={{this._changed}}>
          {{@label}}
  
          <span id="{{t.tid}}" class="wrapper-tooltip">
              <span class="monospace">[✔]</span> {{i18n-t "Search.booleanTrue"}} <br />
              <span class="monospace">[&nbsp;]</span> {{i18n-t "Search.booleanFalse"}} <br />
              <span class="monospace">[-]</span> {{i18n-t "Search.booleanIndeterminate"}} <br />
          </span>
      </ThreeStateCheckbox>
  </TooltipWrapper>
  
  */
  {
    "id": "upTEzKAj",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@checked\",\"@changed\"],[[30,0,[\"dummy\"]],[30,0,[\"_changed\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2]],[1,\"\\n\\n        \"],[10,1],[15,1,[29,[[30,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"monospace\"],[12],[1,\"[✔]\"],[13],[1,\" \"],[1,[28,[35,2],[\"Search.booleanTrue\"],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n            \"],[10,1],[14,0,\"monospace\"],[12],[1,\"[ ]\"],[13],[1,\" \"],[1,[28,[35,2],[\"Search.booleanFalse\"],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n            \"],[10,1],[14,0,\"monospace\"],[12],[1,\"[-]\"],[13],[1,\" \"],[1,[28,[35,2],[\"Search.booleanIndeterminate\"],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"t\",\"@label\"],false,[\"tooltip-wrapper\",\"three-state-checkbox\",\"i18n-t\"]]",
    "moduleName": "plutof/components/tables/-list-view/tristate-checkbox-label.hbs",
    "isStrictMode": false
  });
  let ListViewTristateCheckboxLabel = (_class = class ListViewTristateCheckboxLabel extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dummy", _descriptor, this);
    }
    _changed(value) {
      this.dummy = value;
      this.args.changed(value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dummy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_changed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_changed"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ListViewTristateCheckboxLabel);
});