define("plutof/translations/eng/taxonomy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    taxonName: 'Taxon name',
    /* INDEX */
    indexTitle: 'Taxonomy Laboratory',
    descriptionsLabel: 'Taxon Descriptions',
    taxonomyInfo: 'In this section you can add and edit taxon names.',
    descriptionsInfo: 'In this section you can add edit and view taxon descriptions.',
    /* TREES */
    newClassification: 'New classification',
    treesTitle: 'Classifications',
    treeIsBlocked: 'Classification cannot be modified at this time',
    /* DESCRIPTIONS */
    descriptionsTitle: 'Taxon Descriptions',
    descriptionsInfoTitle: 'Taxon Descriptions',
    /* THE REST */
    classifications: 'Classifications',
    attributes: 'Attributes',
    vernacularNames: 'Vernacular Names',
    newTaxon: 'New taxon',
    editTaxon: 'Edit Taxon',
    viewTaxon: 'View Taxon',
    showOnTree: 'Show on tree',
    commonName: 'Vernacular name',
    isPreferred: 'Is preferred',
    isNotPreferred: 'Is not preferred',
    parentOne: 'Parent 1',
    parentTwo: 'Parent 2',
    history: 'History',
    properties: 'Properties',
    locationOnTree: 'Lineage',
    validNameLineage: 'Valid name lineage',
    addChild: 'Add child',
    syn: 'syn',
    deletedTaxon: 'NB! You are viewing details for taxon which has been deleted.',
    synonyms: 'Synonyms',
    showSynonyms: 'Show synonyms.',
    includeSubtaxa: 'Include subtaxa history',
    addPanelTitle: 'New Taxon Remarks',
    editPanelTitle: 'Taxon Edit Remarks',
    editPanelInfo: 'Add remarks to the taxonomy edit.',
    changes: 'Changes',
    copyLineageTooltip: 'Copy taxon lineage',
    indexFungorumId: 'Index Fungorum ID',
    protectionStatus: {
      panelTitle: 'Protection status',
      country: 'Country',
      is_restricted: 'Is restricted',
      category: 'Protection category',
      remarks: 'Remarks'
    },
    ranks: {
      kingdom: 'Kingdom',
      phylum: 'Phylum',
      'class': 'Class',
      order: 'Order',
      family: 'Family',
      genus: 'Genus',
      species: 'Species'
    },
    externalLinksTitle: 'External Resources'
  };
});