define("plutof/templates/taxon-description/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NnB0L7Mc",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"taxon-description.view\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@editRoute\",\"@goBack\",\"@module\",\"@accessRights\",\"@deletable\"],[[28,[37,2],[\"taxon-description\"],null],[28,[37,3],[\"TaxonDescription.taxonDesc\"],null],[30,0,[\"model\",\"taxonDescription\"]],\"taxon-description.edit\",[30,0,[\"routeActions\",\"goBack\"]],\"taxonomylab/taxondescription\",[30,0,[\"model\",\"accessRights\"]],true]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@taxonDescription\"],[[30,0,[\"model\",\"taxonDescription\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],[[24,\"data-test\",\"TaxonomyLab::TaxonDescription::View::ConservationInstructions\"]],[[\"@title\",\"@collapse\"],[[28,[37,3],[\"TaxonDescription.conservationInstructions\"],null],[28,[37,6],[[30,0,[\"model\",\"taxonDescription\",\"conservation_instructions\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],[[24,0,\"form-control route-taxon-description-view__conservation-instructions\"],[24,\"data-test\",\"conservation_instructions\"]],[[\"@value\",\"@disabled\"],[[30,0,[\"model\",\"taxonDescription\",\"conservation_instructions\"]],true]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@object\"],[[30,0,[\"model\",\"taxonDescription\"]]]],null],[1,\"\\n\\n\"],[8,[39,9],null,[[\"@object\",\"@rights\"],[[30,0,[\"model\",\"taxonDescription\"]],[30,0,[\"model\",\"accessRights\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/view\",\"icon\",\"i18n-t\",\"taxonomy-lab/taxon-description/view\",\"plutof-panel/simple\",\"not\",\"flexible-textarea\",\"linked-items/view\",\"access/view-rights\"]]",
    "moduleName": "plutof/templates/taxon-description/view.hbs",
    "isStrictMode": false
  });
});