define("plutof/components/measurements/measurement-view/expanded-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield this.expand}}
  
  <Ui::Modal::Dialog
      @open={{this.open}}
      @close={{this.close}}
  >
      <:header>
          {{this.measurement.fullName}}
      </:header>
  
      <:body>
          <Measurements::MeasurementView::Expanded @measurement={{this.measurement}} />
      </:body>
  </Ui::Modal::Dialog>
  
  */
  {
    "id": "wLwMShve",
    "block": "[[[18,1,[[30,0,[\"expand\"]]]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@open\",\"@close\"],[[30,0,[\"open\"]],[30,0,[\"close\"]]]],[[\"header\",\"body\"],[[[[1,\"\\n        \"],[1,[30,0,[\"measurement\",\"fullName\"]]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,2],null,[[\"@measurement\"],[[30,0,[\"measurement\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"ui/modal/dialog\",\"measurements/measurement-view/expanded\"]]",
    "moduleName": "plutof/components/measurements/measurement-view/expanded-dialog.hbs",
    "isStrictMode": false
  });
  let ExpandedMeasurementDialog = _exports.default = (_class = class ExpandedMeasurementDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "measurement", _descriptor, this);
      _initializerDefineProperty(this, "open", _descriptor2, this);
    }
    expand(measurement) {
      this.measurement = measurement;
      this.open = true;
    }
    close() {
      this.open = false;
      this.measurement = null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "measurement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "expand", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "expand"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExpandedMeasurementDialog);
});