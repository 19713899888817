define("plutof/controllers/taxonomy/trees", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "@ember/utils", "plutof/misc/config", "plutof/utils/access", "plutof/utils/i18n", "plutof/utils/notifications", "ember-concurrency"], function (_exports, _controller, _object, _computed, _runloop, _service, _utils, _config, _access, _i18n, _notifications, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    ENABLE_BLOCK_POLL,
    BLOCK_POLL_INTERVAL
  } = _config.default.Taxonomy;
  let TaxonomyTreesController = (_dec = (0, _object.computed)('selectedTree.id', 'tree_id'), _dec2 = (0, _object.computed)('treeId'), _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec4 = (0, _computed.and)('selectedTreeIsEditable', 'selectedTree.isBlocked'), _dec5 = (0, _object.computed)('selectedTreeIsEditable', 'selectedTree.isBlocked'), _class = class TaxonomyTreesController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _defineProperty(this, "queryParams", ['tree_id', 'taxon_id']);
      _defineProperty(this, "tree_id", null);
      _defineProperty(this, "taxon_id", null);
      _defineProperty(this, "selectedTree", null);
      _defineProperty(this, "selectedTaxon", null);
      _defineProperty(this, "selectedTreeIsEditable", false);
      _defineProperty(this, "selectedTreeOwnedByMe", false);
      _defineProperty(this, "taxonImportOpen", false);
      _initializerDefineProperty(this, "checkTreeRights", _descriptor3, this);
      _initializerDefineProperty(this, "showBlockMessage", _descriptor4, this);
    }
    resetTree() {
      this.clearTaxon();
    }
    clearTaxon() {
      this.set('selectedTaxon', null);
      this.set('taxon_id', null);
    }
    selectTree(tree) {
      this.clearTaxon();

      // TODO: selectedTree should be a computed property dependant on tree_id, probably
      this.set('tree_id', (0, _utils.isNone)(tree) ? null : tree.id);
      this.set('selectedTree', tree);
      this.checkTreeRights.perform();
    }

    // XXX Is is even necessary?
    get treeId() {
      return this.get('selectedTree.id') || this.get('tree_id');
    }
    set treeId(value) {
      return;
    }
    get canImportTaxa() {
      return parseInt(this.get('treeId')) === _config.default.Taxonomy.DEFAULT_TAXONOMY_ID;
    }
    pollBlock(tree) {
      if (this.selectedTree === tree && tree.get('isBlocked')) {
        tree.reload().then(() => {
          (0, _runloop.later)(this, 'pollBlock', tree, BLOCK_POLL_INTERVAL);
        });
      }
    }
    get canEditTree() {
      return this.selectedTreeIsEditable && !this.get('selectedTree.isBlocked');
    }
    selectTaxon(taxon) {
      this.set('selectedTaxon', taxon);
      this.set('taxon_id', (0, _utils.isNone)(taxon) ? null : taxon.get('id'));
    }
    editTaxon(taxon) {
      this.router.transitionTo('taxonomy.edit', taxon.id);
    }
    addTaxon() {
      return this.router.transitionTo('taxonomy.add', this.treeId);
    }

    // taxon here can be the entire taxon tree (XXX wat)
    deleteTaxon(taxon) {
      const trees = this.get('model.trees');
      const resetTree = trees.findBy('id', this.get('selectedTree.id'));
      this.set('selectedTree', null);
      this.set('deleting', true);
      return taxon.destroyCompletely().then(() => {
        this.set('deleting', false);
        this.set('selectedTaxon', null);
        this.store.unloadRecord(taxon);
        if (resetTree.get('isDeleted')) {
          trees.removeObject(resetTree);
        } else {
          this.set('selectedTree', resetTree);
        }
      }, error => {
        (0, _notifications.reportError)(error);
        this.set('deleting', false);
      });
    }
    restoreTaxon(taxonId) {
      const url = `/taxonomy/taxonnodes/${taxonId}/restore_taxon_node/`;
      const resetTree = this.model.trees.findBy('id', this.get('selectedTree.id'));
      this.set('selectedTree', null);
      this.ajax.put(url, {
        data: {}
      }).then(() => {
        this.set('selectedTree', resetTree);
        (0, _notifications.displayNotification)('success', (0, _i18n.getI18n)().t('General.done'));
      }, _notifications.reportError);
    }
    openTaxonImport() {
      this.set('taxonImportOpen', true);
    }
    closeTaxonImport() {
      this.set('taxonImportOpen', false);
    }
    afterImport(taxon) {
      this.selectTree({
        id: _config.default.Taxonomy.DEFAULT_TAXONOMY_ID
      });
      this.selectTaxon(taxon);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetTree", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetTree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTree", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectTree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "treeId", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "treeId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canImportTaxa", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "canImportTaxa"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "checkTreeRights", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.set('selectedTreeIsEditable', false);
        this.set('selectedTreeOwnedByMe', false);
        const tree = this.selectedTree;
        if (!(0, _utils.isNone)(tree) && !(0, _utils.isNone)(tree.get('id'))) {
          yield tree.reload().then(() => {
            (0, _access.get_permissions)(this.store, this.ajax, tree).then(permissions => {
              // That is the first bit we want from this.
              this.set('selectedTreeIsEditable', permissions.get('canModify'));
              this.set('selectedTreeOwnedByMe', permissions.get('ownedByCurrentUser'));

              // That is the second bit we want from this.
              if (ENABLE_BLOCK_POLL && permissions.get('canModify') && tree.get('isBlocked')) {
                // XXX: The only way to actually reload select's values
                (0, _runloop.later)(this, 'pollBlock', tree, BLOCK_POLL_INTERVAL);
              }
            });
          });
        }
      };
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showBlockMessage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canEditTree", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "canEditTree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "restoreTaxon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "restoreTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openTaxonImport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openTaxonImport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeTaxonImport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeTaxonImport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterImport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "afterImport"), _class.prototype), _class);
  var _default = _exports.default = TaxonomyTreesController;
});