define("plutof/components/quick-browse", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "ember-concurrency", "rsvp", "plutof/config/environment", "plutof/utils/modules", "plutof/utils/notifications", "plutof/utils/search", "plutof/utils/search/modules"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _computed, _service, _utils, _emberConcurrency, _rsvp, _environment, _modules, _notifications, _search, _modules2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.findRecord = findRecord;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2, _descriptor5, _dec3, _class3, _descriptor6, _descriptor7, _descriptor8, _class4, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
      {{on-click-outside this.closePopup}}
      class="quick-browse-container {{if this.showList 'quick-browse-container--open'}}"
  >
      <QuickBrowse::ToggleButton
          @toggle={{this.toggleList}}
          @open={{this.showList}} />
  
      <div class="quick-browse-content">
          <div class="quick-browse-content__body">
              <QuickBrowse::Filters
                  @browseData={{this.data}}
                  @updateFilters={{this.updateFilters}} />
  
              <QuickBrowse::List
                  @browseData={{this.data}}
                  @pinController={{this.pinController}}
                  @selectRecord={{this.selectRecord}}
                  @closePopup={{this.closePopup}}
                  @reload={{this.reload}} />
          </div>
  
          {{#if this.selectedRecord}}
              <QuickBrowse::RecordPopup
                  @record={{this.selectedRecord}}
                  @module={{this.data.module}}
                  @closePopup={{this.closePopup}} />
          {{/if}}
      </div>
  </div>
  
  */
  {
    "id": "gnA3s8Ta",
    "block": "[[[11,0],[16,0,[29,[\"quick-browse-container \",[52,[30,0,[\"showList\"]],\"quick-browse-container--open\"]]]],[4,[38,1],[[30,0,[\"closePopup\"]]],null],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@toggle\",\"@open\"],[[30,0,[\"toggleList\"]],[30,0,[\"showList\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"quick-browse-content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"quick-browse-content__body\"],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@browseData\",\"@updateFilters\"],[[30,0,[\"data\"]],[30,0,[\"updateFilters\"]]]],null],[1,\"\\n\\n            \"],[8,[39,4],null,[[\"@browseData\",\"@pinController\",\"@selectRecord\",\"@closePopup\",\"@reload\"],[[30,0,[\"data\"]],[30,0,[\"pinController\"]],[30,0,[\"selectRecord\"]],[30,0,[\"closePopup\"]],[30,0,[\"reload\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"selectedRecord\"]],[[[1,\"            \"],[8,[39,5],null,[[\"@record\",\"@module\",\"@closePopup\"],[[30,0,[\"selectedRecord\"]],[30,0,[\"data\",\"module\"]],[30,0,[\"closePopup\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"on-click-outside\",\"quick-browse/toggle-button\",\"quick-browse/filters\",\"quick-browse/list\",\"quick-browse/record-popup\"]]",
    "moduleName": "plutof/components/quick-browse.hbs",
    "isStrictMode": false
  });
  const LINE_HEIGHT = 20;

  // Find record match by ID and content type.
  function findRecord(record, records) {
    return records.find(item => item.id === record.id && item.data.content_type_id === record.data.content_type_id);
  }
  function getSearchParams(filters, pagination) {
    const params = Object.assign({}, filters);
    params.page = pagination.pageNumber;
    params.page_size = pagination.pageSize;

    // Ugliest way to do this, but it works.
    return Object.keys(params).map(key => {
      const value = params[key];
      if ((0, _utils.isNone)(value)) {
        return null;
      }
      return `${key}=${params[key]}`;
    }).compact().join('&');
  }
  async function documentTypeContent(document, _ref2) {
    let {
      store
    } = _ref2;
    const contentType = await store.findRecord('contenttype', document['content_type_id']);
    return {
      modelName: contentType.model_name,
      icon: (0, _modules.get_model_icon)(contentType),
      viewRoute: (0, _modules.get_view_route)(contentType)
    };
  }

  // TODO: Consider merging pagination and pin controllers.
  let Pagination = (_dec = (0, _computed.equal)('pageNumber', 1), _dec2 = (0, _object.computed)('count', 'pageSize', 'pageNumber'), _class = class Pagination {
    constructor() {
      _initializerDefineProperty(this, "pageNumber", _descriptor, this);
      _initializerDefineProperty(this, "pageSize", _descriptor2, this);
      _initializerDefineProperty(this, "count", _descriptor3, this);
      _initializerDefineProperty(this, "isFirstPage", _descriptor4, this);
    }
    get isLastPage() {
      const pages = Math.ceil(this.count / this.pageSize);
      return this.pageNumber >= pages || this.count === 0;
    }
    setPage(page) {
      this.pageNumber = page;
    }
    updatePageSize(pinController) {
      const container = document.getElementById('quick-browse-list-container');
      // - 2 to leave room for next and previous page button.
      // Do we need this?
      const maxLines = Math.floor(container.clientHeight / LINE_HEIGHT) - 2;
      const pageSize = maxLines - pinController.records.length;
      if (this.pageSize !== pageSize) {
        this.pageSize = pageSize;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "pageNumber", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pageSize", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "count", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isFirstPage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isLastPage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isLastPage"), _class.prototype), _class);
  let PinnedRecordsController = (_class2 = class PinnedRecordsController {
    constructor() {
      _initializerDefineProperty(this, "records", _descriptor5, this);
    }
    toggle(record) {
      const pinnedRecord = findRecord(record, this.records);
      if (pinnedRecord) {
        this.unpinRecord(pinnedRecord);
      } else {
        this.pinRecord(record);
      }
    }
    pinRecord(record) {
      (0, _object.set)(record, 'isPinned', true);
      this.records.pushObject(record);
    }
    unpinRecord(record) {
      (0, _object.set)(record, 'isPinned', false);
    }
    toggleRecord(record) {
      (0, _object.set)(record, 'isPinned', !record.isPinned);
    }
    compact() {
      this.records = this.records.filter(record => record.isPinned);
    }
  }, _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "records", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggle"), _class2.prototype), _class2);
  let QuickBrowseData = (_dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class3 = class QuickBrowseData {
    constructor(_ref3) {
      let {
        session,
        store,
        module,
        filters
      } = _ref3;
      _initializerDefineProperty(this, "records", _descriptor6, this);
      _initializerDefineProperty(this, "pinnedRecords", _descriptor7, this);
      _defineProperty(this, "session", null);
      _defineProperty(this, "pagination", null);
      _defineProperty(this, "filters", {});
      _initializerDefineProperty(this, "module", _descriptor8, this);
      _defineProperty(this, "processResponse", results => {
        const records = results.map(async result => {
          const typeContent = await documentTypeContent(result, {
            store: this.store
          });
          return Object.assign({
            id: result.id,
            data: result,
            isPinned: false
          }, typeContent);
        });
        return _rsvp.default.all(records);
      });
      this.filters = filters;
      this.module = module;
      this.session = session;
      this.store = store;
      this.pagination = new Pagination();
    }
    async loadPage(session, moduleEndpoint, searchParams) {
      const headers = {
        'Authorization': `Bearer ${session.data.authenticated.access_token}`
      };
      const baseUrl = `${_environment.default.API_HOST}/${moduleEndpoint}${_search.SEARCH_ENDPOINT}`;
      const url = searchParams ? `${baseUrl}?${searchParams}` : baseUrl;
      const response = await fetch(url, {
        headers
      });
      return response.json();
    }
    *load() {
      try {
        const searchParams = getSearchParams(this.filters, this.pagination);
        const response = yield this.loadPage(this.session, this.module.value, searchParams);
        this.records = yield this.processResponse(response.results);
        this.pagination.count = response.count;
      } catch (err) {
        this.records = [];
        this.pagination.count = 0;
        (0, _notifications.reportError)(err);
      }
    }
    setFilters(filters) {
      Object.assign(this.filters, filters);
    }
    setPage(page) {
      this.pagination.pageNumber = page;
    }
    nextPage() {
      this.pagination.pageNumber += 1;
      return this.load.perform();
    }
    previousPage() {
      this.pagination.pageNumber -= 1;
      return this.load.perform();
    }
    clearResults() {
      this.records.clear();
    }
    setModule(module) {
      this.module = module;
      this.setPage(1);
      this.load.perform();
    }
  }, _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "records", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "pinnedRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "module", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "load", [_dec3], Object.getOwnPropertyDescriptor(_class3.prototype, "load"), _class3.prototype), _class3);
  let QuickBrowse = (_class4 = class QuickBrowse extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor9, this);
      _initializerDefineProperty(this, "store", _descriptor10, this);
      _initializerDefineProperty(this, "selectedRecord", _descriptor11, this);
      _initializerDefineProperty(this, "data", _descriptor12, this);
      _initializerDefineProperty(this, "showList", _descriptor13, this);
      _initializerDefineProperty(this, "pinController", _descriptor14, this);
      const module = _modules2.default.findBy('id', 'taxonoccurrence');
      this.data = new QuickBrowseData({
        filters: {},
        module: module,
        session: this.session,
        store: this.store
      });
      this.pinController = new PinnedRecordsController();
    }
    selectRecord(record) {
      this.selectedRecord = record;
    }
    closePopup() {
      this.selectedRecord = null;
    }
    updateFilters() {
      let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.data.pagination.updatePageSize(this.pinController);

      // XXX If taxon_node filter is used, include parent_taxon filter.
      const _filters = Object.assign({
        parent_taxon: (0, _utils.isNone)(filters.taxon_node) ? null : true
      }, filters);
      this.data.setFilters(_filters);
      this.data.clearResults();
      this.data.setPage(1);
      this.compactPins();
    }
    toggleList() {
      this.showList = !this.showList;
      if (this.showList) {
        this.data.pagination.updatePageSize(this.pinController);
        this.data.load.perform();
      }
    }
    reload() {
      this.compactPins();
      this.data.load.perform();
    }
    compactPins() {
      this.pinController.compact();
      this.data.pagination.updatePageSize(this.pinController);
    }
  }, _descriptor9 = _applyDecoratedDescriptor(_class4.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class4.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class4.prototype, "selectedRecord", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class4.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class4.prototype, "showList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class4.prototype, "pinController", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class4.prototype, "selectRecord", [_object.action], Object.getOwnPropertyDescriptor(_class4.prototype, "selectRecord"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "closePopup", [_object.action], Object.getOwnPropertyDescriptor(_class4.prototype, "closePopup"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "updateFilters", [_object.action], Object.getOwnPropertyDescriptor(_class4.prototype, "updateFilters"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "toggleList", [_object.action], Object.getOwnPropertyDescriptor(_class4.prototype, "toggleList"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "reload", [_object.action], Object.getOwnPropertyDescriptor(_class4.prototype, "reload"), _class4.prototype), _class4);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuickBrowse);
});