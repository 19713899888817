define("plutof/components/linked-items/view/-tab-wrapper", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@group.simpleTab
      @name={{@name}}
      @header={{@header}}
      @count={{@count}}
  >
      {{@content}}
  </@group.simpleTab>
  
  */
  {
    "id": "RpLXTJek",
    "block": "[[[8,[30,1,[\"simpleTab\"]],null,[[\"@name\",\"@header\",\"@count\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,5]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@group\",\"@name\",\"@header\",\"@count\",\"@content\"],false,[]]",
    "moduleName": "plutof/components/linked-items/view/-tab-wrapper.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});