define("plutof/templates/components/common/local-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "8BxJSdog",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@tooltip\",\"@infoLinks\"],[[52,[30,1],[28,[37,2],[[30,1]],null]],[52,[30,2],[28,[37,2],[[30,2]],null]],[52,[30,3],[28,[37,3],[[28,[37,4],null,[[\"url\"],[[30,3]]]]],null]]]],null],[1,\"\\n\"]],[\"@label\",\"@tooltip\",\"@infoLink\"],false,[\"ui/label\",\"if\",\"i18n-t\",\"array\",\"hash\"]]",
    "moduleName": "plutof/templates/components/common/local-label.hbs",
    "isStrictMode": false
  });
});