define("plutof/templates/components/datacite/taxa-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0PxMNgRF",
    "block": "[[[8,[39,0],[[24,0,\"datacite-edit-taxa\"]],[[\"@collapse\"],[[28,[37,1],[[30,1,[\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"datacite.panels.taxa\"],null],[30,1,[\"length\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\",\"@class\"],[[28,[37,3],[[30,0],[30,0,[\"add\"]]],null],\"test-marker-btn-add\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"table-responsive\"],[12],[1,\"\\n            \"],[10,\"table\"],[14,0,\"data-table\"],[12],[1,\"\\n                \"],[10,\"thead\"],[12],[1,\"\\n                    \"],[10,\"tr\"],[12],[1,\"\\n                        \"],[10,\"th\"],[14,\"colspan\",\"2\"],[12],[1,\"\\n                            \"],[1,[28,[35,4],[\"datacite.metadata.taxon.label\"],null]],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n\\n                \"],[10,\"tbody\"],[12],[1,\"\\n\"],[6,[39,5],null,[[\"context\",\"items\"],[[30,3],[30,1]]],[[\"default\"],[[[[1,\"                        \"],[1,[28,[35,6],null,[[\"taxon\",\"validationContext\",\"remove\"],[[30,4],[30,5],[28,[37,3],[[30,0],\"remove\",[30,4]],null]]]]],[1,\"\\n\"]],[4,5]]]]],[1,\"                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@taxa\",\"panel\",\"@validationContext\",\"taxon\",\"context\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"action\",\"common/local-label\",\"validation/split-context\",\"datacite/taxon-row-form\"]]",
    "moduleName": "plutof/templates/components/datacite/taxa-form.hbs",
    "isStrictMode": false
  });
});