define("plutof/components/transaction/occurrence-transactions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Transaction::Occurrence::View @filters={{@filters}} as |transactions|>
      <RelatedObjects::View
          @pagination={{transactions.pagination}}
          @title={{i18n-t "transactions.title"}}
      >
          {{transactions.view}}
      </RelatedObjects::View>
  </Transaction::Occurrence::View>
  
  */
  {
    "id": "4oxQByLu",
    "block": "[[[8,[39,0],null,[[\"@filters\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@pagination\",\"@title\"],[[30,2,[\"pagination\"]],[28,[37,2],[\"transactions.title\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"view\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@filters\",\"transactions\"],false,[\"transaction/occurrence/view\",\"related-objects/view\",\"i18n-t\"]]",
    "moduleName": "plutof/components/transaction/occurrence-transactions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});