define("plutof/routes/sequence/edit", ["exports", "plutof/components/sequence/edit", "plutof/utils/routes"], function (_exports, _edit, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class SequenceEditRoute extends _routes.EditRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "confirmExit", true);
    }
    async model(params) {
      const sequence = await this.store.findRecord('taxonoccurrence/sequence/sequence', params.id);
      const sequenceData = await (0, _edit.loadSequenceData)(this.store, sequence);
      return {
        sequenceData
      };
    }
    getTitle(model) {
      return model.sequenceData.sequence.compound_id;
    }
    transitionToDefaultRoute() {
      return this.transitionTo('sequence.view', this.currentModel.sequenceData.sequence.id);
    }
    deactivate() {
      super.deactivate();
      if (this.currentModel) {
        this.currentModel.sequenceData.cleanup();
      }
    }
  }
  var _default = _exports.default = SequenceEditRoute;
});