define("plutof/components/experiment/common/occurrence-experiments/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/utils/pagination"], function (_exports, _component, _templateFactory, _component2, _object, _service, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.pagination wait=true as |pagination|}}
      {{yield (hash
          pagination=pagination
          view=(component "experiment/common/occurrence-experiments/-content" pagination=pagination)
      )}}
  {{/resolve-promise}}
  
  */
  {
    "id": "YD5+W9Kq",
    "block": "[[[6,[39,0],[[30,0,[\"pagination\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[18,2,[[28,[37,2],null,[[\"pagination\",\"view\"],[[30,1],[50,\"experiment/common/occurrence-experiments/-content\",0,null,[[\"pagination\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"pagination\",\"&default\"],false,[\"resolve-promise\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/experiment/common/occurrence-experiments/view.hbs",
    "isStrictMode": false
  });
  let OccurrenceExperimentsView = _exports.default = (_dec = (0, _object.computed)('args.filters'), _class = class OccurrenceExperimentsView extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get pagination() {
      return (0, _pagination.paginatePublishingModel)(this.store, 'dna-lab/dna', {
        ...this.args.filters,
        ordering: '-id',
        include: 'dna_extraction'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pagination", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pagination"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OccurrenceExperimentsView);
});