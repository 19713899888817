define("plutof/controllers/referencebased/edit", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "rsvp", "plutof/misc/config", "plutof/mixins/component-validations", "plutof/mixins/edit-controller", "plutof/models/taxonoccurrence/referencebased/speciesstatus", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _controller, _object, _utils, _rsvp, _config, _componentValidations, _editController, _speciesstatus, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  let ReferencebasedEditController = (_class = class ReferencebasedEditController extends _controller.default.extend(_editController.default, _componentValidations.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "recordsToUnload", [{
        record: 'occurrence',
        subRecords: ['species_status']
      }]);
      _defineProperty(this, "defaultFormPanels", [{
        label: i18n.t('Forms.default'),
        id: _config.default.ReferenceBased.DEFAULT_MAINFORM_ID
      }]);
      _defineProperty(this, "validationChildren", [{
        name: 'occurrence',
        label: 'General.generalData'
      }, {
        name: 'project',
        label: 'General.project'
      }, {
        name: 'areaEvent',
        label: 'General.samplingAreaEvent'
      }, {
        name: 'access',
        label: 'General.access'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }]);
    }
    speciesStatusIsEmpty() {
      const speciesStatus = this.get('model.speciesStatus');
      const values = _speciesstatus.FIELDS.map(f => speciesStatus.get(f));
      return values.every(_utils.isEmpty);
    }
    async _save() {
      if (!this.speciesStatusIsEmpty()) {
        // TODO: Shouldn't we delete the existing one? Though if the save
        // fails, things can get messy
        const speciesStatus = await this.model.speciesStatus.save();
        this.model.occurrence.set('species_status', speciesStatus);
      }
      await this.model.areaEventData.applyAndSaveOccurrence(this.model.occurrence);
      await _rsvp.default.all([this.model.accessRights.save(), this.model.itemData.save(this.model.occurrence, this.model.accessRights), this.model.layers.save([this.model.occurrence])]);
    }
    save() {
      return (0, _notifications.notifyProcess)(this._save(), i18n).then(() => {
        this.set('routeHasBeenLoaded', false);
        this.routeActions.goBack();
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  var _default = _exports.default = ReferencebasedEditController;
});