define("plutof/components/access/-view-rights/history", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#access/-view-rights/column}}
      <ViewField @label={{i18n-t "Access.created"}}>
          {{formatted-date @createdAt}} <br />
          {{@createdBy}}
      </ViewField>
  {{/access/-view-rights/column}}
  
  {{#access/-view-rights/column}}
      <ViewField @label={{i18n-t "Access.modified"}}>
          {{formatted-date @updatedAt}} <br />
          {{@updatedBy}}
      </ViewField>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "efKThspW",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@label\"],[[28,[37,2],[\"Access.created\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,1]],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@label\"],[[28,[37,2],[\"Access.modified\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,3]],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@createdAt\",\"@createdBy\",\"@updatedAt\",\"@updatedBy\"],false,[\"access/-view-rights/column\",\"view-field\",\"i18n-t\",\"formatted-date\"]]",
    "moduleName": "plutof/components/access/-view-rights/history.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});