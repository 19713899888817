define("plutof/templates/components/access/view-rights", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vWu50J1p",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@rights\",\"@record\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@rights\",\"@object\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"access/view-content\"]]",
    "moduleName": "plutof/templates/components/access/view-rights.hbs",
    "isStrictMode": false
  });
});