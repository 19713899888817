define("plutof/components/clipboard/bulk-update-preparation", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "ember-data", "@ember-decorators/component", "@ember-decorators/object", "ember-cp-validations", "plutof/misc/options-getter", "plutof/mixins/component-validations"], function (_exports, _component, _object, _computed, _runloop, _service, _emberData, _component2, _object2, _emberCpValidations, _optionsGetter, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'preparation.type': (0, _emberCpValidations.validator)('presence', true),
    'preparation.name': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    'preparation.remarks': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    'preparation.prepared_location': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    'preparation.method_desc': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    'preparation.count': (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }),
    'preparation.storage_location': (0, _emberCpValidations.validator)('length', {
      max: 254
    })
  });
  let BulkUpdatePreparation = (_dec = (0, _component2.classNames)('bulk-update-preparation'), _dec2 = (0, _computed.alias)('bulkUpdateData.data.preparation'), _dec3 = (0, _computed.alias)('validations.isValid'), _dec4 = (0, _object2.observes)('isValid', 'preparation'), _dec5 = (0, _object.computed)('typeURL'), _dec(_class = (_class2 = class BulkUpdatePreparation extends _component.default.extend(Validations, _componentValidations.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "preparation", _descriptor2, this);
      _initializerDefineProperty(this, "isValid", _descriptor3, this);
    }
    // XXX
    validatorChanged() {
      if (this.preparation) {
        this.set('preparation.isValid', this.isValid);
      }
    }
    init() {
      super.init(...arguments);

      // XXX, See bulk-update-specimen-general
      (0, _runloop.next)(() => {
        // Stores ember records, for the sake of displaying autocomplete prepared BY fields
        this.set('personDummyWrapper', []);
        this.set('bulkUpdateData.data.preparation', {});
        this.set('bulkUpdateData.data.preparation.persons', []); // Stores personDummyWrapper's record URLS
      });
    }
    get preparationTypes() {
      return _emberData.default.PromiseArray.create({
        promise: (0, _optionsGetter.getChoices)(this.ajax, this.typeURL, 'type', {
          preserveEmpty: true
        })
      });
    }
    addAgent(person) {
      if (person) {
        this.personDummyWrapper.pushObject(person);
        this.get('preparation.persons').pushObject(person.get('url'));
      }
    }
    removeAgent(person) {
      this.personDummyWrapper.removeObject(person);
      this.get('preparation.persons').pushObject(person.get('url'));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "preparation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isValid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "validatorChanged", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "validatorChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "preparationTypes", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "preparationTypes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addAgent", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addAgent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeAgent", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeAgent"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = BulkUpdatePreparation;
});