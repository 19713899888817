define("plutof/components/specimen/view/abridged/gallery", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "ember-concurrency", "rsvp", "plutof/utils/push-to-store", "plutof/utils/reflection"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _object, _emberConcurrency, _rsvp, _pushToStore, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!--
      Hate this, but can't just use a cp, because need to reset the selected
      item and there is still no way to do that properly in this trashfire
      of a framework
  --}}
  {{on-change (perform this.load) @specimen}}
  
  {{#if (and this.load.last.isSuccessful this.items)}}
      <PlutofPanel::Headerless @withMargin={{true}}>
          <div class="abridged-gallery">
              {{#if this.selectedItem}}
                  <button
                      {{on "click" (fn this.openGallery this.selectedItem)}}
                      class="btn-reset abridged-gallery__selected"
                  >
                      <Filerepository::Gallery::-Preview
                          @filename={{this.selectedItem.file.file_name}}
                          @type={{this.selectedItem.file.type}}
                          @links={{this.selectedItem.file.download_links}}
                          @disableZoom={{true}} />
                  </button>
              {{/if}}
  
              <div class="abridged-gallery__items">
                  {{#each this.items as |item|}}
                      <div class="abridged-gallery__item {{if (is-equal item this.selectedItem) 'abridged-gallery__item--selected'}}">
                          <LinkedItems::View::FileItem
                              @item={{item}}
                              @open={{fn this.select item}}
                              @hideTitle={{true}} />
                      </div>
                  {{/each}}
              </div>
          </div>
  
          <Filerepository::Gallery
              @items={{this.items}}
              @active={{this.galleryItem}} />
      </PlutofPanel::Headerless>
  {{/if}}
  
  */
  {
    "id": "CdLzpqFJ",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[[30,0,[\"load\"]]],null],[30,1]],null]],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,0,[\"load\",\"last\",\"isSuccessful\"]],[30,0,[\"items\"]]],null],[[[1,\"    \"],[8,[39,4],null,[[\"@withMargin\"],[true]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"abridged-gallery\"],[12],[1,\"\\n\"],[41,[30,0,[\"selectedItem\"]],[[[1,\"                \"],[11,\"button\"],[24,0,\"btn-reset abridged-gallery__selected\"],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"openGallery\"]],[30,0,[\"selectedItem\"]]],null]],null],[12],[1,\"\\n                    \"],[8,[39,7],null,[[\"@filename\",\"@type\",\"@links\",\"@disableZoom\"],[[30,0,[\"selectedItem\",\"file\",\"file_name\"]],[30,0,[\"selectedItem\",\"file\",\"type\"]],[30,0,[\"selectedItem\",\"file\",\"download_links\"]],true]],null],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n            \"],[10,0],[14,0,\"abridged-gallery__items\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"                    \"],[10,0],[15,0,[29,[\"abridged-gallery__item \",[52,[28,[37,10],[[30,2],[30,0,[\"selectedItem\"]]],null],\"abridged-gallery__item--selected\"]]]],[12],[1,\"\\n                        \"],[8,[39,11],null,[[\"@item\",\"@open\",\"@hideTitle\"],[[30,2],[28,[37,6],[[30,0,[\"select\"]],[30,2]],null],true]],null],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[2]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,12],null,[[\"@items\",\"@active\"],[[30,0,[\"items\"]],[30,0,[\"galleryItem\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@specimen\",\"item\"],false,[\"on-change\",\"perform\",\"if\",\"and\",\"plutof-panel/headerless\",\"on\",\"fn\",\"filerepository/gallery/-preview\",\"each\",\"-track-array\",\"is-equal\",\"linked-items/view/file-item\",\"filerepository/gallery\"]]",
    "moduleName": "plutof/components/specimen/view/abridged/gallery.hbs",
    "isStrictMode": false
  });
  let SpecimenAbridgedViewGallery = _exports.default = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class SpecimenAbridgedViewGallery extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "selectedItem", _descriptor3, this);
      _initializerDefineProperty(this, "items", _descriptor4, this);
      _initializerDefineProperty(this, "galleryItem", _descriptor5, this);
      _initializerDefineProperty(this, "load", _descriptor6, this);
    }
    async loadItems(record) {
      const items = await this.ajax.request((0, _reflection.get_record_url)(record) + 'files/');
      for (const item of items) {
        item.file = (0, _pushToStore.default)(this.store, 'filerepository/file', item.file);
      }
      return items;
    }
    select(item) {
      this.selectedItem = item;
    }
    openGallery(item) {
      this.galleryItem = item;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedItem", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "items", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "galleryItem", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "load", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const event = yield this.args.specimen.samplingevent;
        const [eventItems, specimenItems] = yield _rsvp.default.all([this.loadItems(this.args.specimen), this.loadItems(event)]);
        const items = eventItems.concat(specimenItems);
        this.selectedItem = items[0];
        this.items = items;
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openGallery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openGallery"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SpecimenAbridgedViewGallery);
});