define("plutof/components/tab-group", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/runloop"], function (_exports, _component, _object, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Tab extends _object.default {}
  class TabGroup extends _component.default {
    constructor() {
      super(...arguments);
      // Until the user has manually switched something, we can switch tabs
      // ourselves
      _defineProperty(this, "receivedManualSelection", false);
    }
    init() {
      super.init(...arguments);
      this.set('tabs', []);
      this.set('headersContainerId', Math.floor(Math.random() * 1e9).toString());
    }
    createTab(name) {
      return Tab.create({
        name,
        selected: false
      });
    }
    getTab(name) {
      const tabs = this.tabs;
      const selectedName = this.selected;
      let tab = tabs.findBy('name', name);
      if ((0, _utils.isNone)(tab)) {
        tab = this.createTab(name);
        tabs.pushObject(tab);

        // TODO: external initial selection
        const selected = selectedName === name || (0, _utils.isNone)(selectedName) && tabs.get('length') === 1 || !this.receivedManualSelection && this.forcedOrder && name === this.forcedOrder.filter(name => tabs.findBy('name', name))[0];

        // XXX: Then whole @tabSwitched is a nest of hacks. This is
        // necessary, so that the history tab in abridged specimen
        // view loads correctly when it's auto-selected as the first
        if (selected) {
          (0, _runloop.next)(() => {
            this.tabs.forEach(t => {
              t.set('selected', t === tab);
            });
            this.notifySelection(tab);
          });
        }
        tab.set('selected', selected);
        return tab;
      }
      return tab;
    }
    removeTab(name) {
      const tab = this.getTab(name);
      if (tab?.selected) {
        const newSelectedIndex = Math.min(this.tabs.indexOf(tab), this.tabs.length - 2);
        this.tabs.removeObject(tab);
        const newSelected = this.tabs[newSelectedIndex];
        if (newSelected) {
          this.switchTab(newSelected);
        }
      }
    }
    notifySelection(tab) {
      if (!(0, _utils.isNone)(this.tabSwitched)) {
        this.tabSwitched(tab.get('name'));
      }
    }
    switchTab(tab) {
      let manual = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.tabs.forEach(t => {
        t.set('selected', t === tab);
      });
      if (manual) {
        this.notifySelection(tab);
        this.receivedManualSelection = true;
      }
    }
    get headersContainer() {
      return document.getElementById(this.headersContainerId);
    }
  }
  var _default = _exports.default = TabGroup;
});