define("plutof/components/linked-items/view/keywords", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkedItems::View::-LoadItems
      @record={{@record}}
      @itemModel="glossary/item"
      @disabled={{@disabled}}
      as |items|
  >
      {{yield (hash
          count=items.objectCount
          tab=(if (not disabled) (component "linked-items/view/-keywords-tab" group=@group items=items)))}}
  </LinkedItems::View::-LoadItems>
  
  */
  {
    "id": "b9gMsDqU",
    "block": "[[[8,[39,0],null,[[\"@record\",\"@itemModel\",\"@disabled\"],[[30,1],\"glossary/item\",[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[28,[37,2],null,[[\"count\",\"tab\"],[[30,3,[\"objectCount\"]],[52,[28,[37,4],[[33,5]],null],[50,\"linked-items/view/-keywords-tab\",0,null,[[\"group\",\"items\"],[[30,4],[30,3]]]]]]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@record\",\"@disabled\",\"items\",\"@group\",\"&default\"],false,[\"linked-items/view/-load-items\",\"yield\",\"hash\",\"if\",\"not\",\"disabled\",\"component\"]]",
    "moduleName": "plutof/components/linked-items/view/keywords.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});