define("plutof/components/view-preparations", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Preparation::View @record={{@parent}} as |preparations|>
      <PlutofPanel::ItemList
          @collapse={{or @closeOverride preparations.empty}}
          data-test="ViewPreparations"
          as |panel|
      >
          <panel.header
              @title={{i18n-t "General.preparations"}}
              @count={{preparations.count}} />
  
          <panel.innerPanels>
              <preparations.view as |view preparation|>
                  <PlutofPanel::Inner
                      @bodyClass={{annotation/added-removed-class preparation}}
                      data-test="preparation"
                  >
                      {{view}}
                  </PlutofPanel::Inner>
              </preparations.view>
          </panel.innerPanels>
      </PlutofPanel::ItemList>
  </Preparation::View>
  
  */
  {
    "id": "lfLSzMiw",
    "block": "[[[8,[39,0],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,\"data-test\",\"ViewPreparations\"]],[[\"@collapse\"],[[28,[37,2],[[30,3],[30,2,[\"empty\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,3],[\"General.preparations\"],null],[30,2,[\"count\"]]]],null],[1,\"\\n\\n        \"],[8,[30,4,[\"innerPanels\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,2,[\"view\"]],null,null,[[\"default\"],[[[[1,\"\\n                \"],[8,[39,4],[[24,\"data-test\",\"preparation\"]],[[\"@bodyClass\"],[[28,[37,5],[[30,6]],null]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,5]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"]],[5,6]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@parent\",\"preparations\",\"@closeOverride\",\"panel\",\"view\",\"preparation\"],false,[\"preparation/view\",\"plutof-panel/item-list\",\"or\",\"i18n-t\",\"plutof-panel/inner\",\"annotation/added-removed-class\"]]",
    "moduleName": "plutof/components/view-preparations.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});