define("plutof/controllers/filerepository/upload", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "@glimmer/tracking", "rsvp", "plutof/misc/config", "plutof/misc/fileupload", "plutof/utils/notifications", "plutof/utils/access", "plutof/utils/structures"], function (_exports, _controller, _object, _computed, _service, _utils, _tracking, _rsvp, _config, _fileupload, _notifications, _access, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function uploadByBatches(uploads, accessRights) {
    const batches = (0, _structures.chop)(_config.default.Filerepository.UPLOAD_BATCH_SIZE, uploads);
    function upload() {
      const first = batches.shift();
      if ((0, _utils.isNone)(first)) {
        return;
      }
      return _rsvp.default.Promise.all(first.map(upload => upload.save(accessRights))).then(upload);
    }
    return upload();
  }
  let FileUploadController = (_dec = (0, _computed.empty)('model.uploader.uploads'), _dec2 = (0, _computed.or)('uploadingFiles', 'showRetransformControls'), _class = class FileUploadController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fileUpload", _descriptor, this);
      _initializerDefineProperty(this, "showRetransformControls", _descriptor2, this);
      _initializerDefineProperty(this, "fileBeingRetransformed", _descriptor3, this);
      _initializerDefineProperty(this, "uploadingFiles", _descriptor4, this);
      _initializerDefineProperty(this, "noUploads", _descriptor5, this);
      _initializerDefineProperty(this, "uploadingInProgress", _descriptor6, this);
    }
    async uploadFiles() {
      const files = await (0, _fileupload.selectFile)({
        multiple: true
      });
      this.uploadingFiles = true;
      try {
        if ((0, _utils.isEmpty)(files)) {
          return;
        }
        const uploads = await this.fileUpload.addLocalFiles(files);
        if (uploads.length === 1 && uploads[0].toBeRetransformed) {
          this.fileBeingRetransformed = uploads[0];
          this.showRetransformControls = true;
        } else {
          const accessRights = await (0, _access.create_access_rights)(this.store);
          await uploadByBatches(uploads, accessRights);
        }
      } catch (err) {
        (0, _notifications.reportError)(err);
      } finally {
        this.uploadingFiles = false;
      }
    }
    async acceptArea() {
      try {
        this.showRetransformControls = false;
        const accessRights = await (0, _access.create_access_rights)(this.store);
        await this.fileBeingRetransformed.save(accessRights);
        this.fileBeingRetransformed = null;
      } catch (err) {
        (0, _notifications.reportError)(err);
      }
    }
    changeArea(area) {
      this.fileBeingRetransformed.retransformRect = area;
      this.acceptArea();
    }
    cancelRetransform() {
      this.showRetransformControls = false;
      this.fileBeingRetransformed = null;
    }

    // XXX This is a tad sketchy and should be part of fil-upload service.
    clear() {
      // Clear uploader uploads.
      this.model.uploader.uploads.clear();
      const uploads = this.fileUpload.localFiles;

      // Clear local file uploads.
      Object.keys(uploads).forEach(key => delete uploads[key]);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showRetransformControls", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fileBeingRetransformed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uploadingFiles", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "noUploads", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uploadingInProgress", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uploadFiles", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFiles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "acceptArea", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "acceptArea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeArea", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeArea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelRetransform", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelRetransform"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _class);
  var _default = _exports.default = FileUploadController;
});