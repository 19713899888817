define("plutof/components/annotation/record-annotations", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/misc/content_types", "plutof/utils/pagination/builder"], function (_exports, _component, _templateFactory, _component2, _content_types, _builder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadAnnotations = loadAnnotations;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @pagination={{@annotations}}
      @title={{i18n-t "annotation.table.title"}}
      data-anchor="annotations"
  >
      <Annotation::RecordAnnotations::List
          @annotations={{@annotations}}
          @accessRights={{@accessRights}} />
  </RelatedObjects::View>
  
  */
  {
    "id": "Lhaos0Yt",
    "block": "[[[8,[39,0],[[24,\"data-anchor\",\"annotations\"]],[[\"@pagination\",\"@title\"],[[30,1],[28,[37,1],[\"annotation.table.title\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@annotations\",\"@accessRights\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@annotations\",\"@accessRights\"],false,[\"related-objects/view\",\"i18n-t\",\"annotation/record-annotations/list\"]]",
    "moduleName": "plutof/components/annotation/record-annotations.hbs",
    "isStrictMode": false
  });
  async function loadAnnotations(ajax, record) {
    const ctype = await (0, _content_types.get_object_ctype)(record.store, record);
    const pagination = await (0, _builder.default)({
      ajax
    }).fromEndpoint('annotation/record-annotations/').withCount.fromEndpoint().withFilters({
      content_type: ctype.id,
      object_id: record.id,
      ordering: '-annotated_at'
    }).build();
    return pagination;
  }
  class RecordAnnotations extends _component2.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RecordAnnotations);
});