define("plutof/components/generic-views/-record/field-value", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (is-equal @meta.type "value")}}
      {{show-field @value}}
  {{else if (is-equal @meta.type "belongsTo")}}
      {{#if @meta.route}}
          <LinkTo @route={{@meta.route}} @model={{@value.id}}>
              {{@value.representation}}
          </LinkTo>
      {{else}}
          {{@value.representation}}
      {{/if}}
  {{else if (is-equal @meta.type "datetime")}}
      {{formatted-date @value}}
  {{else if (is-equal @meta.type "enum")}}
      {{#resolve-promise @meta.choices as |labels|}}
          {{get labels @value}}
      {{/resolve-promise}}
  {{else if (is-equal @meta.type "enum-multiple")}}
      {{#resolve-promise @meta.choices as |labels|}}
          <Common::RecordListView @records={{@value}} as |choice|>
              {{get labels choice}}
          </Common::RecordListView>
      {{/resolve-promise}}
  {{else if (is-equal @meta.type "hasMany")}}
      <Common::RecordListView @records={{@value}} as |item|>
          {{#if @meta.route}}
              <LinkTo @route={{@meta.route}} @model={{item.id}}>
                  {{item.representation}}
              </LinkTo>
          {{else}}
              {{item.representation}}
          {{/if}}
      </Common::RecordListView>
  {{/if}}
  
  */
  {
    "id": "+EiVqsPW",
    "block": "[[[41,[28,[37,1],[[30,1,[\"type\"]],\"value\"],null],[[[1,\"    \"],[1,[28,[35,2],[[30,2]],null]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"belongsTo\"],null],[[[41,[30,1,[\"route\"]],[[[1,\"        \"],[8,[39,3],null,[[\"@route\",\"@model\"],[[30,1,[\"route\"]],[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,2,[\"representation\"]]],[1,\"\\n\"]],[]]]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"datetime\"],null],[[[1,\"    \"],[1,[28,[35,4],[[30,2]],null]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"enum\"],null],[[[6,[39,5],[[30,1,[\"choices\"]]],null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,6],[[30,3],[30,2]],null]],[1,\"\\n\"]],[3]]]]]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"enum-multiple\"],null],[[[6,[39,5],[[30,1,[\"choices\"]]],null,[[\"default\"],[[[[1,\"        \"],[8,[39,7],null,[[\"@records\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,6],[[30,4],[30,5]],null]],[1,\"\\n        \"]],[5]]]]],[1,\"\\n\"]],[4]]]]]],[]],[[[41,[28,[37,1],[[30,1,[\"type\"]],\"hasMany\"],null],[[[1,\"    \"],[8,[39,7],null,[[\"@records\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"route\"]],[[[1,\"            \"],[8,[39,3],null,[[\"@route\",\"@model\"],[[30,1,[\"route\"]],[30,6,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,6,[\"representation\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,6,[\"representation\"]]],[1,\"\\n\"]],[]]],[1,\"    \"]],[6]]]]],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[]]]],[]]]],[]]]],[\"@meta\",\"@value\",\"labels\",\"labels\",\"choice\",\"item\"],false,[\"if\",\"is-equal\",\"show-field\",\"link-to\",\"formatted-date\",\"resolve-promise\",\"get\",\"common/record-list-view\"]]",
    "moduleName": "plutof/components/generic-views/-record/field-value.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});