define("plutof/translations/eng/specimen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Specimens',
    searchTitle: 'Search Specimens',
    infoTitle: 'Specimens',
    newS: 'New Specimen',
    editS: 'Edit Specimen',
    specimen: 'Specimen',
    additionalCode: 'Additional identifier',
    alreadyExists: 'already exists in database',
    alreadyExistShort: 'already in use',
    transferRestriction: 'Material transfer restrictions',
    abridged: {
      fullView: 'Full view'
    },
    placeholders: {
      searchHint: 'Search by specimen ID'
    },
    annotation: {
      add: 'Annotate specimen',
      edit: 'Edit annotation',
      view: 'Annotated specimen'
    },
    relationships: {
      relationships: 'Relationships'
    }
  };
});