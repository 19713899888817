define("plutof/components/ui/label", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="label-with-tooltip">
      {{#if (has-block "label")}}
          {{yield to="label"}}
      {{else}}
          <Ui::-ClickableLabel @clicked={{@clicked}}>
              {{@label}}
          </Ui::-ClickableLabel>
      {{/if}}
  
      {{#if (or (or this.tooltip (has-block "tooltip")) @infoLinks)}}
          <TooltipWrapper as |t|>
              <div class="label-with-tooltip__tooltip-present-icon">
                  <span class={{icon "help"}}></span>
              </div>
  
              <span id="{{t.tid}}" class="wrapper-tooltip wrapper-tooltip--fast wrapper-tooltip--small-anchor">
                  {{#if (has-block "tooltip")}}
                      {{yield to="tooltip"}}
                  {{else}}
                      {{!--
                          In case there is no tooltip, but infoLinks are present, use the label. Of course,
                          label can be passed via a block, but dealing with that is on the caller
                      --}}
                      {{or this.tooltip @label}}
  
                      {{! Ideally, just urls, but { url } comes everywhere, so not pushing this point atm }}
                      {{#each @infoLinks as |link|}}
                          <InfoLink @infoLink={{link.url}} />
                      {{/each}}
                  {{/if}}
              </span>
          </TooltipWrapper>
      {{/if}}
  </div>
  
  */
  {
    "id": "t3Isv3lB",
    "block": "[[[10,0],[14,0,\"label-with-tooltip\"],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"        \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,3],null,[[\"@clicked\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[28,[37,4],[[28,[37,4],[[30,0,[\"tooltip\"]],[48,[30,7]]],null],[30,3]],null],[[[1,\"        \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"label-with-tooltip__tooltip-present-icon\"],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,6],[\"help\"],null]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,1],[15,1,[29,[[30,4,[\"tid\"]]]]],[14,0,\"wrapper-tooltip wrapper-tooltip--fast wrapper-tooltip--small-anchor\"],[12],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"                    \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"                    \"],[1,[28,[35,4],[[30,0,[\"tooltip\"]],[30,2]],null]],[1,\"\\n\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,3]],null]],null],null,[[[1,\"                        \"],[8,[39,9],null,[[\"@infoLink\"],[[30,5,[\"url\"]]]],null],[1,\"\\n\"]],[5]],null]],[]]],[1,\"            \"],[13],[1,\"\\n        \"]],[4]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@clicked\",\"@label\",\"@infoLinks\",\"t\",\"link\",\"&label\",\"&tooltip\"],false,[\"if\",\"has-block\",\"yield\",\"ui/-clickable-label\",\"or\",\"tooltip-wrapper\",\"icon\",\"each\",\"-track-array\",\"info-link\"]]",
    "moduleName": "plutof/components/ui/label.hbs",
    "isStrictMode": false
  });
  class UILabel extends _component2.default {
    // Just in case, handling this here instead of callsites
    get tooltip() {
      return this.args.tooltip === this.args.label ? '' : this.args.tooltip;
    }
  }
  _exports.default = UILabel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UILabel);
});