define("plutof/templates/components/experiment/common/occurrence-experiments", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "zGCEyHCq",
    "block": "[[[8,[39,0],null,[[\"@filters\"],[[30,0,[\"filters\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@collapse\"],[[30,1,[\"pagination\",\"isEmpty\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"experiment.panels.dnas\"],null],[30,1,[\"pagination\",\"objectCount\"]]]],null],[1,\"\\n\\n        \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"view\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"experiments\",\"panel\"],false,[\"experiment/common/occurrence-experiments/view\",\"plutof-panel/item-list\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/experiment/common/occurrence-experiments.hbs",
    "isStrictMode": false
  });
});