define("plutof/components/info-link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href="{{@infoLink}}" target="_blank">
      DwC
      <span class={{icon "external-link"}}></span>
  </a>
  
  */
  {
    "id": "pzGYsyqO",
    "block": "[[[10,3],[15,6,[29,[[30,1]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n    DwC\\n    \"],[10,1],[15,0,[28,[37,0],[\"external-link\"],null]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@infoLink\"],false,[\"icon\"]]",
    "moduleName": "plutof/components/info-link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});