define("plutof/components/habitat/view/panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Habitat::View::Provider @habitat={{@habitat}} as |habitat present|>
      <Measurements::MeasurementView::Wrapper
          @mainform={{@mainform}}
          @object={{habitat}}
          as |measurements measurementsEmpty expandMeasurement|
      >
          <PlutofPanel::Simple
              @title={{i18n-t "SamplingEvent.habitat"}}
              @collapse={{and (not present) measurementsEmpty}}
              ...attributes
          >
              <Habitat::HabitatView @habitat={{habitat}} />
  
              <Measurements::MeasurementView::Columns
                  @measurements={{measurements}}
                  @columnWidth="3"
                  @expand={{expandMeasurement}} />
          </PlutofPanel::Simple>
      </Measurements::MeasurementView::Wrapper>
  </Habitat::View::Provider>
  
  */
  {
    "id": "OvMsejnd",
    "block": "[[[8,[39,0],null,[[\"@habitat\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@mainform\",\"@object\"],[[30,4],[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[17,8]],[[\"@title\",\"@collapse\"],[[28,[37,3],[\"SamplingEvent.habitat\"],null],[28,[37,4],[[28,[37,5],[[30,3]],null],[30,6]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@habitat\"],[[30,2]]],null],[1,\"\\n\\n            \"],[8,[39,7],null,[[\"@measurements\",\"@columnWidth\",\"@expand\"],[[30,5],\"3\",[30,7]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[5,6,7]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@habitat\",\"habitat\",\"present\",\"@mainform\",\"measurements\",\"measurementsEmpty\",\"expandMeasurement\",\"&attrs\"],false,[\"habitat/view/provider\",\"measurements/measurement-view/wrapper\",\"plutof-panel/simple\",\"i18n-t\",\"and\",\"not\",\"habitat/habitat-view\",\"measurements/measurement-view/columns\"]]",
    "moduleName": "plutof/components/habitat/view/panel.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});