define("plutof/components/information/content/living-specimen/add", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h4> GBIF import </h4>
  <Information::MarkdownReader @path="taxon/gbif-import" />
  <Information::Content::General::MapControls />
  
  */
  {
    "id": "lJ55qeWX",
    "block": "[[[10,\"h4\"],[12],[1,\" GBIF import \"],[13],[1,\"\\n\"],[8,[39,0],null,[[\"@path\"],[\"taxon/gbif-import\"]],null],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"]],[],false,[\"information/markdown-reader\",\"information/content/general/map-controls\"]]",
    "moduleName": "plutof/components/information/content/living-specimen/add.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});