define("plutof/components/generic-views/-record/link", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GenericViews::-Record::Attr
      @field={{this.field}}
      @context={{@context}}
      @label={{@label}}
      @translations={{@translations}}
  >
      {{#let (get @context.record this.field) as |value|}}
          {{#if value.id}}
              <LinkTo @route={{@route}} @model={{value.id}}>
                  {{value.representation}}
              </LinkTo>
          {{/if}}
      {{/let}}
  </GenericViews::-Record::Attr>
  
  */
  {
    "id": "AHqzjd3w",
    "block": "[[[8,[39,0],null,[[\"@field\",\"@context\",\"@label\",\"@translations\"],[[30,0,[\"field\"]],[30,1],[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,2],[[30,1,[\"record\"]],[30,0,[\"field\"]]],null]],[[[41,[30,4,[\"id\"]],[[[1,\"            \"],[8,[39,4],null,[[\"@route\",\"@model\"],[[30,5],[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,4,[\"representation\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null]],[4]]]],[]]]]],[1,\"\\n\"]],[\"@context\",\"@label\",\"@translations\",\"value\",\"@route\"],false,[\"generic-views/-record/attr\",\"let\",\"get\",\"if\",\"link-to\"]]",
    "moduleName": "plutof/components/generic-views/-record/link.hbs",
    "isStrictMode": false
  });
  // TODO: Ideally, we'd want to derive route from value, but atm we key modules by
  // content type
  class GenericRecordViewLink extends _component.default {}
  _exports.default = GenericRecordViewLink;
  _defineProperty(GenericRecordViewLink, "positionalParams", ['field']);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GenericRecordViewLink);
});