define("plutof/templates/components/measurements/measurement-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2BfZcF/r",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@tooltip\",\"@infoLinks\"],[[30,1,[\"fullName\"]],[30,1,[\"fullDescription\"]],[30,1,[\"measurementData\",\"info_links\"]]]],[[\"label\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n            \"],[1,[30,1,[\"fullName\"]]],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"                \"],[11,\"button\"],[24,0,\"btn-fake-link btn-fake-link--no-underline\"],[4,[38,2],[\"click\",[30,2]],null],[12],[1,\"\\n                    ...\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,1,[\"isSet\"]],[[[41,[30,1,[\"measurementData\",\"allow_multiple\"]],[[[1,\"        \"],[8,[39,3],[[16,\"data-test-measurement\",[30,1,[\"measurementData\",\"name\"]]]],[[\"@content\",\"@selections\",\"@optionLabelPath\",\"@prompt\"],[[30,1,[\"measurementData\",\"set_choices\"]],[30,1,[\"value\"]],[30,0,[\"elementLabel\"]],\"---\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,4],[[24,0,\"measurement-form__measurement measurement-form__measurement--set\"],[16,\"data-test-measurement\",[30,1,[\"measurementData\",\"name\"]]]],[[\"@content\",\"@selection\",\"@optionLabelPath\",\"@optionValuePath\"],[[30,1,[\"measurementData\",\"set_choices\"]],[30,1,[\"value\"]],[30,0,[\"elementLabel\"]],\"id\"]],null],[1,\"\\n\"]],[]]]],[]],[[[41,[30,1,[\"isLogical\"]],[[[1,\"    \"],[8,[39,5],[[16,\"data-test-measurement\",[30,1,[\"measurementData\",\"name\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"value\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],[[24,0,\"form-control measurement-form__measurement measurement-form__measurement-text\"],[16,\"data-test-measurement\",[30,1,[\"measurementData\",\"name\"]]]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"value\"]]]],null],[1,\"\\n\"]],[]]]],[]]]],[\"@field\",\"@expand\"],false,[\"ui/label\",\"if\",\"on\",\"multiple-selection\",\"plutof-select/object\",\"input\"]]",
    "moduleName": "plutof/templates/components/measurements/measurement-input.hbs",
    "isStrictMode": false
  });
});