define("plutof/components/taxonomy/taxon/auto-complete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/components/auto-complete/backends", "plutof/misc/config"], function (_exports, _component, _templateFactory, _component2, _object, _service, _backends, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.backend as |backend|}}
      {{#if backend}}
          <AutoComplete::Base
              @backend={{backend}}
              @value={{@value}}
              @update={{@update}}
              @disabled={{@disabled}}
              @params={{@params}}
              ...attributes
          >
              <:result as |result context|>
                  {{#if result.full.valid_name}}
                      <span class="plutof-ac-suggestion-description">
                          {{i18n-t "Taxonomy.syn"}}
                      </span>
                  {{/if}}
  
                  {{!shrinkText name}}
                  {{hl-subs result.value context.query}}
  
                  <span class="taxon-ac-suggestion-description">
                      <AutoComplete::ScientificName @taxon={{result.full}} />
                  </span>
  
                  <div class="plutof-tooltip">
                      <div class="taxon-ac-tooltip-body">
                          {{#if result.full.valid_name}}
                              <span class="taxon-ac-scientific-name">
                                  {{i18n-t "Taxonomy.syn"}}
                              </span>
                          {{/if}}
  
                          {{result.full.name}}
                      </div>
  
                      <div>
                          <AutoComplete::ScientificName @taxon={{result.full}} />
                      </div>
                  </div>
              </:result>
          </AutoComplete::Base>
      {{/if}}
  {{/resolve-promise}}
  
  */
  {
    "id": "oyWk34W4",
    "block": "[[[6,[39,0],[[30,0,[\"backend\"]]],null,[[\"default\"],[[[[41,[30,1],[[[1,\"        \"],[8,[39,2],[[17,2]],[[\"@backend\",\"@value\",\"@update\",\"@disabled\",\"@params\"],[[30,1],[30,3],[30,4],[30,5],[30,6]]],[[\"result\"],[[[[1,\"\\n\"],[41,[30,7,[\"full\",\"valid_name\"]],[[[1,\"                    \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n                        \"],[1,[28,[35,3],[\"Taxonomy.syn\"],null]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,\"                \"],[1,[28,[35,4],[[30,7,[\"value\"]],[30,8,[\"query\"]]],null]],[1,\"\\n\\n                \"],[10,1],[14,0,\"taxon-ac-suggestion-description\"],[12],[1,\"\\n                    \"],[8,[39,5],null,[[\"@taxon\"],[[30,7,[\"full\"]]]],null],[1,\"\\n                \"],[13],[1,\"\\n\\n                \"],[10,0],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n                    \"],[10,0],[14,0,\"taxon-ac-tooltip-body\"],[12],[1,\"\\n\"],[41,[30,7,[\"full\",\"valid_name\"]],[[[1,\"                            \"],[10,1],[14,0,\"taxon-ac-scientific-name\"],[12],[1,\"\\n                                \"],[1,[28,[35,3],[\"Taxonomy.syn\"],null]],[1,\"\\n                            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n                        \"],[1,[30,7,[\"full\",\"name\"]]],[1,\"\\n                    \"],[13],[1,\"\\n\\n                    \"],[10,0],[12],[1,\"\\n                        \"],[8,[39,5],null,[[\"@taxon\"],[[30,7,[\"full\"]]]],null],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"]],[7,8]]]]],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"backend\",\"&attrs\",\"@value\",\"@update\",\"@disabled\",\"@params\",\"result\",\"context\"],false,[\"resolve-promise\",\"if\",\"auto-complete/base\",\"i18n-t\",\"hl-subs\",\"auto-complete/scientific-name\"]]",
    "moduleName": "plutof/components/taxonomy/taxon/auto-complete.hbs",
    "isStrictMode": false
  });
  let TaxonAutocomplete = _exports.default = (_dec = (0, _object.computed)('args.filters', 'args.includeCommonNames', 'args.form'), _class = class TaxonAutocomplete extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get backend() {
      const filters = this.args.filters || {
        is_deleted: false,
        tree: _config.default.Taxonomy.DEFAULT_TAXONOMY_ID
      };
      if (this.args.includeCommonNames) {
        filters.include_commonnames = true;
      }
      if (this.args.form) {
        filters.form = this.args.form;
      }
      return new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'taxonomy/taxonnode',
        filters
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backend", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "backend"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaxonAutocomplete);
});