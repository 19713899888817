define("plutof/components/information/content/general/area-event", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h4>{{i18n-t "Sample.area.areasSubtitle"}}</h4>
  
  <Information::MarkdownReader @path="area/types" />
  <Information::Content::General::MapControls @locale={{@locale}} />
  
  */
  {
    "id": "YU7TfE13",
    "block": "[[[10,\"h4\"],[12],[1,[28,[35,0],[\"Sample.area.areasSubtitle\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@path\"],[\"area/types\"]],null],[1,\"\\n\"],[8,[39,2],null,[[\"@locale\"],[[30,1]]],null],[1,\"\\n\"]],[\"@locale\"],false,[\"i18n-t\",\"information/markdown-reader\",\"information/content/general/map-controls\"]]",
    "moduleName": "plutof/components/information/content/general/area-event.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});