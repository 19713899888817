define("plutof/components/preparation/-view-list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @preparations as |preparation|}}
      {{yield
          (component "preparation/-view-item"
              preparation=preparation.preparation
              agents=preparation.agents
              modelSet=@modelSet)
  
          preparation.preparation
          preparation.agents}}
  {{/each}}
  
  */
  {
    "id": "bgOrfCFk",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[18,4,[[50,\"preparation/-view-item\",0,null,[[\"preparation\",\"agents\",\"modelSet\"],[[30,2,[\"preparation\"]],[30,2,[\"agents\"]],[30,3]]]],[30,2,[\"preparation\"]],[30,2,[\"agents\"]]]],[1,\"\\n\"]],[2]],null]],[\"@preparations\",\"preparation\",\"@modelSet\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"component\"]]",
    "moduleName": "plutof/components/preparation/-view-list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});