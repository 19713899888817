define("plutof/translations/est/specimen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Eksemplarid',
    searchTitle: 'Otsi eksemeplare',
    infoTitle: 'Eksemplarid',
    newS: 'Uus eksemplar',
    editS: 'Muuda eksemplari',
    specimen: 'Eksemplar',
    alreadyExists: 'on juba lisatud andmebaasi',
    alreadyExistShort: 'juba kasutusel',
    additionalCode: 'Teisene kood',
    transferRestriction: 'Materjali ümberpaigutamine on piiratud',
    abridged: {
      fullView: 'Täisvaade'
    },
    placeholders: {
      searchHint: 'Otsi eksemplari koodi järgi'
    },
    annotation: {
      add: 'Annoteeri eksemplari',
      edit: 'Muuda annotatsiooni',
      view: 'Annoteeritud eksemplar'
    },
    relationships: {
      relationships: 'Seosed'
    }
  };
});