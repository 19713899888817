define("plutof/components/history/view", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <History::View::Provider
      @record={{@record}}
      @disabled={{this.unloaded}}
      as |history|
  >
      <PlutofPanel::Base
          @open={{this.open}}
          @toggle={{this.toggle}}
      >
          <:title>
              {{i18n-t "history.table.header"}}
  
              {{#if history.loading}}
                  <span class="loading-icon"></span>
              {{/if}}
          </:title>
  
          <:body>
              {{history.content}}
          </:body>
      </PlutofPanel::Base>
  </History::View::Provider>
  
  */
  {
    "id": "gwt2Lpes",
    "block": "[[[8,[39,0],null,[[\"@record\",\"@disabled\"],[[30,1],[30,0,[\"unloaded\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@open\",\"@toggle\"],[[30,0,[\"open\"]],[30,0,[\"toggle\"]]]],[[\"title\",\"body\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"history.table.header\"],null]],[1,\"\\n\\n\"],[41,[30,2,[\"loading\"]],[[[1,\"                \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]],[[[1,\"\\n            \"],[1,[30,2,[\"content\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@record\",\"history\"],false,[\"history/view/provider\",\"plutof-panel/base\",\"i18n-t\",\"if\"]]",
    "moduleName": "plutof/components/history/view.hbs",
    "isStrictMode": false
  });
  let HistoryView = (_class = class HistoryView extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "open", _descriptor2, this);
      _initializerDefineProperty(this, "unloaded", _descriptor3, this);
    }
    toggle() {
      this.open = !this.open;
      if (this.open && this.unloaded) {
        this.unloaded = false;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "unloaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HistoryView);
});