define("plutof/components/sample/area-event-view-panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "plutof/components/sample/sampling-area-event", "plutof/utils/area-event"], function (_exports, _component, _templateFactory, _object, _service, _samplingAreaEvent, _areaEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.areaEvent as |areaEvent|}}
      <PlutofPanel::ItemList as |panel|>
          <panel.header
              @title="{{this.translations.samplingAreaEvent}}"
              @infoContent="information/content/general/map-controls"
          >
              {{#if @canModify}}
                  <panel.button @clicked={{fn this.editArea areaEvent.area}}>
                      <span class={{icon "edit"}}></span>
  
                      {{i18n-t "Observations.samplingArea"}}
                  </panel.button>
  
                  <panel.button @clicked={{fn this.editEvent areaEvent.event}}>
                      <span class={{icon "edit"}}></span>
  
                      {{i18n-t "Observations.samplingEvent"}}
                  </panel.button>
              {{/if}}
          </panel.header>
  
          <panel.content>
              <Sample::AreaEventView
                  @externalMainform={{@parent.mainform}}
                  @customTranslations={{this.translations}}
                  @event={{areaEvent.event}}
                  @area={{areaEvent.area}}
                  @showAreaType={{@showAreaType}}
                  @showParent={{true}}
                  @occurrenceModelName={{@occurrenceModelName}}
                  @derived={{@derived}}
                  @layers={{@layers}} />
          </panel.content>
      </PlutofPanel::ItemList>
  {{/resolve-promise}}
  
  */
  {
    "id": "iVLox2cE",
    "block": "[[[6,[39,0],[[30,0,[\"areaEvent\"]]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@infoContent\"],[[29,[[30,0,[\"translations\",\"samplingAreaEvent\"]]]],\"information/content/general/map-controls\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3],[[[1,\"                \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\"],[[28,[37,3],[[30,0,[\"editArea\"]],[30,1,[\"area\"]]],null]]],[[\"default\"],[[[[1,\"\\n                    \"],[10,1],[15,0,[28,[37,4],[\"edit\"],null]],[12],[13],[1,\"\\n\\n                    \"],[1,[28,[35,5],[\"Observations.samplingArea\"],null]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\\n                \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\"],[[28,[37,3],[[30,0,[\"editEvent\"]],[30,1,[\"event\"]]],null]]],[[\"default\"],[[[[1,\"\\n                    \"],[10,1],[15,0,[28,[37,4],[\"edit\"],null]],[12],[13],[1,\"\\n\\n                    \"],[1,[28,[35,5],[\"Observations.samplingEvent\"],null]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@externalMainform\",\"@customTranslations\",\"@event\",\"@area\",\"@showAreaType\",\"@showParent\",\"@occurrenceModelName\",\"@derived\",\"@layers\"],[[30,4,[\"mainform\"]],[30,0,[\"translations\"]],[30,1,[\"event\"]],[30,1,[\"area\"]],[30,5],true,[30,6],[30,7],[30,8]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[1]]]]]],[\"areaEvent\",\"panel\",\"@canModify\",\"@parent\",\"@showAreaType\",\"@occurrenceModelName\",\"@derived\",\"@layers\"],false,[\"resolve-promise\",\"plutof-panel/item-list\",\"if\",\"fn\",\"icon\",\"i18n-t\",\"sample/area-event-view\"]]",
    "moduleName": "plutof/components/sample/area-event-view-panel.hbs",
    "isStrictMode": false
  });
  let AreaEventViewPanel = (_dec = (0, _object.computed)('parent'), _dec2 = (0, _object.computed)('customTranslations'), _class = class AreaEventViewPanel extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get areaEvent() {
      return (0, _areaEvent.occurrenceAreaEvent)(this.store, this.parent);
    }
    get translations() {
      return (0, _samplingAreaEvent.getSamplingTranslations)(this.i18n, this.customTranslations);
    }
    editArea(area) {
      this.router.transitionTo('area.edit', area.id);
    }
    editEvent(event) {
      this.router.transitionTo('event.edit', event.id);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "areaEvent", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "areaEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "translations", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "translations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editArea", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editArea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editEvent"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AreaEventViewPanel);
});