define("plutof/components/information/content/organization/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Information::MarkdownReader @path="general/list-view" />
  <Information::MarkdownReader @path="organization" />
  
  */
  {
    "id": "56ZdxSAe",
    "block": "[[[8,[39,0],null,[[\"@path\"],[\"general/list-view\"]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@path\"],[\"organization\"]],null],[1,\"\\n\"]],[],false,[\"information/markdown-reader\"]]",
    "moduleName": "plutof/components/information/content/organization/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});