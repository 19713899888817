define("plutof/components/interaction/-view-interactions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @parentInteractions as |interaction|}}
      <PlutofPanel::Inner
          @highlight={{true}}
          @compact={{true}}
          data-test="interaction"
      >
          <Interaction::ViewSingle
              @interaction={{interaction}}
              @object={{interaction.interacting_object}}
              @contentType={{interaction.interacting_content_type}} />
      </PlutofPanel::Inner>
  {{/each}}
  
  {{#each @parentInteractings as |interaction|}}
      <PlutofPanel::Inner
          @highlight={{true}}
          @compact={{true}}
          data-test="interaction"
      >
          <Interaction::ViewSingle
              @interaction={{interaction}}
              @object={{interaction.interaction_object}}
              @contentType={{interaction.content_type}}
              @hideTaxonType={{true}} />
      </PlutofPanel::Inner>
  {{/each}}
  
  {{#each @interactions as |interaction|}}
      <PlutofPanel::Inner
          class={{annotation/added-removed-class interaction container=true}}
          @compact={{true}}
          data-test="interaction"
      >
          <Interaction::ViewSingle
              @interaction={{interaction}}
              @object={{interaction.interacting_object}}
              @contentType={{interaction.interacting_content_type}} />
      </PlutofPanel::Inner>
  {{/each}}
  
  {{#each @interactings as |interaction|}}
      <PlutofPanel::Inner
          class={{annotation/added-removed-class interaction container=true}}
          @compact={{true}}
          data-test="interaction"
      >
          <Interaction::ViewSingle
              @interaction={{interaction}}
              @object={{interaction.interaction_object}}
              @contentType={{interaction.content_type}}
              @hideTaxonType={{true}} />
      </PlutofPanel::Inner>
  {{/each}}
  
  */
  {
    "id": "q2PDQFkT",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[24,\"data-test\",\"interaction\"]],[[\"@highlight\",\"@compact\"],[true,true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@interaction\",\"@object\",\"@contentType\"],[[30,2],[30,2,[\"interacting_object\"]],[30,2,[\"interacting_content_type\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[24,\"data-test\",\"interaction\"]],[[\"@highlight\",\"@compact\"],[true,true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@interaction\",\"@object\",\"@contentType\",\"@hideTaxonType\"],[[30,4],[30,4,[\"interaction_object\"]],[30,4,[\"content_type\"]],true]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[4]],null],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,5]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[16,0,[28,[37,4],[[30,6]],[[\"container\"],[true]]]],[24,\"data-test\",\"interaction\"]],[[\"@compact\"],[true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@interaction\",\"@object\",\"@contentType\"],[[30,6],[30,6,[\"interacting_object\"]],[30,6,[\"interacting_content_type\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[6]],null],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,7]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[16,0,[28,[37,4],[[30,8]],[[\"container\"],[true]]]],[24,\"data-test\",\"interaction\"]],[[\"@compact\"],[true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@interaction\",\"@object\",\"@contentType\",\"@hideTaxonType\"],[[30,8],[30,8,[\"interaction_object\"]],[30,8,[\"content_type\"]],true]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[8]],null]],[\"@parentInteractions\",\"interaction\",\"@parentInteractings\",\"interaction\",\"@interactions\",\"interaction\",\"@interactings\",\"interaction\"],false,[\"each\",\"-track-array\",\"plutof-panel/inner\",\"interaction/view-single\",\"annotation/added-removed-class\"]]",
    "moduleName": "plutof/components/interaction/-view-interactions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});