define("plutof/components/linked-items/view/-links-tab", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@group.simpleTab
      @name="links"
      @header={{i18n-t "General.externalLink"}}
      @count={{@items.objectCount}}
  >
      <Common::RecordListView @records={{@items.objects}} as |link|>
          <a
              href="{{link.link}}"
              target="_blank"
              class="plutof-link wrapped-text {{annotation/added-removed-class link}}"
          >
              {{link.representation}}
  
              {{#if link.description}}
                  - {{link.description}}
              {{/if}}
          </a>
      </Common::RecordListView>
  
      <Pagination::LoadMore @pagination={{@items}} />
  </@group.simpleTab>
  
  */
  {
    "id": "VkvJLnkm",
    "block": "[[[8,[30,1,[\"simpleTab\"]],null,[[\"@name\",\"@header\",\"@count\"],[\"links\",[28,[37,0],[\"General.externalLink\"],null],[30,2,[\"objectCount\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@records\"],[[30,2,[\"objects\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,3],[15,6,[29,[[30,3,[\"link\"]]]]],[14,\"target\",\"_blank\"],[15,0,[29,[\"plutof-link wrapped-text \",[28,[37,2],[[30,3]],null]]]],[12],[1,\"\\n            \"],[1,[30,3,[\"representation\"]]],[1,\"\\n\\n\"],[41,[30,3,[\"description\"]],[[[1,\"                - \"],[1,[30,3,[\"description\"]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@pagination\"],[[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@group\",\"@items\",\"link\"],false,[\"i18n-t\",\"common/record-list-view\",\"annotation/added-removed-class\",\"if\",\"pagination/load-more\"]]",
    "moduleName": "plutof/components/linked-items/view/-links-tab.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});