define("plutof/templates/components/datacite/funder-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "yrdV5BJd",
    "block": "[[[10,\"td\"],[15,0,[29,[\"data-table-short-cell \",[52,[30,0,[\"validations\",\"attrs\",\"funder\",\"identifier_type\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"content\",\"value\",\"selectClass\",\"class\"],[[30,0,[\"identifierTypes\"]],[30,1,[\"identifier_type\"]],\"mini-input\",\"datacite-edit-funders__type\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[52,[30,0,[\"validations\",\"attrs\",\"funder\",\"funder_name\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"funder_name\"]],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"funder_identifier\"]],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"award_number\"]],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"award_title\"]],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"award_uri\"]],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@funder\",\"@remove\"],false,[\"if\",\"plutof-select/value\",\"input\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/funder-row-form.hbs",
    "isStrictMode": false
  });
});