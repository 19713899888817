define("plutof/components/information/content/filerepository/general", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Information::MarkdownReader @path="filerepository/general" />
  
  <h4> {{i18n-t 'Profile.CreativeCommons'}}</h4>
  
  <Information::Content::General::DefaultLicence />
  
  */
  {
    "id": "YR/BrCiw",
    "block": "[[[8,[39,0],null,[[\"@path\"],[\"filerepository/general\"]],null],[1,\"\\n\\n\"],[10,\"h4\"],[12],[1,\" \"],[1,[28,[35,1],[\"Profile.CreativeCommons\"],null]],[13],[1,\"\\n\\n\"],[8,[39,2],null,null,null],[1,\"\\n\"]],[],false,[\"information/markdown-reader\",\"i18n-t\",\"information/content/general/default-licence\"]]",
    "moduleName": "plutof/components/information/content/filerepository/general.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});