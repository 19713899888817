define("plutof/utils/menu", ["exports", "plutof/config/environment", "plutof/helpers/icon", "plutof/models/users/usergroup"], function (_exports, _environment, _icon, _usergroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.menuConfig = _exports.ROUTES_WITHOUT_MENU = void 0;
  const menuConfig = _exports.menuConfig = {
    children: [{
      title: 'General.DMP',
      iconClass: (0, _icon.icon)('dmp'),
      route: 'dmp',
      addRoute: 'dmp.add'
    }, {
      title: 'General.Projects',
      iconClass: (0, _icon.icon)('project'),
      route: 'study',
      addRoute: 'study.add',
      searchModule: 'study'
    }, {
      title: 'General.taxonOccurrences',
      iconClass: 'fas fa-th-large',
      children: [{
        route: 'specimen',
        addRoute: 'specimen.add',
        title: 'General.Specimens',
        iconClass: (0, _icon.icon)('specimen'),
        searchModule: 'specimen'
      }, {
        route: 'observation',
        addRoute: 'observation.add',
        title: 'General.Observations',
        iconClass: (0, _icon.icon)('observation'),
        searchModule: 'observation'
      }, {
        route: 'sequence',
        addRoute: 'sequence.add',
        title: 'General.Sequences',
        iconClass: (0, _icon.icon)('sequence'),
        searchModule: 'sequence'
      }, {
        route: 'livingspecimen',
        addRoute: 'livingspecimen.add',
        title: 'General.LivingC',
        iconClass: (0, _icon.icon)('livingspecimen'),
        searchModule: 'livingculture'
      }, {
        route: 'referencebased',
        addRoute: 'referencebased.add',
        title: 'General.RefBased',
        iconClass: (0, _icon.icon)('refbased'),
        searchModule: 'referencebased'
      }, {
        route: 'materialsample',
        addRoute: 'materialsample.add',
        title: 'General.materialSamples',
        iconClass: (0, _icon.icon)('materialsample'),
        searchModule: 'materialsample'
      }]
    }, {
      route: 'trait-lab',
      title: 'Forms.traitsLabel',
      iconClass: (0, _icon.icon)('trait'),
      addRoute: 'trait.add',
      searchModule: 'trait',
      remoteRoutes: [{
        route: 'trait'
      }, {
        route: 'forms'
      }],
      async disableAdd(container) {
        if (!_environment.default.TRAIT_GROUP_ID) {
          return true;
        }
        const groupMembership = container.lookup('service:groupMembership');
        const isMember = await groupMembership.currentUserBelongs(_environment.default.TRAIT_GROUP_ID, {
          minStatus: _usergroup.UserStatus.ReadWrite
        });
        return !isMember;
      }
    }, {
      title: 'General.laboratories',
      iconClass: (0, _icon.icon)('laboratory'),
      children: [{
        route: 'collection-lab',
        title: 'menu.labs.collection',
        iconClass: (0, _icon.icon)('collection'),
        remoteRoutes: [{
          route: 'collection',
          searchModule: 'collection'
        }, {
          route: 'transaction',
          addRoute: 'transaction.add',
          searchModule: 'transaction'
        }, {
          route: 'condition-assessment',
          addRoute: 'condition-assessment.add'
        }, {
          route: 'treatment',
          addRoute: 'treatment.add'
        }, {
          route: 'photobank',
          addRoute: 'photobank.add',
          searchModule: 'photobank'
        }, {
          route: 'permit',
          addRoute: 'permit.add'
        }]
      }, {
        route: 'publishing-lab',
        title: 'menu.labs.publishing',
        iconClass: (0, _icon.icon)('publishing-lab'),
        remoteRoutes: [{
          route: 'datacite',
          addRoute: 'datacite.add',
          searchModule: 'publishing'
        }, {
          route: 'reference',
          addRoute: 'reference.add',
          searchModule: 'reference'
        }, {
          route: 'series',
          addRoute: 'series.add',
          searchModule: 'referenceseries'
        }, {
          route: 'glossary',
          addRoute: 'glossary.add',
          searchModule: 'glossary'
        }, {
          route: 'glossary.collection',
          searchModule: 'glossarycollection'
        }, {
          route: 'publishing'
        }]
      }, {
        route: 'taxonomy-lab',
        title: 'menu.labs.taxonomy',
        iconClass: (0, _icon.icon)('taxonomy'),
        searchModule: 'taxonnode',
        remoteRoutes: [{
          route: 'trees',
          addRoute: 'trees.add',
          searchModule: 'taxonnode'
        }, {
          route: 'taxon-description',
          addRoute: 'taxon-description.add',
          searchModule: 'taxonnode'
        }, {
          route: 'unite',
          searchModule: 'referencesequence'
        }, {
          route: 'taxonomy',
          addRoute: 'taxonomy.add',
          searchModule: 'taxonnode'
        }, {
          route: 'compound',
          searchModule: 'compoundclusters'
        }, {
          route: 'statistics'
        }]
      }, {
        route: 'analyses-lab',
        title: 'menu.labs.analysis',
        iconClass: (0, _icon.icon)('analysis'),
        remoteRoutes: [{
          route: 'analysis'
        }]
      }, {
        route: 'molecular-lab',
        title: 'menu.labs.molecular',
        iconClass: (0, _icon.icon)('molecular-lab'),
        subRoutes: [{
          route: 'experiment.dna-extraction.index',
          searchModule: 'dnaextraction'
        }, {
          route: 'experiment.dna-extraction.add',
          searchModule: 'dnaextraction'
        }, {
          route: 'experiment.dna-extraction.edit',
          searchModule: 'dnaextraction'
        }, {
          route: 'experiment.dna-extraction.view',
          searchModule: 'dnaextraction'
        }, {
          route: 'experiment.pcr.index',
          searchModule: 'pcr'
        }, {
          route: 'experiment.pcr.add',
          searchModule: 'pcr'
        }, {
          route: 'experiment.pcr.edit',
          searchModule: 'pcr'
        }, {
          route: 'experiment.pcr.view',
          searchModule: 'pcr'
        }, {
          route: 'experiment.sequencing.index',
          searchModule: 'sequencing'
        }, {
          route: 'experiment.sequencing.add',
          searchModule: 'sequencing'
        }, {
          route: 'experiment.sequencing.edit',
          searchModule: 'sequencing'
        }, {
          route: 'experiment.sequencing.view',
          searchModule: 'sequencing'
        }, {
          route: 'experiment.dna.index',
          searchModule: 'dna'
        }, {
          route: 'experiment.dna.add',
          searchModule: 'dna'
        }, {
          route: 'experiment.dna.edit',
          searchModule: 'dna'
        }, {
          route: 'experiment.dna.view',
          searchModule: 'dna'
        }],
        remoteRoutes: [{
          route: 'experiment'
        }]
      }, {
        route: 'gis-lab',
        addRoute: 'area.add',
        title: 'menu.labs.gis',
        iconClass: (0, _icon.icon)('gis-lab'),
        remoteRoutes: [{
          route: 'area'
        }]
      }, {
        route: 'conservation-lab',
        title: 'conservation.laboratory.title',
        iconClass: (0, _icon.icon)('conservation'),
        remoteRoutes: [{
          route: 'conservation-lab.red-list'
        }, {
          route: 'conservation-lab.red-list-assessment',
          searchModule: 'red-list-assessments'
        }]
      }]
    }, {
      route: 'filerepository',
      title: 'General.FileRepo',
      addRoute: 'filerepository.upload',
      iconClass: (0, _icon.icon)('file'),
      searchModule: 'filerepository'
    }, {
      route: 'person',
      title: 'Agents.personsTitle',
      addRoute: 'person.add',
      iconClass: (0, _icon.icon)('person'),
      searchModule: 'person'
    }, {
      route: 'organization',
      title: 'Agents.orgTitle',
      addRoute: 'organization.add',
      iconClass: (0, _icon.icon)('organization'),
      searchModule: 'organization'
    }, {
      route: 'clipboard',
      title: 'General.ClipboardModule',
      iconClass: (0, _icon.icon)('clipboard'),
      // Sub-routes are full length route paths.
      subRoutes: [{
        route: 'clipboard.specimen',
        searchModule: 'specimen'
      }, {
        route: 'clipboard.observation',
        searchModule: 'observation'
      }, {
        route: 'clipboard.sequence',
        searchModule: 'sequence'
      }, {
        route: 'clipboard.photobank',
        searchModule: 'photobank'
      }, {
        route: 'clipboard.livingspecimen',
        searchModule: 'livingculture'
      }, {
        route: 'clipboard.reference-based',
        searchModule: 'referencebased'
      }]
    }, {
      route: 'import',
      title: 'Import.import',
      addRoute: 'import.add',
      iconClass: (0, _icon.icon)('import')
    }, {
      route: 'search',
      title: 'General.Search',
      iconClass: (0, _icon.icon)('search')
    }, {
      route: 'annotations',
      title: 'annotation.annotations',
      iconClass: (0, _icon.icon)('annotation')
    }]
  };
  const ROUTES_WITHOUT_MENU = _exports.ROUTES_WITHOUT_MENU = ['activate', 'step-two-register'];
});