define("plutof/templates/components/search/gbif-filter-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "v1yWNFT+",
    "block": "[[[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"filter-input\"],[15,\"data-input\",[30,1,[\"config\",\"label\"]]],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@label\",\"@tooltip\"],[[30,1,[\"config\",\"label\"]],[30,1,[\"config\",\"help_text\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@value\"],[[30,1,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,0,\"filter-input-ac\"]],[[\"@update\",\"@params\",\"@datasetType\"],[[30,2],[28,[37,4],null,[[\"enableVoidQuery\"],[true]]],[30,0,[\"recordType\"]]]],null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"@filter\",\"add\"],false,[\"if\",\"ui/label\",\"auto-complete/bind-multiple\",\"publishing/gbif/auto-complete\",\"hash\"]]",
    "moduleName": "plutof/templates/components/search/gbif-filter-input.hbs",
    "isStrictMode": false
  });
});