define("plutof/components/layer/map", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-concurrency", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadMapModel = loadMapModel;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@tabGroup.tab @name="map">
      <@tabGroup.header @name="map">
          {{i18n-t "Layer.tabs.map"}}
      </@tabGroup.header>
  
      <Map::ViewMap
          @geodata={{@mapModel.geometries}}
          @selectionChanged={{@mapModel.selectGeometry}}
          @popupEnabled={{true}}
          @externalLoading={{false}}
          @expandVertically={{true}}
          @collapseSmallPolygons={{false}}
      >
          <Layer::-Map::AreaPopup
              @selectedGeometry={{@mapModel.selectedGeometry}}
              @updateFilter={{@updateFilter}} />
      </Map::ViewMap>
  </@tabGroup.tab>
  
  */
  {
    "id": "NQSzYKkZ",
    "block": "[[[8,[30,1,[\"tab\"]],null,[[\"@name\"],[\"map\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@name\"],[\"map\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,0],[\"Layer.tabs.map\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@geodata\",\"@selectionChanged\",\"@popupEnabled\",\"@externalLoading\",\"@expandVertically\",\"@collapseSmallPolygons\"],[[30,2,[\"geometries\"]],[30,2,[\"selectGeometry\"]],true,false,true,false]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@selectedGeometry\",\"@updateFilter\"],[[30,2,[\"selectedGeometry\"]],[30,3]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@tabGroup\",\"@mapModel\",\"@updateFilter\"],false,[\"i18n-t\",\"map/view-map\",\"layer/-map/area-popup\"]]",
    "moduleName": "plutof/components/layer/map.hbs",
    "isStrictMode": false
  });
  async function loadGeoData(ajax, layer) {
    const url = `geography/layers/${layer.id}/wkt_geometry/`;
    const data = await ajax.request(url);
    return data.map(area => ({
      id: area.id,
      geom: area.wkt,
      styleClass: 'base'
    }));
  }
  let LayerMapModel = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class LayerMapModel {
    constructor(_ref2) {
      let {
        ajax,
        layer
      } = _ref2;
      _initializerDefineProperty(this, "selectedGeometry", _descriptor, this);
      _initializerDefineProperty(this, "geometries", _descriptor2, this);
      _initializerDefineProperty(this, "_loadGeoData", _descriptor3, this);
      this.layer = layer;
      this.ajax = ajax;
      this._loadGeoData.perform();
    }
    selectGeometry(selection) {
      this.selectedGeometry = null;
      this.selectedGeometry = selection[0];
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedGeometry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "geometries", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_loadGeoData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.geometries = yield loadGeoData(this.ajax, this.layer);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectGeometry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectGeometry"), _class.prototype), _class);
  function loadMapModel(ajax, layer) {
    return new LayerMapModel({
      ajax,
      layer
    });
  }
  class LayerTabsMap extends _component2.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LayerTabsMap);
});