define("plutof/components/tab-group/simple-tab", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TabGroup::Tab @name={{@name}} @group={{@group}} as |tab|>
      <TabGroup::Header @name={{@name}} @group={{@group}}>
          {{@header}}
  
          {{#if (not (is-empty @count))}}
              <span class="count-badge {{unless tab.selected 'count-badge--inactive'}}">
                  {{@count}}
              </span>
          {{/if}}
      </TabGroup::Header>
  
      {{yield}}
  </TabGroup::Tab>
  
  */
  {
    "id": "EG6elkT6",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@group\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@name\",\"@group\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4]],[1,\"\\n\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,5]],null]],null],[[[1,\"            \"],[10,1],[15,0,[29,[\"count-badge \",[52,[51,[30,3,[\"selected\"]]],\"count-badge--inactive\"]]]],[12],[1,\"\\n                \"],[1,[30,5]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\\n    \"],[18,6,null],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@name\",\"@group\",\"tab\",\"@header\",\"@count\",\"&default\"],false,[\"tab-group/tab\",\"tab-group/header\",\"if\",\"not\",\"is-empty\",\"unless\",\"yield\"]]",
    "moduleName": "plutof/components/tab-group/simple-tab.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});