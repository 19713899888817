define("plutof/components/quick-browse/record-popup/taxon-node", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
      <span class="plutof-label">
          {{i18n-t "QuickBrowse.popup.taxonRank"}}
      </span>
  
      {{@record.data.taxon_rank}}
  </div>
  
  <div>
      <span class="plutof-label">
          {{i18n-t "QuickBrowse.popup.taxonVernacularNames"}}
      </span>
  
      <table>
          {{#each @record.data.vernacular_names as |name|}}
              <tr>
                  <td class="quick-browse-common-name">{{name.name}}</td>
                  <td>({{name.iso_639}})</td>
              </tr>
          {{/each}}
      </table>
  </div>
  
  */
  {
    "id": "/fE8twwg",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[10,1],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"QuickBrowse.popup.taxonRank\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[30,1,[\"data\",\"taxon_rank\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[12],[1,\"\\n    \"],[10,1],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"QuickBrowse.popup.taxonVernacularNames\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"table\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"data\",\"vernacular_names\"]]],null]],null],null,[[[1,\"            \"],[10,\"tr\"],[12],[1,\"\\n                \"],[10,\"td\"],[14,0,\"quick-browse-common-name\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n                \"],[10,\"td\"],[12],[1,\"(\"],[1,[30,2,[\"iso_639\"]]],[1,\")\"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@record\",\"name\"],false,[\"i18n-t\",\"each\",\"-track-array\"]]",
    "moduleName": "plutof/components/quick-browse/record-popup/taxon-node.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});