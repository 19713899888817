define("plutof/templates/components/analysis/matching-source-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "FpQ3hCQ7",
    "block": "[[[10,\"td\"],[14,0,\"analysis-matching-source__accno-cell\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[52,[28,[37,1],[[30,0,[\"accnoValid\"]]],null],\"has-error\"],\" control-group\"]]],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@model\",\"@value\",\"@filters\",\"@params\"],[\"taxonoccurrence/sequence/sequence\",[30,1,[\"sequence\"]],[28,[37,3],null,[[\"access\"],[\"edit\"]]],[28,[37,3],null,[[\"mini\"],[true]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"analysis-matching-source__sequence-cell\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mini-input item-table__read-only-value\"],[12],[1,\"\\n        \"],[1,[30,1,[\"sequence\",\"sequence\"]]],[1,\" \\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-link no-padding\"],[24,\"tabindex\",\"-1\"],[4,[38,4],[\"click\",[30,3]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@item\",\"@canRemove\",\"@remove\"],false,[\"if\",\"not\",\"auto-complete/bound-model\",\"hash\",\"on\"]]",
    "moduleName": "plutof/templates/components/analysis/matching-source-item.hbs",
    "isStrictMode": false
  });
});