define("plutof/components/specimen/view/abridged/-measurements-tab", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@group.tab @name="measurements">
      <@group.header @name="measurements">
          {{i18n-t "General.measurements"}}
      </@group.header>
  
      <Measurements::MeasurementView::ExpandedDialog as |expand|>
          <Layout::RegularGrid @columns={{2}}>
              {{#each @measurements as |om|}}
                  <div>
                      <Measurements::MeasurementView::Single
                          @measurement={{om}}
                          @expand={{unless om.valueIsEmpty (fn expand om)}} />
                  </div>
              {{/each}}
          </Layout::RegularGrid>
      </Measurements::MeasurementView::ExpandedDialog>
  </@group.tab>
  
  */
  {
    "id": "PbmH5+N6",
    "block": "[[[8,[30,1,[\"tab\"]],null,[[\"@name\"],[\"measurements\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@name\"],[\"measurements\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,0],[\"General.measurements\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"                \"],[10,0],[12],[1,\"\\n                    \"],[8,[39,5],null,[[\"@measurement\",\"@expand\"],[[30,4],[52,[51,[30,4,[\"valueIsEmpty\"]]],[28,[37,7],[[30,2],[30,4]],null]]]],null],[1,\"\\n                \"],[13],[1,\"\\n\"]],[4]],null],[1,\"        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@group\",\"expand\",\"@measurements\",\"om\"],false,[\"i18n-t\",\"measurements/measurement-view/expanded-dialog\",\"layout/regular-grid\",\"each\",\"-track-array\",\"measurements/measurement-view/single\",\"unless\",\"fn\"]]",
    "moduleName": "plutof/components/specimen/view/abridged/-measurements-tab.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});