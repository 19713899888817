define("plutof/components/linked-items/view/permits", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/models/permit/item"], function (_exports, _component, _templateFactory, _component2, _object, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.items wait=true as |items|}}
      {{yield (hash
          count=items.length
          tab=(if
              (not @disabled)
              (component "linked-items/view/-permits-tab" group=@group items=items)))}}
  {{/resolve-promise}}
  
  */
  {
    "id": "0kCm9Eh2",
    "block": "[[[6,[39,0],[[30,0,[\"items\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[18,4,[[28,[37,2],null,[[\"count\",\"tab\"],[[30,1,[\"length\"]],[52,[28,[37,4],[[30,2]],null],[50,\"linked-items/view/-permits-tab\",0,null,[[\"group\",\"items\"],[[30,3],[30,1]]]]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"items\",\"@disabled\",\"@group\",\"&default\"],false,[\"resolve-promise\",\"yield\",\"hash\",\"if\",\"not\",\"component\"]]",
    "moduleName": "plutof/components/linked-items/view/permits.hbs",
    "isStrictMode": false
  });
  let LinkedItemsViewPermits = _exports.default = (_dec = (0, _object.computed)('args.record', 'args.disabled'), _class = class LinkedItemsViewPermits extends _component2.default {
    get items() {
      if (this.args.disabled) {
        return [];
      }
      return (0, _item.loadPermitItems)(this.args.record);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "items", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LinkedItemsViewPermits);
});