define("plutof/components/quick-browse/list/item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="quick-browse-list-item">
      <button
          {{on "click" @togglePin}}
          class="column-control-btn {{if @record.isPinned 'column-control-btn--active'}}"
          ...attributes
      >
          <span class="fas fa-thumbtack"></span>
      </button>
  
      <button
          {{on "click" @selectRecord}}
          class="
              btn-link quick-browse-list-item__btn
              {{if @isActive 'quick-browse-list-item__btn--active'}}
          "
          data-quick-browse="list-record"
      >
          <span>
              {{#if @record.data.has_sequences}}
                  <span class={{icon "sequence"}}></span>
              {{/if}}
  
              {{@record.data.name}}
  
              {{#if @record.data.taxon_node.name}}
                  - {{@record.data.taxon_node.name}}
              {{/if}}
          </span>
      </button>
  </div>
  
  */
  {
    "id": "KYQ9DE7E",
    "block": "[[[10,0],[14,0,\"quick-browse-list-item\"],[12],[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[\"column-control-btn \",[52,[30,1,[\"isPinned\"]],\"column-control-btn--active\"]]]],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"fas fa-thumbtack\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[11,\"button\"],[16,0,[29,[\"\\n            btn-link quick-browse-list-item__btn\\n            \",[52,[30,4],\"quick-browse-list-item__btn--active\"],\"\\n        \"]]],[24,\"data-quick-browse\",\"list-record\"],[4,[38,1],[\"click\",[30,5]],null],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n\"],[41,[30,1,[\"data\",\"has_sequences\"]],[[[1,\"                \"],[10,1],[15,0,[28,[37,2],[\"sequence\"],null]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n            \"],[1,[30,1,[\"data\",\"name\"]]],[1,\"\\n\\n\"],[41,[30,1,[\"data\",\"taxon_node\",\"name\"]],[[[1,\"                - \"],[1,[30,1,[\"data\",\"taxon_node\",\"name\"]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@record\",\"&attrs\",\"@togglePin\",\"@isActive\",\"@selectRecord\"],false,[\"if\",\"on\",\"icon\"]]",
    "moduleName": "plutof/components/quick-browse/list/item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});