define("plutof/utils/annotation/format", ["exports", "ember-inflector", "rsvp", "plutof/config/environment", "plutof/utils/push-to-store", "plutof/utils/reflection", "plutof/utils/errors"], function (_exports, _emberInflector, _rsvp, _environment, _pushToStore, _reflection, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Operation = _exports.AnnotationFormats = _exports.AnnotationFormatImplementations = void 0;
  _exports.applyAnnotation = applyAnnotation;
  _exports.coalesceChanges = coalesceChanges;
  const Operation = _exports.Operation = {
    Add: 'add',
    Modify: 'modify',
    Remove: 'remove'
  };
  const AnnotationFormats = _exports.AnnotationFormats = {
    APIdiff1: 'flat',
    // APIdiff1 with previous values and per-field commentary
    APIdiff2: 'api-diff-v2',
    Elixir: 'elixir'
  };

  // TODO: Move format fields to annotation model, then merge with AnnotationFormats,
  // so that the model field has one of these as a value
  const AnnotationFormatImplementations = _exports.AnnotationFormatImplementations = {
    [AnnotationFormats.APIdiff1]: {
      id: AnnotationFormats.APIdiff1,
      // Change:: { operation, model, id, fields }
      getChanges: annotation => annotation.changes,
      async apply(ajax, emberDataStore, annotationID, annotation) {
        await applyPlutofChanges(ajax, emberDataStore, annotationID, annotation.changes);
      },
      components: {
        view: 'annotation/-summary/apidiff1'
      }
    },
    [AnnotationFormats.APIdiff2]: {
      id: AnnotationFormats.APIdiff2,
      getChanges: annotation => annotation.changes,
      async apply(ajax, emberDataStore, annotationID, annotation) {
        await applyPlutofChanges(ajax, emberDataStore, annotationID, annotation.changes);
      },
      components: {
        view: 'annotation/-summary/apidiff2'
      }
    },
    [AnnotationFormats.Elixir]: {
      id: AnnotationFormats.Elixir,
      getChanges: annotation => annotation.changes,
      async apply(ajax, emberDataStore, annotationID, annotation) {
        // Turns out we have to apply Elixir changes on backend
        await applyPlutofChanges(ajax, emberDataStore, annotationID, annotation.changes);
      },
      components: {
        view: 'annotation/-summary/elixir'
      }
    }
  };
  function listURL(model) {
    return `${_environment.default.API_HOST}/${(0, _emberInflector.pluralize)(model)}/`;
  }
  function recordURL(model, id) {
    return `${listURL(model)}${id}/`;
  }
  function convertRelationsToURLs(emberDataStore, modelname, fields) {
    let converted = Object.assign({}, fields);
    try {
      const model = emberDataStore.modelFor(modelname);
      model.eachRelationship((field, meta) => {
        if (converted[field]) {
          if (meta.kind === 'hasMany') {
            converted[field] = converted[field].map(id => recordURL(meta.type, id));
          } else {
            converted[field] = recordURL(meta.type, converted[field]);
          }
        }
      });
    } catch (err) {
      // OM has no model
    }
    return converted;
  }
  async function applyAnnotation(ajax, emberDataStore, annotation) {
    const format = AnnotationFormatImplementations[annotation.format];
    try {
      await format.apply(ajax, emberDataStore, annotation.id, annotation.annotation);
    } finally {
      // Annotation change status updates must be saved
      await annotation.save();
    }
  }
  async function applyPlutofChanges(ajax, emberDataStore, annotationID, changes) {
    await checkRelationPermissions(emberDataStore, changes);
    function isStoreModel(model) {
      if (model === 'measurement/objectmeasurement') {
        return false;
      }
      try {
        emberDataStore.modelFor(model);
        return true;
      } catch (err) {
        return false;
      }
    }
    async function apply(change) {
      const {
        operation,
        model,
        id,
        fields
      } = change;
      switch (operation) {
        case Operation.Add:
          {
            const endpoint = change.endpoint || listURL(model);
            const response = await ajax.post(endpoint + `?@annotation=${annotationID}`, {
              data: convertRelationsToURLs(emberDataStore, model, fields)
            });
            return {
              applied: true,
              serverID: response.id.toString()
            };
          }
        case Operation.Modify:
          {
            const url = change.endpoint || recordURL(model, id);
            const converted = convertRelationsToURLs(emberDataStore, model, fields);
            const data = await ajax.request(url);
            Object.assign(data, converted);
            const response = await ajax.put(url + `?@annotation=${annotationID}`, {
              data
            });
            if (isStoreModel(model)) {
              // Update local ember-data record's state
              await (0, _pushToStore.default)(emberDataStore, model, response);
            }
            return {
              applied: true
            };
          }
        case Operation.Remove:
          {
            const endpoint = change.endpoint || recordURL(model, id);
            await ajax.delete(endpoint + `?@annotation=${annotationID}`);
            return {
              applied: true
            };
          }
        default:
          throw new Error(`Unknown annotation operation: ${operation}`);
      }
    }
    const localID = /^@\d+$/;

    // Because of dependencies between additions, it's not as easy as changes.map(apply)
    //
    // For example, two adds:
    // { id: '@1' }
    // { id: '@2', fields: { foo: '@1' } }
    //
    // Because @2 can be saved, @1 needs to be resolved to a proper ID (this operation is stored in localIDResolves).
    // Then foo: @1 can be replaced by foo: 123 and @2 save works
    //
    // Alternatively, could just apply changes one-by-one, but this way is parallelized when possible
    let localIDResolves = new Map();
    let resolves = [];
    changes.forEach(change => {
      // If the change is already successfully applied, skip it
      if (change.status && change.status.applied) {
        if (change.operation === Operation.Add) {
          // Add local -> server id for this change to the map so that unapplied changes can
          // refer to it correctly
          localIDResolves.set(change.id, _rsvp.default.Promise.resolve(change.status.serverID));
        }
        return;
      }
      let requiredIDs = [];
      try {
        emberDataStore.modelFor(change.model).eachRelationship((field, meta) => {
          if (localID.test(change.fields[field])) {
            requiredIDs.push(field);
          }

          // Generic relation hacks
          if (meta.type === 'contenttype') {
            const objectIDField = field.replace('content_type', 'object_id');
            const objectID = change.fields[objectIDField];
            if (objectID && localID.test(objectID)) {
              requiredIDs.push(objectIDField);
            }
          }
        });
      } catch (err) {
        // Non-models (ObjectMeasurement)
      }
      const idsFilled = _rsvp.default.all(requiredIDs.map(field => localIDResolves.get(change.fields[field]).then(id => {
        change.fields[field] = id;
      })));

      // Note that if idsFilled fails, we don't change the status of dependant
      // field, only the one that's actually failed
      const applied = idsFilled.then(() => apply(change).then(status => {
        change.status = status;
      }, err => {
        change.status = {
          applied: false,
          error: (0, _errors.getErrorMessage)(err)
        };
        throw err;
      }));
      resolves.push(applied);
      if (change.operation === Operation.Add) {
        localIDResolves.set(change.id, applied.then(() => change.status.serverID));
      }
    });
    return _rsvp.default.allSettled(resolves).then(results => {
      const failed = results.find(r => r.state === 'rejected');
      if (failed) {
        throw failed.reason;
      }
    });
  }

  // Is it possible for client-side annotation to refer to a record that won't be
  // accessible to the moderator. When the moderator tries to accept it, it will fail,
  // but possibly fail partially, with other changes going through. To mitigate that
  // and make client-side annotations a bit more robust (though they can't be 100%
  // so), we check that each relation used is reachable
  async function checkRelationPermissions(emberDataStore, changes) {
    const relations = [];
    const genericRelations = [];
    changes.filter(change => change.operation === Operation.Add || change.operation === Operation.Modify).forEach(change => {
      const model = emberDataStore.modelFor(change.model);
      model.eachRelationship((field, meta) => {
        const changed = change.fields[field];
        if (changed) {
          // Generic relations have to be hacked in
          if (meta.type === 'contenttype') {
            // This, of course, depends on a convention and can easily break but that's
            // the story of annotations
            const oid = change.fields[field.replace('content_type', 'object_id')];
            if (oid) {
              genericRelations.push([changed, oid]);
            }
          }
          if (meta.kind === 'hasMany') {
            for (const id of changed) {
              relations.push([meta.type, id]);
            }
          } else {
            relations.push([meta.type, changed]);
          }
        }
      });
    });
    await _rsvp.default.Promise.all(genericRelations.map(async _ref => {
      let [ctypeID, oid] = _ref;
      const ctype = await emberDataStore.findRecord('contenttype', ctypeID);
      relations.push([ctype.model_name, oid]);
    }));
    await _rsvp.default.Promise.all(relations.filter(_ref2 => {
      let [_, id] = _ref2;
      return !isLocalID(id);
    }).map(async _ref3 => {
      let [model, id] = _ref3;
      try {
        await emberDataStore.findRecord(model, id);
      } catch (err) {
        const i18n = (0, _reflection.getService)(emberDataStore, 'i18n');
        throw new _errors.userError(i18n.translate('annotation.errors.missingRelationPermission', {
          hash: {
            record: `${model}:${id}`
          }
        }));
      }
    }));
  }
  function combineChanges(x, y) {
    if (y.operation === Operation.Remove) {
      // Remove overwrites whatever came before
      return y;
    }
    if (y.operation === Operation.Add) {
      // Shouldn't happen, but if it does, we stop everything
      throw new Error('Invalid changeset: add operation on existing record');
    }
    if (x.operation === Operation.Remove) {
      throw new Error('Invalid changeset: modify operation on removed record');
    }
    return {
      operation: x.operation,
      // y.op is always modify, but add + modify = add
      model: x.model,
      id: x.id,
      fields: Object.assign({}, x.fields, y.fields)
    };
  }
  function isLocalID(id) {
    return id.startsWith('@');
  }
  function isLocalChange(change) {
    return isLocalID(change.id);
  }

  // Combines multiple changes to the same model
  // NB: Order-preserving, just in case
  function coalesceChanges(changes) {
    let lastSeen = new Map();
    let coalesced = [];
    for (const change of changes) {
      const recordID = `${change.model}:${change.id}`;
      if (change.operation === Operation.Remove && isLocalChange(change)) {
        // Not sending a remove on an in-annotation record
        coalesced = coalesced.filter(c => c.model !== change.model || c.id !== change.id);
      } else if (lastSeen.has(recordID)) {
        const index = lastSeen.get(recordID);
        coalesced[index] = combineChanges(coalesced[index], change);
      } else {
        coalesced.push(change);
        lastSeen.set(recordID, coalesced.length - 1);
      }
    }
    return coalesced;
  }
});