define("plutof/translations/eng/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    Projects: 'Projects',
    Project: 'Project',
    DMP: 'Data Management Plans',
    taxon: 'Taxon',
    taxonOccurrences: 'Taxon Occurrences',
    References: 'References',
    Clipboard: 'Clipboard',
    ClipboardModule: 'Clipboard & Export',
    Settings: 'Settings',
    laboratories: 'Laboratories',
    Doi: 'DOI',
    Specimens: 'Specimens',
    Observations: 'Observations',
    Sequences: 'Sequences',
    LivingC: 'Living Specimens',
    RefBased: 'Reference Based',
    FileRepo: 'File Repository',
    editFileRepo: 'Edit File',
    CollectionManagement: 'Collection Laboratory',
    SequenceAnalyze: 'Sequence Analysis',
    UNITESH: 'UNITE Species Hypotheses',
    materialSamples: 'Material Samples',
    Photobank: 'Photobank',
    MolecularLab: 'Molecular Laboratory',
    AnalysesLab: 'Analysis Laboratory',
    PublishingLab: 'Publishing Laboratory',
    gisLab: 'GIS Laboratory',
    New: 'New',
    Save: 'Save',
    Edit: 'Edit',
    Bookmark: 'Bookmark',
    Bookmarks: 'Bookmarks',
    Info: 'Info',
    Cancel: 'Cancel',
    NoInfo: 'No info available.',
    Reset: 'Reset',
    Reject: 'Reject',
    Remove: 'Remove',
    Add: 'Add',
    Back: 'Back',
    Upload: 'Upload',
    Close: 'Close',
    Search: 'Search',
    Select: 'Select',
    Download: 'Download',
    Statistics: 'Statistics',
    Clear: 'Clear',
    Delete: 'Delete',
    showMore: 'Show more',
    Reply: 'Reply',
    autoCompletePlaceholder: 'Type to find...',
    datepickerPlaceholder: 'YYYY-MM-DD',
    timePlaceholder: 'hh:mm',
    loggedInAs: 'You are logged in as',
    measurements: 'Traits and Measurements',
    associatedData: 'Associated Data',
    identifications: 'Identifications',
    identifiedBy: 'Identified By',
    collectedBy: 'Collected By',
    samplingArea: 'Sampling Area',
    samplingEvent: 'Sampling Event',
    samplingEvents: 'Sampling Events',
    samplingAreaEvent: 'Area and Event',
    localityAndHabitat: 'Locality and Habitat',
    preparations: 'Preparations',
    preparedBy: 'Prepared By',
    interactions: 'Interactions',
    additionalIdentifiers: 'Additional Identifiers',
    substrate: 'Substrate',
    access: 'Access Rights',
    exsiccata: 'Exsiccata',
    generalData: 'General Data',
    loadingMeasurement: 'Loading measurements',
    loading: 'Loading',
    error: 'Error',
    pending: 'Pending',
    rejected: 'Rejected',
    controls: 'Controls',
    Options: 'Options',
    Converter: 'GPS converter',
    formType: 'Form type',
    externalLink: 'External Links',
    owner: 'Rights holder',
    ownerTooltip: 'PlutoF record rights holder who can edit record and its access rights.',
    mainformName: 'Form name',
    measurementMethod: 'Method',
    loadMore: 'Load more',
    loadAll: 'Load all',
    yes: 'Yes',
    no: 'No',
    ok: 'OK',
    myMenu: 'My Menu',
    saving: 'Saving...',
    deleting: 'Deleting...',
    done: 'Done.',
    bookmarksAndSearches: 'Bookmarks and Searches',
    Notifications: 'Notifications',
    record: 'Record',
    searches: 'Searches',
    routeExitDlgTitle: 'Are you sure you want to leave without saving?',
    routeExitDlgContent: 'If you leave without saving, you’ll lose unsaved work.',
    deleteFilesDlgTitle: 'Delete related files',
    deleted: 'Removed',
    restore: 'Restore',
    fields: 'Fields',
    all: 'All',
    login: 'Log in',
    logout: 'Log out',
    loggingIn: 'loading profile...',
    openWorkbench: 'Open Workbench',
    updateNotification: 'Update available. <button class="btn-fake-link" onclick="window.location.reload(true)">Please refresh</button>.',
    browserUpdateNotification: 'Update available. Please refresh.',
    pageTitle: 'PlutoF biodiversity platform',
    addExternalMeasurements: 'Add measurements',
    description: 'Description',
    textCopiedToClipboard: 'Text copied: "{{text}}"',
    dialogs: {
      delete: {
        title: 'Delete?',
        content: 'Deleted entries can not be recovered.',
        yes: 'Yes',
        no: 'No'
      },
      clipboardAppend: {
        title: 'Add {{moduleRecords}} to Clipboard',
        content: 'Append to, or overwrite clipboard {{moduleRecords}}.',
        append: 'Append',
        overwrite: 'Overwrite',
        modules: {
          // Keep in sync with utils/modules clipboard.id field
          // (which depends on the backend)
          livingcultures: 'living specimens',
          materialsamples: 'material samples',
          observations: 'observations',
          sequences: 'sequences',
          specimens: 'specimens',
          occurrences: 'reference based occurrences',
          photobanks: 'photobank items'
        }
      }
    },
    connection: {
      lost: 'Connection lost',
      restored: 'Connection restored'
    },
    readMore: {
      more: 'More…',
      less: 'Less…'
    },
    tableBulkUpdate: {
      toggle: 'Bulk edit',
      update: 'Update'
    },
    termsOfService: {
      dialogTitle: 'Data Protection',
      accept: 'Accept',
      decline: 'Decline'
    },
    occurrencesCount: {
      label: 'Taxon occurrences'
    },
    pagination: {
      maxPageTooltip: 'Browsable page limit reached.',
      maxPageNotification: 'Browsable record limit reached: {{maxResults}} records.'
    },
    relatedIds: {
      label: 'Related IDs',
      tooltip: 'Records with same ID, but a different subcode.'
    }
  };
});