define("plutof/components/annotation/record-annotations/list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <I18n::ModelStrings @model="annotation.client-annotation" as |translations|>
      <RelatedObjects::Content @pagination={{@annotations}} as |Field annotation|>
          <Annotation::Fields
              @field={{Field}}
              @annotation={{annotation}}
              @translations={{translations}}
              as |fields|
          >
              <fields.icon />
  
              <Field @label={{i18n-t "annotation.fields.status"}}>
                  <Annotation::AnnotationLink
                      @annotation={{annotation}}
                      @accessRights={{@accessRights}}
                  >
                      {{get translations.status.choices annotation.status}}
                  </Annotation::AnnotationLink>
              </Field>
  
              <fields.author/>
              <fields.comment />
              <fields.annotatedAt />
          </Annotation::Fields>
      </RelatedObjects::Content>
  </I18n::ModelStrings>
  
  */
  {
    "id": "q5HlLWhG",
    "block": "[[[8,[39,0],null,[[\"@model\"],[\"annotation.client-annotation\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@pagination\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@field\",\"@annotation\",\"@translations\"],[[30,3],[30,4],[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,5,[\"icon\"]],null,null,null],[1,\"\\n\\n            \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"annotation.fields.status\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,4],null,[[\"@annotation\",\"@accessRights\"],[[30,4],[30,6]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[28,[35,5],[[30,1,[\"status\",\"choices\"]],[30,4,[\"status\"]]],null]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,5,[\"author\"]],null,null,null],[1,\"\\n            \"],[8,[30,5,[\"comment\"]],null,null,null],[1,\"\\n            \"],[8,[30,5,[\"annotatedAt\"]],null,null,null],[1,\"\\n        \"]],[5]]]]],[1,\"\\n    \"]],[3,4]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"translations\",\"@annotations\",\"Field\",\"annotation\",\"fields\",\"@accessRights\"],false,[\"i18n/model-strings\",\"related-objects/content\",\"annotation/fields\",\"i18n-t\",\"annotation/annotation-link\",\"get\"]]",
    "moduleName": "plutof/components/annotation/record-annotations/list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});