define("plutof/components/collection-lab/view/condition-assessments", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <CollectionLab::ConditionAssessment::View
      @record={{@object}}
      @occurrenceType={{@occurrenceType}}
      as |assessments|
  >
      <RelatedObjects::View
          @title={{i18n-t "CollectionManagement.mainTitle"}}
          @pagination={{assessments.pagination}}
          @canModify={{@occurrenceType}}
          @add={{assessments.add}}
      >
          {{assessments.view}}
      </RelatedObjects::View>
  </CollectionLab::ConditionAssessment::View>
  
  */
  {
    "id": "NPwFa+o1",
    "block": "[[[8,[39,0],null,[[\"@record\",\"@occurrenceType\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@title\",\"@pagination\",\"@canModify\",\"@add\"],[[28,[37,2],[\"CollectionManagement.mainTitle\"],null],[30,3,[\"pagination\"]],[30,2],[30,3,[\"add\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"view\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@object\",\"@occurrenceType\",\"assessments\"],false,[\"collection-lab/condition-assessment/view\",\"related-objects/view\",\"i18n-t\"]]",
    "moduleName": "plutof/components/collection-lab/view/condition-assessments.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});