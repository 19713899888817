define("plutof/templates/components/interaction/view-all", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vw0XfOOA",
    "block": "[[[8,[39,0],null,[[\"@object\",\"@parentObject\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,\"data-test\",\"Interaction::ViewAll\"]],[[\"@collapse\"],[[28,[37,2],[[30,4]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,5,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,3],[\"General.interactions\"],null],[30,0,[\"total\"]]]],null],[1,\"\\n\\n        \"],[8,[30,5,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[5]]]]],[1,\"\\n\"]],[3,4]]]]],[1,\"\\n\"]],[\"@object\",\"@parentObject\",\"interactionView\",\"total\",\"panel\"],false,[\"interaction/view\",\"plutof-panel/item-list\",\"not\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/interaction/view-all.hbs",
    "isStrictMode": false
  });
});