define("plutof/translations/eng/quick-browse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    button: 'Quick Browse',
    query: {
      project: 'Project',
      collection: 'Collection',
      taxon: 'Taxon',
      text: 'Name or ID',
      textPlaceholder: 'e.g. TU1234',
      hasMedia: 'Media',
      hasSequences: 'Sequences'
    },
    ordering: {
      modified: 'Modified',
      taxon: 'Taxon'
    },
    tooltips: {
      hasMedia: 'Filter records with image or video',
      hasSequences: 'Filter records that have sequences',
      modified: 'Sort by last modified time',
      taxon: 'Sort by taxon name'
    },
    popup: {
      taxon: 'Taxon',
      taxonRank: 'Taxon rank',
      taxonVernacularNames: 'Common names',
      collection: 'Collection',
      project: 'Project',
      area: 'Location',
      collected: 'Collected',
      identified: 'Identified'
    },
    pagination: {
      next: 'Next',
      previous: 'Previous'
    }
  };
});