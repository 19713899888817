define("plutof/components/linked-items/view/files", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "rsvp", "plutof/utils/annotation/format", "plutof/utils/push-to-store", "plutof/utils/reflection", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _object, _service, _rsvp, _format, _pushToStore, _reflection, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.files wait=true as |files|}}
      {{yield (hash
          count=files.all.length
          content=(if (not @disabled) (component "linked-items/view/-files-content" files=files))
          tab=(if
              (not @disabled)
              (component "linked-items/view/-tab-wrapper"
                  group=@group
                  name="files"
                  header=(i18n-t "FileRepository.files")
                  count=files.all.length
                  content=(component "linked-items/view/-files-content" files=files)))
      )}}
  {{/resolve-promise}}
  
  */
  {
    "id": "oAx4Y7SW",
    "block": "[[[6,[39,0],[[30,0,[\"files\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[18,4,[[28,[37,2],null,[[\"count\",\"content\",\"tab\"],[[30,1,[\"all\",\"length\"]],[52,[28,[37,4],[[30,2]],null],[50,\"linked-items/view/-files-content\",0,null,[[\"files\"],[[30,1]]]]],[52,[28,[37,4],[[30,2]],null],[50,\"linked-items/view/-tab-wrapper\",0,null,[[\"group\",\"name\",\"header\",\"count\",\"content\"],[[30,3],\"files\",[28,[37,6],[\"FileRepository.files\"],null],[30,1,[\"all\",\"length\"]],[50,\"linked-items/view/-files-content\",0,null,[[\"files\"],[[30,1]]]]]]]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"files\",\"@disabled\",\"@group\",\"&default\"],false,[\"resolve-promise\",\"yield\",\"hash\",\"if\",\"not\",\"component\",\"i18n-t\"]]",
    "moduleName": "plutof/components/linked-items/view/files.hbs",
    "isStrictMode": false
  });
  let LinkedItemsViewFiles = _exports.default = (_dec = (0, _object.computed)('args.record', 'args.disabled'), _class = class LinkedItemsViewFiles extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get files() {
      if (this.args.disabled) {
        return {
          all: [],
          record: [],
          event: []
        };
      }
      return _rsvp.default.all([this.loadItems(this.args.record), _rsvp.default.Promise.resolve(this.args.record.samplingevent).then(event => event ? this.loadItems(event) : [])]).then(_ref2 => {
        let [record, event] = _ref2;
        return {
          all: event.concat(record),
          record,
          event
        };
      });
    }
    async loadItems(record) {
      const url = (0, _reflection.get_record_url)(record) + 'files/';
      let items = await this.ajax.request(url);

      // XXX: Sequencing being a JSON API endpoint just
      // changes the format of this without asking
      if (items.data) {
        items = items.data;
      }
      for (const item of items) {
        item.file = (0, _pushToStore.default)(this.store, 'filerepository/file', item.file);
      }
      if (this.store.inAnnotation) {
        // Annotate removals
        for (const item of items) {
          const change = this.store.annotatedStore.getRecordChange('filerepository/item', item.id);
          if (change && change.operation === _format.Operation.Remove) {
            item.removedInAnnotation = true;
          }
        }

        // Annotate additions
        const ctype = (0, _modules.getRecordContentType)(record);
        const addedInAnnotation = this.store.annotatedStore.rawQueryAdditions('filerepository/item', {
          content_type: ctype,
          object_id: record.id
        });
        const resolvedAdditions = await _rsvp.default.Promise.all(addedInAnnotation.map(async rawItem => {
          const file = await this.store.findRecord('filerepository/file', rawItem.file);
          return {
            id: rawItem.id,
            file,
            addedInAnnotation: true
          };
        }));
        items = items.concat(resolvedAdditions);
      }
      return items;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "files", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "files"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LinkedItemsViewFiles);
});