define("plutof/components/linked-items/view/-load-items", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "rsvp", "plutof/misc/content_types", "plutof/utils/pagination"], function (_exports, _component, _templateFactory, _component2, _object, _service, _rsvp, _content_types, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.items wait=true as |items|}}
      {{yield items}}
  {{/resolve-promise}}
  
  */
  {
    "id": "54cqhhMP",
    "block": "[[[6,[39,0],[[30,0,[\"items\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[18,2,[[30,1]]],[1,\"\\n\"]],[1]]]]]],[\"items\",\"&default\"],false,[\"resolve-promise\",\"yield\"]]",
    "moduleName": "plutof/components/linked-items/view/-load-items.hbs",
    "isStrictMode": false
  });
  let LinkedItemsViewReferences = _exports.default = (_dec = (0, _object.computed)('args.record', 'args.itemModel', 'args.disabled'), _class = class LinkedItemsViewReferences extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get items() {
      // This is hacky, but seems the best available way to load
      // only enabled tabs in LinkedItems::View, while still being
      // able to count how many items we have in total (for panel collapse)
      if (this.args.disabled) {
        return _rsvp.default.Promise.resolve(_pagination.EmptyPagination.create());
      }
      return (0, _content_types.get_object_ctype)(this.store, this.args.record).then(ctype => (0, _pagination.paginateModel)(this.store, this.args.itemModel, {
        content_type: ctype.id,
        object_id: this.args.record.id
      }, [_pagination.AccumulatingPaginationMixin]));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "items", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LinkedItemsViewReferences);
});