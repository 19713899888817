define("plutof/routes/specimen/annotation/view", ["exports", "plutof/routes/specimen/view-full", "plutof/utils/annotation/routes"], function (_exports, _viewFull, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.annotationViewRoute)(_viewFull.default, {
    parentRoute: 'specimen'
  });
});