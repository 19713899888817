define("plutof/components/plutof-panel/headerless", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="panel {{unless @withMargin 'no-margin'}}" ...attributes>
      <div class="panel-body">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "TonVAJFt",
    "block": "[[[11,0],[16,0,[29,[\"panel \",[52,[51,[30,1]],\"no-margin\"]]]],[17,2],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@withMargin\",\"&attrs\",\"&default\"],false,[\"unless\",\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/headerless.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});