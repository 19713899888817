define("plutof/templates/components/chart/pheno-graph-taxon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "bCDZrd30",
    "block": "[[[41,[30,0,[\"chartConfig\",\"data\",\"datasets\",\"length\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"type\",\"options\",\"data\"],[[30,0,[\"chartConfig\",\"type\"]],[30,0,[\"chartConfig\",\"options\"]],[30,0,[\"chartConfig\",\"data\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"chart/base-chart\"]]",
    "moduleName": "plutof/templates/components/chart/pheno-graph-taxon.hbs",
    "isStrictMode": false
  });
});