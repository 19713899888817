define("plutof/components/common/clearable-text-field", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
      class="clearable-input"
      ...attributes
  >
      <Input
          @value={{@value}}
          @keyUp={{@keyUp}}
          @enter={{@enter}}
          placeholder={{@placeholder}}
          class="form-control" />
  
      {{#if @value}}
          <button
              {{on "click" @clear}}
              class="clearable-input__clear-btn"
          >
              <span class={{icon "cancel"}}></span>
          </button>
      {{/if}}
  </div>
  
  */
  {
    "id": "yqN4rKTU",
    "block": "[[[11,0],[24,0,\"clearable-input\"],[17,1],[12],[1,\"\\n    \"],[8,[39,0],[[16,\"placeholder\",[30,2]],[24,0,\"form-control\"]],[[\"@value\",\"@keyUp\",\"@enter\"],[[30,3],[30,4],[30,5]]],null],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"        \"],[11,\"button\"],[24,0,\"clearable-input__clear-btn\"],[4,[38,2],[\"click\",[30,6]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[28,[37,3],[\"cancel\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@placeholder\",\"@value\",\"@keyUp\",\"@enter\",\"@clear\"],false,[\"input\",\"if\",\"on\",\"icon\"]]",
    "moduleName": "plutof/components/common/clearable-text-field.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});