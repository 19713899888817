define("plutof/components/access/-view-rights/derived-from", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#access/-view-rights/column}}
      <GenericViews::CopyContent as |copy|>
          <Ui::-ClickableLabel @clicked={{copy.copy}}>
              {{#if this.module.name}}
                  {{this.module.name}}
              {{else}}
                  {{i18n-t "Access.derivedFrom"}}
              {{/if}}
          </Ui::-ClickableLabel>
  
          <p {{copy.register-content}}>
              {{#if this.module.routes.view}}
                  <LinkTo @route={{this.module.routes.view}} @model={{@parent.id}}>
                      <span class={{this.module.icon}}></span>
                      {{@parent.name}}
                  </LinkTo>
              {{else}}
                  <span class={{this.module.icon}}></span>
                  {{@parent.name}}
              {{/if}}
          </p>
      </GenericViews::CopyContent>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "cz/rcpq4",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@clicked\"],[[30,1,[\"copy\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"module\",\"name\"]],[[[1,\"                \"],[1,[30,0,[\"module\",\"name\"]]],[1,\"\\n\"]],[]],[[[1,\"                \"],[1,[28,[35,4],[\"Access.derivedFrom\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n\\n        \"],[11,2],[4,[30,1,[\"register-content\"]],null,null],[12],[1,\"\\n\"],[41,[30,0,[\"module\",\"routes\",\"view\"]],[[[1,\"                \"],[8,[39,5],null,[[\"@route\",\"@model\"],[[30,0,[\"module\",\"routes\",\"view\"]],[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[10,1],[15,0,[30,0,[\"module\",\"icon\"]]],[12],[13],[1,\"\\n                    \"],[1,[30,2,[\"name\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"                \"],[10,1],[15,0,[30,0,[\"module\",\"icon\"]]],[12],[13],[1,\"\\n                \"],[1,[30,2,[\"name\"]]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]]],[\"copy\",\"@parent\"],false,[\"access/-view-rights/column\",\"generic-views/copy-content\",\"ui/-clickable-label\",\"if\",\"i18n-t\",\"link-to\"]]",
    "moduleName": "plutof/components/access/-view-rights/derived-from.hbs",
    "isStrictMode": false
  });
  class ViewRightsDerivedFrom extends _component2.default {
    get module() {
      return _modules.default[this.args.parent.type];
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewRightsDerivedFrom);
});