define("plutof/components/view-field", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
      <GenericViews::CopyContent as |copy|>
          {{#if (has-block "label")}}
              {{yield (hash copy=copy.copy) to="label"}}
          {{else}}
              <Ui::Label
                  @label={{@label}}
                  @tooltip={{@tooltip}}
                  @clicked={{copy.copy}} />
          {{/if}}
  
          <p
              {{copy.register-content}}
              class="view-field"
              ...attributes
          >
              {{yield}}
          </p>
      </GenericViews::CopyContent>
  </div>
  
  */
  {
    "id": "0GV1EfKP",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"            \"],[18,5,[[28,[37,4],null,[[\"copy\"],[[30,1,[\"copy\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,5],null,[[\"@label\",\"@tooltip\",\"@clicked\"],[[30,2],[30,3],[30,1,[\"copy\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n        \"],[11,2],[24,0,\"view-field\"],[17,4],[4,[30,1,[\"register-content\"]],null,null],[12],[1,\"\\n            \"],[18,6,null],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"copy\",\"@label\",\"@tooltip\",\"&attrs\",\"&label\",\"&default\"],false,[\"generic-views/copy-content\",\"if\",\"has-block\",\"yield\",\"hash\",\"ui/label\"]]",
    "moduleName": "plutof/components/view-field.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});