define("plutof/components/measurements/measurement-view/single", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GenericViews::CopyContent as |copy|>
      <Ui::Label
          @label={{@measurement.fullName}}
          @tooltip={{@measurement.fullDescription}}
          @infoLinks={{@measurement.infoLinks}}
      >
          <:label>
              <div class="plutof-label">
                  <button {{on "click" copy.copy}} class="btn-reset font-bold">
                      {{@measurement.fullName}}
                  </button>
  
                  {{#if @expand}}
                      <button
                          {{on "click" @expand}}
                          class="btn-fake-link btn-fake-link--no-underline"
                      >
                          ...
                      </button>
                  {{/if}}
              </div>
          </:label>
      </Ui::Label>
  
      <p
          {{copy.register-content}}
          data-measurement="{{@measurement.measurementData.name}}"
          class="view-measurements__measurement {{if @measurement.augmented 'annotated'}}"
      >
          {{@measurement.representation}}&nbsp;
      </p>
  </GenericViews::CopyContent>
  
  */
  {
    "id": "sNFfTIGC",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@label\",\"@tooltip\",\"@infoLinks\"],[[30,2,[\"fullName\"]],[30,2,[\"fullDescription\"]],[30,2,[\"infoLinks\"]]]],[[\"label\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                \"],[11,\"button\"],[24,0,\"btn-reset font-bold\"],[4,[38,2],[\"click\",[30,1,[\"copy\"]]],null],[12],[1,\"\\n                    \"],[1,[30,2,[\"fullName\"]]],[1,\"\\n                \"],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"                    \"],[11,\"button\"],[24,0,\"btn-fake-link btn-fake-link--no-underline\"],[4,[38,2],[\"click\",[30,3]],null],[12],[1,\"\\n                        ...\\n                    \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n    \"],[11,2],[16,\"data-measurement\",[29,[[30,2,[\"measurementData\",\"name\"]]]]],[16,0,[29,[\"view-measurements__measurement \",[52,[30,2,[\"augmented\"]],\"annotated\"]]]],[4,[30,1,[\"register-content\"]],null,null],[12],[1,\"\\n        \"],[1,[30,2,[\"representation\"]]],[1,\" \\n    \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"copy\",\"@measurement\",\"@expand\"],false,[\"generic-views/copy-content\",\"ui/label\",\"on\",\"if\"]]",
    "moduleName": "plutof/components/measurements/measurement-view/single.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});