define("plutof/components/habitat/habitat-view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/components/habitat/habitat-edit"], function (_exports, _component, _templateFactory, _component2, _object, _service, _habitatEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="habitat-view">
      <GenericViews::Record @record={{@habitat}} as |view|>
          {{view.attr "description"}}
  
          {{#resolve-promise this.enabledFields as |enabled|}}
              <Layout::RegularGrid @columns={{3}}>
                  {{#if enabled.iucn_habitat_type}}
                      {{view.field "iucn_habitat_type"}}
                  {{/if}}
  
                  {{#if enabled.paal_habitat_type}}
                      {{view.field "paal_habitat_type"}}
                  {{/if}}
  
                  {{#if enabled.eunis_habitat_type}}
                      {{view.field "eunis_habitat_type"}}
                  {{/if}}
  
                  {{#if enabled.envo_biome}}
                      {{view.field "envo_biome"}}
                      {{view.field "envo_local_environmental_context"}}
                      {{view.field "envo_environmental_medium"}}
                  {{/if}}
  
                  {{#if enabled.water_body}}
                      {{view.field "water_body"}}
                  {{/if}}
  
                  {{view.field "nature_reserve"}}
              </Layout::RegularGrid>
          {{/resolve-promise}}
      </GenericViews::Record>
  </div>
  
  */
  {
    "id": "aguBUKli",
    "block": "[[[10,0],[14,0,\"habitat-view\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"description\"],null]],[1,\"\\n\\n\"],[6,[39,1],[[30,0,[\"enabledFields\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,2],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3,[\"iucn_habitat_type\"]],[[[1,\"                    \"],[1,[28,[30,2,[\"field\"]],[\"iucn_habitat_type\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3,[\"paal_habitat_type\"]],[[[1,\"                    \"],[1,[28,[30,2,[\"field\"]],[\"paal_habitat_type\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3,[\"eunis_habitat_type\"]],[[[1,\"                    \"],[1,[28,[30,2,[\"field\"]],[\"eunis_habitat_type\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3,[\"envo_biome\"]],[[[1,\"                    \"],[1,[28,[30,2,[\"field\"]],[\"envo_biome\"],null]],[1,\"\\n                    \"],[1,[28,[30,2,[\"field\"]],[\"envo_local_environmental_context\"],null]],[1,\"\\n                    \"],[1,[28,[30,2,[\"field\"]],[\"envo_environmental_medium\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3,[\"water_body\"]],[[[1,\"                    \"],[1,[28,[30,2,[\"field\"]],[\"water_body\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n                \"],[1,[28,[30,2,[\"field\"]],[\"nature_reserve\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[3]]]]],[1,\"    \"]],[2]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@habitat\",\"view\",\"enabled\"],false,[\"generic-views/record\",\"resolve-promise\",\"layout/regular-grid\",\"if\"]]",
    "moduleName": "plutof/components/habitat/habitat-view.hbs",
    "isStrictMode": false
  });
  let HabitatView = (_class = class HabitatView extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "settings", _descriptor, this);
    }
    get enabledFields() {
      return (0, _habitatEdit.loadEnabledHabitatFields)(this.settings);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "enabledFields", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "enabledFields"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HabitatView);
});