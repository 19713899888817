define("plutof/templates/components/analysis/edit-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "RWdhyjPH",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],null,[[\"name\",\"type\",\"setType\",\"validationContext\"],[[30,1],[30,2],[30,3],[30,4,[\"generalData\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"    \"],[46,[30,2,[\"components\",\"source\"]],null,[[\"data\",\"validationContext\"],[[30,5],[30,4,[\"sourceData\"]]]],null],[1,\"\\n\\n\"],[41,[30,2,[\"components\",\"parameters\"]],[[[1,\"        \"],[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"Analysis.programParameters\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[46,[30,2,[\"components\",\"parameters\"]],null,[[\"run\",\"validationContext\"],[[30,6],[30,4,[\"parameters\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,4]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],[[24,0,\"btn btn-default save-button\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[30,7],[30,4,[\"isInvalid\"]],\"far fa-play-circle\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"Analysis.start\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2,[\"prerun\"]],[[[1,\"            \"],[8,[39,8],null,[[\"@save\",\"@disabled\"],[[28,[37,9],[[30,0],[30,8]],null],[30,4,[\"isInvalid\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[39,10],null,[[\"@cancel\"],[[28,[37,9],[[30,0],[30,9]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@name\",\"@type\",\"@setType\",\"@validationContext\",\"@sourceData\",\"@run\",\"@saveAndRun\",\"@save\",\"@cancel\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"analysis/analysis-data\",\"if\",\"component\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"promise-button\",\"common/save-button\",\"action\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/components/analysis/edit-form.hbs",
    "isStrictMode": false
  });
});