define("plutof/components/collection-lab/view/treatments", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <CollectionLab::Treatment::View
      @record={{@object}}
      @occurrenceType={{@occurrenceType}}
      as |treatments|
  >
      <RelatedObjects::View
          @title={{i18n-t "CollectionManagement.treatmentsTitle"}}
          @pagination={{treatments.pagination}}
          @add={{treatments.add}}
          @canModify={{true}}
      >
          {{treatments.view}}
      </RelatedObjects::View>
  </CollectionLab::Treatment::View>
  
  */
  {
    "id": "SxybzVsc",
    "block": "[[[8,[39,0],null,[[\"@record\",\"@occurrenceType\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@title\",\"@pagination\",\"@add\",\"@canModify\"],[[28,[37,2],[\"CollectionManagement.treatmentsTitle\"],null],[30,3,[\"pagination\"]],[30,3,[\"add\"]],true]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"view\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@object\",\"@occurrenceType\",\"treatments\"],false,[\"collection-lab/treatment/view\",\"related-objects/view\",\"i18n-t\"]]",
    "moduleName": "plutof/components/collection-lab/view/treatments.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});