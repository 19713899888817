define("plutof/templates/components/generic-views/record", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "e5kOl1+7",
    "block": "[[[41,[28,[37,1],[[30,1],[30,0,[\"modelPath\"]]],null],[[[1,\"    \"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"modelPath\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[18,3,[[28,[37,4],null,[[\"attr\",\"field\",\"link\"],[[50,\"generic-views/-record/attr\",0,null,[[\"context\",\"translations\"],[[30,0],[30,2]]]],[50,\"generic-views/-record/field\",0,null,[[\"context\",\"translations\"],[[30,0],[30,2]]]],[50,\"generic-views/-record/link\",0,null,[[\"context\",\"translations\"],[[30,0],[30,2]]]]]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"@record\",\"translations\",\"&default\"],false,[\"if\",\"and\",\"i18n/model-strings\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/generic-views/record.hbs",
    "isStrictMode": false
  });
});