define("plutof/templates/components/photobank/custom-classifications", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "CMKgm4JJ",
    "block": "[[[8,[39,0],null,null,[[\"tabs\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"classificationsData\"]]],null]],null],null,[[[6,[30,1,[\"tab\"]],null,[[\"name\"],[[30,2,[\"name\"]]]],[[\"default\"],[[[[6,[30,1,[\"header\"]],null,[[\"name\"],[[30,2,[\"name\"]]]],[[\"default\"],[[[[1,\"                    \"],[1,[30,2,[\"name\"]]],[1,\"\\n\\n                    \"],[10,1],[14,0,\"count-badge\"],[12],[1,\"\\n                        \"],[1,[30,2,[\"selectedClassificationItems\",\"length\"]]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n                \"],[1,[28,[35,3],null,[[\"classification\",\"data\",\"object\",\"selectedItems\",\"objectsToDelete\"],[[30,2,[\"classification\"]],[30,2],[30,0,[\"object\"]],[30,0,[\"items\"]],[30,0,[\"objectsToDelete\"]]]]]],[1,\"\\n\"]],[]]]]]],[2]],null],[1,\"    \"]],[1]]]]],[1,\"\\n\"]],[\"group\",\"data\"],false,[\"tab-group\",\"each\",\"-track-array\",\"photobank/classification-form\"]]",
    "moduleName": "plutof/templates/components/photobank/custom-classifications.hbs",
    "isStrictMode": false
  });
});