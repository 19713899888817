define("plutof/components/specimen/view/abridged/location", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/components/map/mixins/default-styling"], function (_exports, _component, _templateFactory, _component2, _object, _defaultStyling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.area wait=true as |area|}}
      <PlutofPanel::Headerless @withMargin={{true}}>
          <Map::ViewMap @geodata={{area.mapData}} />
  
          <div class="spacer-large"></div>
  
          <GenericViews::Record
              @record={{area.area}}
              @hideEmpty={{true}}
              as |view|
          >
              {{view.field "name"}}
              {{view.field "country"}}
              {{view.field "latitude"}}
              {{view.field "longitude"}}
              {{view.field "state"}}
              {{view.field "district"}}
              {{view.field "commune"}}
              {{view.field "locality_text"}}
              {{view.field "parish"}}
          </GenericViews::Record>
      </PlutofPanel::Headerless>
  {{/resolve-promise}}
  
  */
  {
    "id": "TO59UcWU",
    "block": "[[[6,[39,0],[[30,0,[\"area\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@withMargin\"],[true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@geodata\"],[[30,1,[\"mapData\"]]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@record\",\"@hideEmpty\"],[[30,1,[\"area\"]],true]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"name\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"country\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"latitude\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"longitude\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"state\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"district\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"commune\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"locality_text\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"parish\"],null]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"area\",\"view\"],false,[\"resolve-promise\",\"plutof-panel/headerless\",\"map/view-map\",\"generic-views/record\"]]",
    "moduleName": "plutof/components/specimen/view/abridged/location.hbs",
    "isStrictMode": false
  });
  let SpecimenAbridgedViewLocation = _exports.default = (_dec = (0, _object.computed)('args.specimen'), _class = class SpecimenAbridgedViewLocation extends _component2.default {
    get area() {
      return this.args.specimen.samplingevent.then(async event => {
        const area = await event.samplingarea;
        const mapData = [];
        if (area.geom) {
          mapData.push({
            geom: area.geom
          });
        } else {
          const country = await area.country;
          if (country.centroid) {
            const centroid = (0, _defaultStyling.makeCountryCentroidFeature)(country.code, country.centroid);
            mapData.push({
              geom: centroid.geom,
              rawStyle: centroid.rawStyle
            });
          }
        }
        return {
          area,
          mapData
        };
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "area", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "area"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SpecimenAbridgedViewLocation);
});