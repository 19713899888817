define("plutof/components/habitat/view/provider", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "rsvp"], function (_exports, _component, _templateFactory, _component2, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.data wait=true as |data|}}
      {{#if data.habitat}}
          {{yield data.habitat data.present}}
      {{/if}}
  {{/resolve-promise}}
  
  */
  {
    "id": "PpakQQqw",
    "block": "[[[6,[39,0],[[30,0,[\"data\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[41,[30,1,[\"habitat\"]],[[[1,\"        \"],[18,2,[[30,1,[\"habitat\"]],[30,1,[\"present\"]]]],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"data\",\"&default\"],false,[\"resolve-promise\",\"if\",\"yield\"]]",
    "moduleName": "plutof/components/habitat/view/provider.hbs",
    "isStrictMode": false
  });
  let HabitatViewProvider = _exports.default = (_dec = (0, _object.computed)('args.habitat'), _class = class HabitatViewProvider extends _component2.default {
    get data() {
      // XXX: I don't like supporting passing habitat promises inside,
      // but that's how things worked before, so I'm keeping it for now.
      // Plus, forcing each detail view to resolve on its own would just
      // add boilerplate
      return _rsvp.default.Promise.resolve(this.args.habitat).then(habitat => ({
        habitat,
        present: habitat && (habitat.description || habitat.count > 0)
      }));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "data", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "data"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HabitatViewProvider);
});