define("plutof/components/quick-browse/record-popup/gallery", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object/computed", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let this.thumbnailItem as |entry|}}
      {{#if entry}}
          <button
              {{on "click" (fn this.select entry)}}
              class="quick-browse-popup-thumbnail"
          >
              {{#if (is-equal entry.typeName "Audio")}}
                  <span class="{{icon 'file-sound'}} quick-browse-popup-thumbnail__media"></span>
              {{else}}
                  <img
                      src={{entry.links.small_link}}
                      class="quick-browse-popup-thumbnail__media" />
              {{/if}}
  
              {{#if this.hasMore}}
                  <div class="quick-browse-popup-thumbnail__has-more">
                      +{{this.hasMoreCount}}
                  </div>
              {{/if}}
          </button>
      {{/if}}
  {{/let}}
  
  <Gallery::Gallery
      @open={{this.selectedEntry}}
      @close={{this.close}}
      @previous={{if this.hasMore this.previous}}
      @next={{if this.hasMore this.next}}
  >
      <:preview>
          <Filerepository::Gallery::-Preview
              @type={{this.selectedEntry.type}}
              @links={{this.selectedEntry.links}} />
      </:preview>
  </Gallery::Gallery>
  
  */
  {
    "id": "K0KKIe45",
    "block": "[[[44,[[30,0,[\"thumbnailItem\"]]],[[[41,[30,1],[[[1,\"        \"],[11,\"button\"],[24,0,\"quick-browse-popup-thumbnail\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"select\"]],[30,1]],null]],null],[12],[1,\"\\n\"],[41,[28,[37,4],[[30,1,[\"typeName\"]],\"Audio\"],null],[[[1,\"                \"],[10,1],[15,0,[29,[[28,[37,5],[\"file-sound\"],null],\" quick-browse-popup-thumbnail__media\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"                \"],[10,\"img\"],[15,\"src\",[30,1,[\"links\",\"small_link\"]]],[14,0,\"quick-browse-popup-thumbnail__media\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"hasMore\"]],[[[1,\"                \"],[10,0],[14,0,\"quick-browse-popup-thumbnail__has-more\"],[12],[1,\"\\n                    +\"],[1,[30,0,[\"hasMoreCount\"]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null]],[1]]],[1,\"\\n\"],[8,[39,6],null,[[\"@open\",\"@close\",\"@previous\",\"@next\"],[[30,0,[\"selectedEntry\"]],[30,0,[\"close\"]],[52,[30,0,[\"hasMore\"]],[30,0,[\"previous\"]]],[52,[30,0,[\"hasMore\"]],[30,0,[\"next\"]]]]],[[\"preview\"],[[[[1,\"\\n        \"],[8,[39,7],null,[[\"@type\",\"@links\"],[[30,0,[\"selectedEntry\",\"type\"]],[30,0,[\"selectedEntry\",\"links\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"entry\"],false,[\"let\",\"if\",\"on\",\"fn\",\"is-equal\",\"icon\",\"gallery/gallery\",\"filerepository/gallery/-preview\"]]",
    "moduleName": "plutof/components/quick-browse/record-popup/gallery.hbs",
    "isStrictMode": false
  });
  // XXX
  const FileType = {
    Audio: 12,
    Video: 17,
    Image: 18
  };
  let QuickBrowsePopupGallery = (_dec = (0, _object.computed)('args.files.[]'), _dec2 = (0, _computed.gt)('items.length', 1), _dec3 = (0, _object.computed)('items.length'), _dec4 = (0, _object.computed)('items.[]'), _class = class QuickBrowsePopupGallery extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "hasMore", _descriptor, this);
      _initializerDefineProperty(this, "selectedEntry", _descriptor2, this);
    }
    get items() {
      // Only audio, image, and video for now.
      const media = this.args.files.filter(file => ['Image', 'Video', 'Audio'].includes(file.type));
      return media.map(file => {
        return {
          // XXX Converting back and forth between name and number.
          type: FileType[file.type],
          typeName: file.type,
          links: {
            link: file.link,
            small_link: file.small_link,
            large_link: file.large_link
          }
        };
      });
    }
    get hasMoreCount() {
      return this.items.length - 1;
    }

    // Prefer image thumbnail
    get thumbnailItem() {
      const image = this.items.findBy('typeName', 'Image');
      return image ? image : this.items.firstObject;
    }
    next() {
      const next = this.items[this.items.indexOf(this.selectedEntry) + 1];
      this.selectedEntry = next ? next : this.items.firstObject;
    }
    previous() {
      const next = this.items[this.items.indexOf(this.selectedEntry) - 1];
      this.selectedEntry = next ? next : this.items.lastObject;
    }
    close() {
      this.selectedEntry = null;
    }
    select(entry) {
      this.selectedEntry = entry;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "items", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "hasMore", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasMoreCount", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasMoreCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "thumbnailItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "thumbnailItem"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedEntry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "next", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previous", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "previous"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuickBrowsePopupGallery);
});