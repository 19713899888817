define("plutof/components/linked-items/view/-permits-tab", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@group.simpleTab
      @name="permits"
      @header={{i18n-t "permit.permits"}}
      @count={{@items.length}}
  >
      <CollectionLab::Permit::Items::View @items={{@items}} />
  </@group.simpleTab>
  
  */
  {
    "id": "WtHrZSft",
    "block": "[[[8,[30,1,[\"simpleTab\"]],null,[[\"@name\",\"@header\",\"@count\"],[\"permits\",[28,[37,0],[\"permit.permits\"],null],[30,2,[\"length\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@items\"],[[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@group\",\"@items\"],false,[\"i18n-t\",\"collection-lab/permit/items/view\"]]",
    "moduleName": "plutof/components/linked-items/view/-permits-tab.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});