define("plutof/templates/profile/classifications", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xXs+SbGL",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"profile.classifications\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-below\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"Settings.classifications.habitatCl\",\"Settings.classifications.habitatToolT\"],null]],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\",\"habitatClassifications\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],[[24,0,\"inline-flex\"]],[[\"@value\",\"@disabled\"],[[30,1,[\"enabled\"]],[28,[37,5],[[30,1,[\"enabled\"]],[28,[37,6],[[30,0,[\"canRemoveHabitat\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@save\"],[[30,0,[\"save\"]]]],null],[1,\"\\n\"]],[\"classification\"],false,[\"test/route-marker\",\"common/local-label\",\"each\",\"-track-array\",\"common/check-box\",\"and\",\"not\",\"common/save-button\"]]",
    "moduleName": "plutof/templates/profile/classifications.hbs",
    "isStrictMode": false
  });
});