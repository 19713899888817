define("plutof/components/interaction/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "rsvp", "plutof/misc/abstract", "plutof/misc/content_types"], function (_exports, _component, _templateFactory, _component2, _object, _service, _rsvp, _abstract, _content_types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.data wait=true as |data|}}
      {{yield
          (component "interaction/-view-interactions"
              interactions=data.props.interactions
              interactings=data.props.interactings
              parentInteractions=data.props.parentInteractions
              parentInteractings=data.props.parentInteractings)
  
          data.total}}
  {{/resolve-promise}}
  
  */
  {
    "id": "XC9aRhX1",
    "block": "[[[6,[39,0],[[30,0,[\"data\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"    \"],[18,2,[[50,\"interaction/-view-interactions\",0,null,[[\"interactions\",\"interactings\",\"parentInteractions\",\"parentInteractings\"],[[30,1,[\"props\",\"interactions\"]],[30,1,[\"props\",\"interactings\"]],[30,1,[\"props\",\"parentInteractions\"]],[30,1,[\"props\",\"parentInteractings\"]]]]],[30,1,[\"total\"]]]],[1,\"\\n\"]],[1]]]]]],[\"data\",\"&default\"],false,[\"resolve-promise\",\"yield\",\"component\"]]",
    "moduleName": "plutof/components/interaction/view.hbs",
    "isStrictMode": false
  });
  let InteractionView = _exports.default = (_dec = (0, _object.computed)('args.object', 'args.parent'), _class = class InteractionView extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    // Taking promises in because that's how interaction panel worked before
    get data() {
      return this._load(_rsvp.default.Promise.resolve(this.args.object), _rsvp.default.Promise.resolve(this.args.parent));
    }
    async _load(objectPromise, parentPromise) {
      const object = await objectPromise;
      const parent = await parentPromise;
      const ctype = await (0, _content_types.get_object_ctype)(this.store, object);
      let props = {
        interactions: this.loadInteractions(ctype, object.id),
        interactings: this.loadInteractings(ctype, object.id),
        parentInteractions: [],
        parentInteractings: []
      };
      if (parent) {
        const parentCType = await (0, _content_types.get_object_ctype)(this.store, parent);
        const parentInteractionPromises = [this.loadInteractions(parentCType, parent.id), this.loadInteractings(parentCType, parent.id)];
        let grandparentInteractionPromises = [[], []];
        if (parentCType.full_name === 'sequence/sequence' || parentCType.full_name === 'livingculture/strain') {
          const gpContentType = await parent.content_type;
          if (gpContentType) {
            grandparentInteractionPromises = [this.loadInteractions(gpContentType, parent.object_id), this.loadInteractings(gpContentType, parent.object_id)];
          }
        }
        const [parentInteractions, parentInteractings] = await _rsvp.default.all(parentInteractionPromises);
        const [gpInteractions, gpInteractings] = await _rsvp.default.all(grandparentInteractionPromises);
        props.parentInteractions = gpInteractions.concat(parentInteractions);
        props.parentInteractings = gpInteractings.concat(parentInteractings);
      }
      const loadedProps = await _rsvp.default.hash(props);
      const total = (0, _abstract.sum)(Object.values(loadedProps).map(is => is.length));
      return {
        total,
        props: loadedProps
      };
    }
    loadInteractions(ctype, id) {
      return this.store.query('taxonoccurrence/interaction/interaction', {
        content_type: ctype.id,
        object_id: id
      });
    }
    loadInteractings(ctype, id) {
      return this.store.query('taxonoccurrence/interaction/interaction', {
        interacting_content_type: ctype.id,
        interacting_object_id: id
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "data", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "data"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InteractionView);
});