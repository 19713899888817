define("plutof/templates/components/plutof-labelc", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "TF9rgKXH",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@tooltip\",\"@infoLinks\",\"@clicked\"],[[30,0,[\"data\",\"labelText\"]],[52,[30,1],null,[28,[37,2],[[30,2],[30,0,[\"data\",\"tooltipText\"]]],null]],[52,[30,1],null,[30,0,[\"data\",\"infoLinks\"]]],[30,3]]],null],[1,\"\\n\"]],[\"@hideTooltip\",\"@tooltip\",\"@clicked\"],false,[\"ui/label\",\"if\",\"or\"]]",
    "moduleName": "plutof/templates/components/plutof-labelc.hbs",
    "isStrictMode": false
  });
});