define("plutof/components/collection-lab/treatment/-view-content", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::Content
      @pagination={{@pagination}}
      @add={{@add}}
      as |Field treatment|
  >
      <Field @label={{backend-translations/label "treatment.carried_out_by"}}>
          <LinkTo @route="treatment.view" @model={{treatment.id}}>
              {{treatment.carried_out_by.representation}}
          </LinkTo>
      </Field>
  
      <Field @label={{backend-translations/label "treatment.start_date"}}>
          {{treatment.start_date}}
      </Field>
  
      <Field @label={{backend-translations/label "treatment.end_date"}}>
          {{treatment.end_date}}
      </Field>
  
      <Field @label={{backend-translations/label "treatment.description"}}>
          {{treatment.description}}
      </Field>
  </RelatedObjects::Content>
  
  */
  {
    "id": "+u/aaH7A",
    "block": "[[[8,[39,0],null,[[\"@pagination\",\"@add\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"treatment.carried_out_by\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"treatment.view\",[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"carried_out_by\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"treatment.start_date\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"start_date\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"treatment.end_date\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"end_date\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"treatment.description\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"description\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3,4]]]]],[1,\"\\n\"]],[\"@pagination\",\"@add\",\"Field\",\"treatment\"],false,[\"related-objects/content\",\"backend-translations/label\",\"link-to\"]]",
    "moduleName": "plutof/components/collection-lab/treatment/-view-content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});