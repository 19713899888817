define("plutof/templates/profile/rights", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "I+3t7U6f",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"profile.rights\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,1,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,2],null,[[\"rights\",\"defaultOwner\",\"validationContext\"],[[30,0,[\"model\",\"profileRights\"]],[30,0,[\"model\",\"profile\",\"default_owner\"]],[30,0,[\"validationContext\",\"access\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[8,[39,3],null,null,[[\"label\",\"tooltip\"],[[[[1,\"\\n                \"],[1,[28,[35,4],null,[[\"path\",\"hideTooltip\"],[\"personalsetting.default_owner\",true]]]],[1,\"\\n            \"]],[]],[[[1,\"\\n                \"],[1,[28,[35,5],[\"Profile.defaultRightsTooltip\"],null]],[1,\"\\n                \"],[10,\"br\"],[12],[13],[1,\"\\n                \"],[1,[28,[35,5],[\"Agents.personTooltip\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n\"],[6,[39,6],[[30,0,[\"model\",\"profile\",\"default_owner\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,7],null,[[\"@value\",\"@update\",\"@noDefault\"],[[30,2],[30,3],true]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"plutof-label\"],[12],[1,\" \"],[13],[1,\"\\n\\n\"],[1,[28,[35,8],null,[[\"save\",\"disabled\"],[[28,[37,9],[[30,0],\"save\"],null],[30,0,[\"validationContext\",\"isInvalid\"]]]]]],[1,\"\\n\"]],[\"grid\",\"value\",\"update\"],false,[\"test/route-marker\",\"layout/regular-grid\",\"access/rights-form\",\"ui/label\",\"plutof-labelc\",\"i18n-t\",\"async/bind-relation\",\"agent/user/auto-complete\",\"common/save-button\",\"action\"]]",
    "moduleName": "plutof/templates/profile/rights.hbs",
    "isStrictMode": false
  });
});