define("plutof/components/generic-views/-record/field", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "plutof/utils/model", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _object, _service, _model, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _GenericRecordViewField;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (component "generic-views/-record/attr"
      field=this.field
      context=@context
      label=@label
      tooltip=@tooltip
      translations=@translations
  ) as |attr|}}
      {{!--
          Hide empty is enabled in very few places and always waiting for value
          would pointlessly trash the layout in the normal case, so we separate
          the two paths
      --}}
      {{#if @context.hideEmpty}}
          {{#let (get @context.record this.field) as |value|}}
              {{#if (not (is-empty value))}}
                  {{#attr}}
                      <GenericViews::-Record::FieldValue
                          @value={{value}}
                          @meta={{this.meta}} />
                  {{/attr}}
              {{/if}}
          {{/let}}
      {{else}}
          {{#attr}}
              {{#let (get @context.record this.field) as |value|}}
                  <GenericViews::-Record::FieldValue
                      @value={{value}}
                      @meta={{this.meta}} />
              {{/let}}
          {{/attr}}
      {{/if}}
  {{/let}}
  
  */
  {
    "id": "23Y3C+Gr",
    "block": "[[[44,[[50,\"generic-views/-record/attr\",0,null,[[\"field\",\"context\",\"label\",\"tooltip\",\"translations\"],[[30,0,[\"field\"]],[30,1],[30,2],[30,3],[30,4]]]]],[[[41,[30,1,[\"hideEmpty\"]],[[[44,[[28,[37,3],[[30,1,[\"record\"]],[30,0,[\"field\"]]],null]],[[[41,[28,[37,4],[[28,[37,5],[[30,6]],null]],null],[[[6,[30,5],null,null,[[\"default\"],[[[[1,\"                    \"],[8,[39,6],null,[[\"@value\",\"@meta\"],[[30,6],[30,0,[\"meta\"]]]],null],[1,\"\\n\"]],[]]]]]],[]],null]],[6]]]],[]],[[[6,[30,5],null,null,[[\"default\"],[[[[44,[[28,[37,3],[[30,1,[\"record\"]],[30,0,[\"field\"]]],null]],[[[1,\"                \"],[8,[39,6],null,[[\"@value\",\"@meta\"],[[30,7],[30,0,[\"meta\"]]]],null],[1,\"\\n\"]],[7]]]],[]]]]]],[]]]],[5]]]],[\"@context\",\"@label\",\"@tooltip\",\"@translations\",\"attr\",\"value\",\"value\"],false,[\"let\",\"component\",\"if\",\"get\",\"not\",\"is-empty\",\"generic-views/-record/field-value\"]]",
    "moduleName": "plutof/components/generic-views/-record/field.hbs",
    "isStrictMode": false
  });
  let GenericRecordViewField = (_dec = (0, _object.computed)('context.record', 'field'), _class = (_GenericRecordViewField = class GenericRecordViewField extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    get meta() {
      const model = this.context.record.constructor;
      const fieldKind = model.fields.get(this.field);
      let meta = {};
      if (fieldKind === 'attribute') {
        const descriptor = model.attributes.get(this.field);
        switch (descriptor.type) {
          case 'date':
          case 'datetime':
          case 'ambiguous-datetime':
            meta.type = 'datetime';
            break;
          case 'enum':
          case 'enum-multiple':
            meta.type = descriptor.type;
            meta.choices = (0, _model.getFieldChoices)(this.ajax, `${model.modelName.replace(/\//g, '.')}.${this.field}`, descriptor.options).then(choices => {
              const labels = {};
              choices.forEach(choice => {
                labels[choice.value] = choice.display_name;
              });
              return labels;
            });
            break;
          default:
            meta.type = 'value';
            break;
        }
      } else if (fieldKind === 'belongsTo' || fieldKind === 'hasMany') {
        meta.type = fieldKind;
        const descriptor = model.relationshipsByName.get(this.field);
        const ctype = (0, _modules.getModelContentType)(descriptor.type);
        const module = _modules.default[ctype];
        if (module) {
          meta.route = (0, _object.get)(module, 'routes.view');
        }
      } else {
        throw new Error(`Unsupported field kind: ${fieldKind} for field ${this.field}`);
      }
      return meta;
    }
  }, _defineProperty(_GenericRecordViewField, "positionalParams", ['field']), _GenericRecordViewField), _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "meta", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "meta"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GenericRecordViewField);
});