define("plutof/components/access/-view-rights/owner", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#access/-view-rights/column}}
      <ViewField
          @label={{i18n-t "General.owner"}}
          @tooltip={{i18n-t "General.ownerTooltip"}}
          class="view-rights__object-owner"
      >
          {{@owner}}
      </ViewField>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "OLW1btvs",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],[[24,0,\"view-rights__object-owner\"]],[[\"@label\",\"@tooltip\"],[[28,[37,2],[\"General.owner\"],null],[28,[37,2],[\"General.ownerTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@owner\"],false,[\"access/-view-rights/column\",\"view-field\",\"i18n-t\"]]",
    "moduleName": "plutof/components/access/-view-rights/owner.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});