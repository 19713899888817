define("plutof/templates/components/datacite/date-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "3ck/GlNg",
    "block": "[[[10,\"td\"],[15,0,[29,[\"data-table-short-cell \",[52,[30,0,[\"validations\",\"attrs\",\"date\",\"date_type\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"content\",\"value\",\"selectClass\",\"class\"],[[30,0,[\"dateTypes\"]],[30,1,[\"date_type\"]],\"mini-input\",\"datacite-edit-dates__type\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[52,[30,0,[\"validations\",\"attrs\",\"dateStartIsValid\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"value\",\"validity\",\"class\"],[[30,1,[\"date_start\"]],[30,0,[\"dateStartValidity\"]],\"mini-input datacite-edit-dates__start\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[52,[30,0,[\"validations\",\"attrs\",\"date\",\"dateEndValidity\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"value\",\"validity\",\"class\"],[[30,1,[\"date_end\"]],[30,0,[\"dateEndValidity\"]],\"mini-input datacite-edit-dates__end\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"mini-input datacite-edit-dates__info\"]],[[\"@value\"],[[30,1,[\"date_information\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,4],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@date\",\"@remove\"],false,[\"if\",\"plutof-select/value\",\"pikaday-date\",\"flexible-textarea\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/date-row-form.hbs",
    "isStrictMode": false
  });
});