define("plutof/components/collection-lab/condition-assessment/-view-content", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::Content
      @pagination={{@pagination}}
      @add={{@add}}
      as |Field assessment|
  >
      <Field @label={{backend-translations/label "condition-assessment.assessment_of_condition"}}>
          <LinkTo @route="condition-assessment.view" @model={{assessment.id}}>
              {{assessment.conditionDisplayName}}
          </LinkTo>
      </Field>
  
      <Field @label={{backend-translations/label "condition-assessment.parent_record_name"}}>
          {{assessment.parent_record_name}}
      </Field>
  
      <Field @label={{backend-translations/label "condition-assessment.assessed_by"}}>
          {{assessment.assessed_by.representation}}
      </Field>
  
      <Field @label={{backend-translations/label "condition-assessment.assessed_date"}}>
          {{assessment.assessed_date}}
      </Field>
  
      <Field @label={{backend-translations/label "condition-assessment.remarks"}}>
          {{assessment.remarks}}
      </Field>
  </RelatedObjects::Content>
  
  */
  {
    "id": "T5LcxlWn",
    "block": "[[[8,[39,0],null,[[\"@pagination\",\"@add\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"condition-assessment.assessment_of_condition\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"condition-assessment.view\",[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"conditionDisplayName\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"condition-assessment.parent_record_name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"parent_record_name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"condition-assessment.assessed_by\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"assessed_by\",\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"condition-assessment.assessed_date\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"assessed_date\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"condition-assessment.remarks\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"remarks\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3,4]]]]],[1,\"\\n\"]],[\"@pagination\",\"@add\",\"Field\",\"assessment\"],false,[\"related-objects/content\",\"backend-translations/label\",\"link-to\"]]",
    "moduleName": "plutof/components/collection-lab/condition-assessment/-view-content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});