define("plutof/components/quick-browse/toggle-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" @toggle}}
      class="btn-reset quick-browse-toggle-btn {{if @open '' 'quick-browse-toggle-btn--hover'}}"
  >
      <span class="quick-browse-toggle-btn__text">
          <span class={{icon "folder"}}></span>
          {{i18n-t "QuickBrowse.button"}}
      </span>
  </button>
  
  */
  {
    "id": "n2BOp95i",
    "block": "[[[11,\"button\"],[16,0,[29,[\"btn-reset quick-browse-toggle-btn \",[52,[30,1],\"\",\"quick-browse-toggle-btn--hover\"]]]],[4,[38,1],[\"click\",[30,2]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"quick-browse-toggle-btn__text\"],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[37,2],[\"folder\"],null]],[12],[13],[1,\"\\n        \"],[1,[28,[35,3],[\"QuickBrowse.button\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@open\",\"@toggle\"],false,[\"if\",\"on\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/quick-browse/toggle-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});