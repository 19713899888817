define("plutof/components/related-objects/content", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layout::SplitRow>
      <:left>
          <Pagination::PageControls @pagination={{@pagination}} />
      </:left>
  
      <:right>
          {{#if @add}}
              <button
                  {{on "click" @add}}
                  class="plutof-btn__controls plutof-btn__controls--xs"
              >
                  <span class={{icon "external-link"}}></span>
                  {{i18n-t "General.New"}}
              </button>
          {{/if}}
      </:right>
  </Layout::SplitRow>
  
  <Tables::List
      @records={{@pagination.objects}}
      @headersClasses="related-objects-view__headers"
      @rowClasses="related-objects-view__row"
      as |Field object|
  >
      {{yield Field object}}
  </Tables::List>
  
  <div class="spacer-large"></div>
  
  <Pagination::PageControls
      @pagination={{@pagination}}
      @removeSpacing={{true}} />
  
  */
  {
    "id": "kiigh4VB",
    "block": "[[[8,[39,0],null,null,[[\"left\",\"right\"],[[[[1,\"\\n        \"],[8,[39,1],null,[[\"@pagination\"],[[30,1]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[41,[30,2],[[[1,\"            \"],[11,\"button\"],[24,0,\"plutof-btn__controls plutof-btn__controls--xs\"],[4,[38,3],[\"click\",[30,2]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,4],[\"external-link\"],null]],[12],[13],[1,\"\\n                \"],[1,[28,[35,5],[\"General.New\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@records\",\"@headersClasses\",\"@rowClasses\"],[[30,1,[\"objects\"]],\"related-objects-view__headers\",\"related-objects-view__row\"]],[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[30,3],[30,4]]],[1,\"\\n\"]],[3,4]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@pagination\",\"@removeSpacing\"],[[30,1],true]],null],[1,\"\\n\"]],[\"@pagination\",\"@add\",\"Field\",\"object\",\"&default\"],false,[\"layout/split-row\",\"pagination/page-controls\",\"if\",\"on\",\"icon\",\"i18n-t\",\"tables/list\",\"yield\"]]",
    "moduleName": "plutof/components/related-objects/content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});