define("plutof/templates/components/tab-group/tab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "wSJazJKn",
    "block": "[[[11,0],[16,0,[29,[\"single-tab \",[52,[30,0,[\"tab\",\"selected\"]],\"single-tab--active\"]]]],[17,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"tab-content\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,2],false],null],[[[41,[30,0,[\"tab\",\"selected\"]],[[[1,\"                \"],[18,3,[[30,0,[\"tab\"]]]],[1,\"\\n\"]],[]],null]],[]],[[[1,\"            \"],[18,3,[[30,0,[\"tab\"]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@dontPrecreate\",\"&default\"],false,[\"if\",\"default-to\",\"yield\"]]",
    "moduleName": "plutof/templates/components/tab-group/tab.hbs",
    "isStrictMode": false
  });
});