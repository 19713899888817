define("plutof/templates/components/tab-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "WSBOV6tK",
    "block": "[[[10,0],[14,0,\"tab-group__tablist-container\"],[12],[1,\"\\n    \"],[10,\"ul\"],[15,1,[30,0,[\"headersContainerId\"]]],[14,0,\"tab-group__tablist\"],[14,\"role\",\"tablist\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"            \"],[10,1],[15,1,[28,[37,2],[[30,0,[\"headersContainerId\"]],\"-\",[30,2]],null]],[12],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,4,[[28,[37,4],null,[[\"header\",\"tab\",\"frame\",\"panel\",\"simpleTab\",\"group\"],[[50,\"tab-group/header\",0,null,[[\"group\"],[[30,0]]]],[50,\"tab-group/tab\",0,null,[[\"group\"],[[30,0]]]],[50,\"tab-group/tab-frame\",0,null,[[\"group\"],[[30,0]]]],[50,\"tab-group/tab-panel\",0,null,null],[50,\"tab-group/simple-tab\",0,null,[[\"group\"],[[30,0]]]],[30,0]]]]]],[1,\"\\n\"]],[\"@forcedOrder\",\"name\",\"&tablist\",\"&tabs\"],false,[\"each\",\"-track-array\",\"concat\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/tab-group.hbs",
    "isStrictMode": false
  });
});