define("plutof/components/information/content/taxon/import", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h4>{{i18n-t "taxonImport.importGbif"}}</h4>
  
  <Information::MarkdownReader
      @path="taxon/gbif-import"
      @locale={{this.locale}} />
  
  <h4>{{i18n-t "taxonImport.importMycobank"}}</h4>
  
  <Information::MarkdownReader
      @path="taxon/mycobank-import"
      @locale={{this.locale}} />
  
  */
  {
    "id": "tnuvh0M9",
    "block": "[[[10,\"h4\"],[12],[1,[28,[35,0],[\"taxonImport.importGbif\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@path\",\"@locale\"],[\"taxon/gbif-import\",[30,0,[\"locale\"]]]],null],[1,\"\\n\\n\"],[10,\"h4\"],[12],[1,[28,[35,0],[\"taxonImport.importMycobank\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@path\",\"@locale\"],[\"taxon/mycobank-import\",[30,0,[\"locale\"]]]],null],[1,\"\\n\"]],[],false,[\"i18n-t\",\"information/markdown-reader\"]]",
    "moduleName": "plutof/components/information/content/taxon/import.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});