define("plutof/components/preparation/-view-item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GenericViews::Record
      @record={{@preparation}}
      as |view|
  >
      <Layout::RegularGrid @columns={{4}}>
          {{view.field "type"}}
  
          {{view.attr "name"}}
          {{view.attr "count"}}
          {{view.attr "prepared_date"}}
  
          {{#view.attr "prepared_by" label=(i18n-t "General.preparedBy")}}
              <Common::RecordListView @records={{@agents}} as |agent|>
                  <LinkTo @route="person.view" @model={{agent.person.id}}>
                      {{agent.person.representation}}
                  </LinkTo>
              </Common::RecordListView>
          {{/view.attr}}
  
          {{view.attr "remarks"}}
  
          {{view.attr "storage_location"}}
          {{view.attr "method_desc" marker="method_description"}}
  
          {{#if @modelSet.optionalFields.storage_temperature}}
              {{view.attr "storage_temperature"}}
          {{/if}}
  
          {{#if @modelSet.optionalFields.prepared_location}}
              {{view.attr "prepared_location"}}
          {{/if}}
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "0Gg1YBUb",
    "block": "[[[8,[39,0],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[30,2,[\"field\"]],[\"type\"],null]],[1,\"\\n\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"name\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"count\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"prepared_date\"],null]],[1,\"\\n\\n\"],[6,[30,2,[\"attr\"]],[\"prepared_by\"],[[\"label\"],[[28,[37,2],[\"General.preparedBy\"],null]]],[[\"default\"],[[[[1,\"            \"],[8,[39,3],null,[[\"@records\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"person.view\",[30,4,[\"person\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,4,[\"person\",\"representation\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"]],[4]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"remarks\"],null]],[1,\"\\n\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"storage_location\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"method_desc\"],[[\"marker\"],[\"method_description\"]]]],[1,\"\\n\\n\"],[41,[30,5,[\"optionalFields\",\"storage_temperature\"]],[[[1,\"            \"],[1,[28,[30,2,[\"attr\"]],[\"storage_temperature\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,5,[\"optionalFields\",\"prepared_location\"]],[[[1,\"            \"],[1,[28,[30,2,[\"attr\"]],[\"prepared_location\"],null]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@preparation\",\"view\",\"@agents\",\"agent\",\"@modelSet\"],false,[\"generic-views/record\",\"layout/regular-grid\",\"i18n-t\",\"common/record-list-view\",\"link-to\",\"if\"]]",
    "moduleName": "plutof/components/preparation/-view-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});