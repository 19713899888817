define("plutof/components/related-objects/tabs", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Sample.associatedOccurrences"}}
      ...attributes
  >
      <TabGroup @tabSwitched={{this.tabSwitched}}>
          <:tabs as |tabGroup|>
              <RelatedObjects::PredefinedTabs
                  @query={{@query}}
                  @clipboardQuery={{@clipboardQuery}}
                  @searchQuery={{@searchQuery}}
                  @tabGroup={{tabGroup}}
                  @canModify={{@canModify}}
                  @loaded={{fn this.loaded tabGroup.group}}
                  @customAddButton={{@customAddButton}}
                  as |tabs|
              >
                  {{yield tabs}}
              </RelatedObjects::PredefinedTabs>
          </:tabs>
      </TabGroup>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "MaZJvcC5",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\"],[[28,[37,1],[\"Sample.associatedOccurrences\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@tabSwitched\"],[[30,0,[\"tabSwitched\"]]]],[[\"tabs\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@query\",\"@clipboardQuery\",\"@searchQuery\",\"@tabGroup\",\"@canModify\",\"@loaded\",\"@customAddButton\"],[[30,3],[30,4],[30,5],[30,2],[30,6],[28,[37,4],[[30,0,[\"loaded\"]],[30,2,[\"group\"]]],null],[30,7]]],[[\"default\"],[[[[1,\"\\n                \"],[18,9,[[30,8]]],[1,\"\\n            \"]],[8]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"tabGroup\",\"@query\",\"@clipboardQuery\",\"@searchQuery\",\"@canModify\",\"@customAddButton\",\"tabs\",\"&default\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"tab-group\",\"related-objects/predefined-tabs\",\"fn\",\"yield\"]]",
    "moduleName": "plutof/components/related-objects/tabs.hbs",
    "isStrictMode": false
  });
  // Dirty hacks to switch to the first non-empty tab. Doing it the proper way means that
  // at either this component should setup all the paginations or they must be passed from outside.
  // Both of those are messy as well, so this will do for now.
  let RelatedObjectTabs = (_class = class RelatedObjectTabs extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "initialTabSet", false);
    }
    loaded(tabGroup, name, pagination) {
      if (this.initialTabSet || !pagination || tabGroup.receivedManualSelection) {
        return;
      }
      if (pagination.objectCount > 0) {
        tabGroup.switchTab(tabGroup.getTab(name), true);
        this.initialTabSet = true;
      }
    }
    tabSwitched() {
      this.initialTabSet = true;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "loaded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loaded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tabSwitched", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "tabSwitched"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RelatedObjectTabs);
});